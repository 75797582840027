import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, Teleport as _Teleport, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e55a636a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal-card-head" }
const _hoisted_2 = {
  key: 0,
  class: "modal-card-title"
}
const _hoisted_3 = { class: "modal-card-foot is-flex is-justify-content-flex-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createElementVNode("div", {
      class: _normalizeClass(["modal", { 'is-active': _ctx.modelValue }])
    }, [
      _createElementVNode("div", {
        class: "modal-background",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
      }),
      _createElementVNode("div", {
        class: _normalizeClass(["modal-card", { 'widen': _ctx.isLarge, 'show-overflow': _ctx.allowOverflow }])
      }, [
        _createElementVNode("header", _hoisted_1, [
          _renderSlot(_ctx.$slots, "header", {}, () => [
            (_ctx.title)
              ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.title), 1))
              : _createCommentVNode("", true)
          ], true),
          _createElementVNode("button", {
            class: "delete ml-3",
            type: "button",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
          })
        ]),
        _createElementVNode("section", {
          class: _normalizeClass(["modal-card-body", { 'show-overflow': _ctx.allowOverflow }])
        }, [
          _renderSlot(_ctx.$slots, "default", { close: _ctx.close }, undefined, true)
        ], 2),
        _createElementVNode("footer", _hoisted_3, [
          _renderSlot(_ctx.$slots, "footer", { close: _ctx.close }, () => [
            (_ctx.showButton)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  class: "button",
                  type: "button",
                  onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
                }, " OK "))
              : _createCommentVNode("", true)
          ], true)
        ])
      ], 2)
    ], 2)
  ]))
}