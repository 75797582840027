import { listExports, listExportSources, startExport, deleteExport } from '@/api'
import { PageNumberPagination, UUID } from '@/types'
import { CorpusExport, ExportSource } from '@/types/export'
import { defineStore } from 'pinia'
import { useJobsStore, useNotificationStore } from '.'
import { errorParser } from '@/helpers'

interface State {
  /**
   * Exports by corpora.
   */
  corpusExports: { [corpusId: UUID]: PageNumberPagination<CorpusExport> }

  /**
   * Available export sources. Arkindex EE only.
   */
  sources: ExportSource[] | null
}

export const useExportStore = defineStore('exports', {
  state: (): State => ({
    corpusExports: {},
    sources: null
  }),
  actions: {
    async list (corpusId: UUID, page = 1) {
      try {
        const data = await listExports(corpusId, { page })
        this.corpusExports[corpusId] = data
      } catch (err) {
        useNotificationStore().notify({ type: 'error', text: errorParser(err) })
      }
    },
    async start (corpusId: UUID, source: string = "default") {
      try {
        await startExport(corpusId, source)
        useJobsStore().list()
      } catch (err) {
        useNotificationStore().notify({ type: 'error', text: errorParser(err) })
      }
    },
    async delete (id: UUID, corpusId: UUID) {
      try {
        await deleteExport(id)
        this.list(corpusId)
      } catch (err) {
        useNotificationStore().notify({ type: 'error', text: errorParser(err) })
      }
    },
    async listSources () {
      if (Array.isArray(this.sources)) return
      try {
        this.sources = await listExportSources()
      } catch (err) {
        useNotificationStore().notify({ type: 'error', text: errorParser(err) })
      }
    }
  }
})
