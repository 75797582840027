<template>
  <div>
    <CorpusHeader :corpus-id="corpusId" />
    <main class="container is-fluid">
      <ElementNavigation :corpus-id="corpusId" v-model:query="query" />
    </main>
  </div>
</template>

<script>
import { isEmpty } from 'lodash'
import { corporaMixin } from '@/mixins'
import ElementNavigation from '@/components/Navigation/ElementNavigation'
import CorpusHeader from '@/components/Corpus/Header.vue'
import { useCorporaStore } from '@/stores'

export default {
  mixins: [
    corporaMixin
  ],
  components: {
    ElementNavigation,
    CorpusHeader
  },
  props: {
    corpusId: {
      type: String,
      required: true
    }
  },
  computed: {
    query: {
      get () {
        return this.$route.query
      },
      set (query) {
        this.$router.push({ ...this.$route, query })
      }
    }
  },
  /**
   * When entering this view's route for the first time, if there are no defined query parameters,
   * we will set the default filters for the corpus (top-level elements, either of a folder type
   * or of a top-level type if one is defined).
   */
  async beforeRouteEnter (to, from, next) {
    if (!isEmpty(to.query)) {
      next()
      return
    }
    const query = { top_level: true }

    /*
     * Retrieve the current corpus to find the top-level type.
     * This uses `store` and not `this.$store` because `this` is not available in beforeRouteEnter.
     */
    const corpus = await useCorporaStore().get(to.params.corpusId)
    if (corpus.top_level_type) query.type = corpus.top_level_type
    else query.folder = true

    next({ path: to.path, query, hash: to.hash })
  }
}
</script>
