
import { mapState } from 'pinia'
import { TranscriptionEntityLight, parseEntities } from '@/helpers'
import { useDisplayStore } from '@/stores'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import { EntityLight, EntityType } from '@/types/entity'

export default defineComponent({
  name: 'Token',
  props: {
    entity: {
      type: Object as PropType<EntityLight>,
      default: null
    },
    text: {
      type: String,
      required: true
    },
    // This allows this component to properly parse its child tokens, to compute entity offsets relative to its own text.
    offset: {
      type: Number,
      required: true
    },
    children: {
      type: Array as PropType<TranscriptionEntityLight[]>,
      default: () => []
    },
    // Show a warning about an entity overflowing its parent
    overflow: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    tagStyle (type: EntityType) {
      return { 'background-color': `#${type.color}` }
    },
    entityStyle (type: EntityType) {
      return { 'text-decoration': `underline #${type.color}` }
    }
  },
  computed: {
    ...mapState(useDisplayStore, ['displayEntityTypes']),
    tokens () {
      if (!this.children.length) {
        return []
      }
      return parseEntities(this.text, this.children, this.offset)
    }
  }
})
