<template>
  <fieldset
    title="The date must contain at least a full year, optionally a month and a day"
    :class="{ 'is-danger': !dateValidated, 'is-success': currentDate.length && dateValidated }"
  >
    <input
      class="date_input"
      type="text"
      maxlength="10"
      v-model="currentDate"
      placeholder="YYYY[-MM[-DD]]"
    />
    <a v-if="currentDate.length" class="is-right delete is-small" v-on:click="currentDate = ''">x</a>
  </fieldset>
</template>

<script>
export default {
  emits: ['valid', 'update:modelValue'],
  props: {
    modelValue: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    currentDate: '',
    dateValidated: true
  }),
  mounted () {
    this.currentDate = this.modelValue
  },
  watch: {
    currentDate (newValue) {
      this.dateValidated = !this.currentDate.length || (
        this.currentDate.match(/^\d{4}(-\d{2})?(-\d{2})?$/) &&
        !isNaN(Date.parse(this.currentDate))
      )
      this.$emit('update:modelValue', newValue)
      this.$emit('valid', this.dateValidated)
    },
    modelValue (newValue) {
      this.currentDate = newValue
    }
  }
}
</script>

<style>
.date_input {
  margin-left: 1ch;
  font-size: inherit;
  border: none;
}
</style>
