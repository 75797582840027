<template>
  <span>
    <!--
      You can override the default slot to customize the button that opens this modal:
      <SingleDeletionModal :element="element">
        <button ... />
      </SingleDeletionModal>
      Do not show this slot if the modal is being opened and closed using v-model.
    -->
    <slot :open="open" :can-delete="canDelete">
      <i
        v-if="modelValue === undefined"
        class="icon-trash"
        :class="canDelete ? 'has-text-danger' : 'has-text-grey'"
        v-on:click.prevent="open"
      ></i>
    </slot>
    <Modal
      v-model="opened"
      :title="modalTitle"
      v-on:update:opened="value => $emit('update:modelValue', value)"
    >
      <p>
        Are you sure you want to delete
        <template v-if="isElement">
          the {{ typeName(target.type) }} <strong>{{ truncateLong(target.name) }}</strong>
        </template>
        <template v-else>
          project <strong>{{ truncateLong(target.name) }}</strong>
        </template>?<br />
        Child elements will also be deleted recursively.<br />
        This action is irreversible.
      </p>
      <template v-slot:footer="{ close }">
        <button class="button" v-on:click.prevent="close">Cancel</button>
        <button
          class="button is-danger"
          :class="{ 'is-loading': loading }"
          :disabled="loading || !canDelete || undefined"
          :title="deleteTitle"
          v-on:click.prevent="performDelete"
        >
          Delete
        </button>
      </template>
    </Modal>
  </span>
</template>

<script>
import { mapGetters as mapVuexGetters, mapActions as mapVuexActions } from 'vuex'
import { mapActions } from 'pinia'
import { corporaMixin, truncateMixin } from '@/mixins'
import Modal from '@/components/Modal.vue'
import { useNotificationStore, useCorporaStore } from '@/stores'
import { errorParser } from '@/helpers'

export default {
  components: {
    Modal
  },
  emits: ['update:modelValue'],
  mixins: [
    corporaMixin,
    truncateMixin
  ],
  props: {
    // The object to delete (Element or Corpus).
    target: {
      type: Object,
      required: true
    },
    // For redirection if an element is being deleted from its main view.
    redirect: {
      type: Boolean,
      default: false
    },
    // To open and close the modal without a button
    modelValue: {
      type: Boolean,
      default: undefined
    }
  },
  data: () => ({
    opened: false,
    loading: false
  }),
  computed: {
    ...mapVuexGetters('elements', {
      // canAdmin is already defined in corporaMixin
      canAdminElement: 'canAdmin'
    }),
    ...mapVuexGetters('auth', ['isVerified']),
    ...mapVuexGetters('elements', ['firstParentId']),
    isElement () {
      if ('type' in this.target) return true
      return false
    },
    // Required for the corporaMixin
    corpusId () {
      if (this.isElement) return this.target.corpus.id
      else return this.target.id
    },
    /**
     * Allow deleting elements only if the user is verified and has admin rights on the corpus.
     * Additionally, if the element has a `rights` attribute, look for an admin right.
     * This element rights check cannot be done for every element since the rights attribute is not available in all endpoints.
     */
    canDelete () {
      if (!this.corpus || !this.isVerified || !this.canAdmin(this.corpus)) return false
      if (this.isElement) return (!this.target.rights || this.canAdminElement(this.target.id))
      return true
    },
    modalTitle () {
      if (this.isElement) return 'Delete ' + this.truncateShort(this.typeName(this.target.type)) + ' ' + this.truncateShort(this.target.name)
      else return 'Delete project ' + this.truncateLong(this.target.name)
    },
    deleteTitle () {
      if (!this.canDelete) {
        if (this.isElement) return 'You do not have the required rights to delete this element'
        else return 'You do not have the required rights to delete this project'
      }
      return ''
    }
  },
  methods: {
    ...mapActions(useNotificationStore, ['notify']),
    ...mapActions(useCorporaStore, { deleteCorpus: 'delete' }),
    ...mapVuexActions('elements', { deleteElement: 'delete' }),
    // A simple helper made available in the slot to let you open the modal
    open () {
      this.opened = this.canDelete
    },
    async performDelete () {
      if (!this.canDelete || this.loading) return
      let newRoute = null
      if (this.redirect) {
        if (this.isElement) {
          // When deleting an element that is the main element, redirect to the first parent element or the corpus.
          if (this.firstParentId(this.target.id)) newRoute = { name: 'element-details', params: { id: this.firstParentId(this.target.id) } }
          else newRoute = { name: 'navigation', params: { corpusId: this.corpusId } }
        // If a project is being deleted, redirect to the project list.
        } else newRoute = { name: 'corpus-list' }
      }
      this.loading = true
      try {
        if (this.isElement) await this.deleteElement({ id: this.target.id })
        else await this.deleteCorpus(this.target.id)
        // Redirect if we defined a route above for a main element or project
        if (newRoute != null) this.$router.push(newRoute)
        // Otherwise unselect an eventual highlighted element
        else this.$store.commit('annotation/selectElement', null)
        this.opened = false
      } catch (err) {
        this.notify({ type: 'error', text: errorParser(err) })
      } finally {
        this.loading = false
      }
    }
  },
  watch: {
    modelValue: {
      immediate: true,
      handler (newValue) {
        if (newValue !== undefined) this.opened = newValue
      }
    },
    opened (newValue, oldValue) {
      if (newValue !== oldValue) this.$emit('update:modelValue', this.opened)
    }
  }
}
</script>
