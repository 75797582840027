/**
 * Compatibility shim to allow creating notifications in the Pinia store
 * from code that still tries to access the Vuex store module.
 */
import { useNotificationStore } from '@/stores'

export const mutations = {
  notify (_, notification) {
    const notificationStore = useNotificationStore()
    notificationStore.notify(notification)
  },
  reset () {
    const notificationStore = useNotificationStore()
    notificationStore.$reset()
  }
}

export default {
  namespaced: true,
  mutations
}
