import { defineStore } from 'pinia'
import { UUID } from '@/types'
import { createProcessFailures } from '@/api'
import { useJobsStore, useNotificationStore } from '@/stores'
import { errorParser } from '@/helpers'

interface State {
  /**
   * List of processes from which user already triggered the creation of a process
   * from elements with activities in an error state. Allows components to provide
   * a feedback once the async task started, to avoid creating duplicate processes.
   */
  processesFromFailures: Set<UUID>
}

export const useProcessStore = defineStore('process', {
  state: (): State => ({
    processesFromFailures: new Set(),
  }),
  actions: {
    async createProcessFailures (processId: UUID) {
      try {
        if (this.processesFromFailures.has(processId)) return
        await createProcessFailures(processId)
        this.processesFromFailures.add(processId)
        useNotificationStore().notify({ type: 'success', text: 'A new process is being created from elements in error state, you will be notified by email once it is accessible.' })
        useJobsStore().list()
      } catch (err) {
        useNotificationStore().notify({ type: 'error', text: errorParser(err) })
        throw err
      }
    }
  }
})
