import { defineStore } from 'pinia'
import { listElementChildren, listElements } from '@/api'
import { ELEMENT_LIST_MAX_AUTO_PAGES } from '@/config'
import { errorParser } from '@/helpers'
import { CorpusLight, ElementBase, PageNumberPagination, UUID } from '@/types'
import { useNotificationStore } from './notification'

export interface Folder extends ElementBase {
  corpus: CorpusLight
  subfolders: UUID[]
}

type Pagination = Omit<PageNumberPagination<ElementBase>, 'results'>

interface State {
  /**
   * Folder data with a list of subfolder IDs.
   * Using IDs in this way allows avoiding strange recursive code in the store.
   */
  folders: { [id: UUID]: Folder }
  /**
   * List of top-level folder IDs per corpora.
   */
  corpus: { [corpusId: UUID]: UUID[] }
  /**
   * Pagination state by corpus ID to allow loading a portion of the list and load more upon user request
   */
  corpusPagination: { [corpusId: UUID]: Pagination }
  /**
   * Pagination state by folder ID to allow loading a portion of the list and load more upon user request
   */
  folderPagination: { [id: UUID]: Pagination }
}

export const useFolderPickerStore = defineStore('folderpicker', {
  state: (): State => ({
    folders: {},
    corpus: {},
    corpusPagination: {},
    folderPagination: {}
  }),
  actions: {
    async nextFolders (corpus: UUID, folder: UUID | null = null, max = ELEMENT_LIST_MAX_AUTO_PAGES) {
      if (!corpus && !folder) throw new Error('Either a corpus ID or a folder ID are required')
      try {
        let count = 0
        while (count < max) {
          const pagination = this.pagination(corpus, folder) || { previous: null, next: null, count: 0, number: 0 }
          if (pagination.number > 0 && !pagination.next) {
            // No next page
            break
          }

          let data: PageNumberPagination<ElementBase & { corpus: CorpusLight }>
          if (folder) {
            data = await listElementChildren({
              id: folder,
              page: pagination.number + 1,
              folder: true
            })
          } else {
            data = await listElements({
              corpus,
              top_level: true,
              page: pagination.number + 1,
              folder: true
            })
          }

          const { results, ...nextPagination } = data

          // Add the folders to the state
          this.folders = {
            ...this.folders,
            ...Object.fromEntries(results.map(subfolder => ([subfolder.id, { ...subfolder, subfolders: [] }])))
          }

          // Add the folders as subfolders of their folder or corpus
          const ids = results.map(subfolder => subfolder.id)
          if (folder) {
            this.folders[folder] = {
              ...(this.folders[folder] || {}),
              subfolders: [
                ...((this.folders[folder] || {}).subfolders || []),
                ...ids
              ]
            }
          } else {
            this.corpus[corpus] = [
              ...new Set([
                ...(this.corpus[corpus] || []),
                ...ids
              ])
            ]
          }

          /*
           * Copy pagination parameters e.g. all returned parameters except the response body
           * Do not alter data directly has API endpoints may return the same object in case of duplicated requests
           */
          if (folder) this.folderPagination[folder] = nextPagination
          else this.corpusPagination[corpus] = nextPagination

          count++
        }
      } catch (err) {
        const notificationStore = useNotificationStore()
        notificationStore.notify({ type: 'error', text: errorParser(err) })
        throw err
      }
    }
  },
  getters: {
    /**
     * Returns pagination parameters of either a folder or a corpus
     */
    pagination () {
      return (corpus: UUID | null = null, folder: UUID | null = null): Pagination | null => {
        if (folder !== null) {
          return this.folderPagination[folder] || null
        }
        if (corpus !== null) {
          return this.corpusPagination[corpus] || null
        }
        return null
      }
    }
  }
})
