
import { defineAsyncComponent, defineComponent, Component } from 'vue'
import { mapState, mapActions } from 'pinia'
import { useFolderPickerStore } from '@/stores'
import { Folder } from '@/stores/folderpicker'

export default defineComponent({
  components: {
    // Using a regular import here would cause issues with circular imports, since Folder also imports FolderList
    Folder: defineAsyncComponent<Component>(async () => await import('./Folder.vue'))
  },
  emits: {
    selectedFolder (payload: Folder) {
      return payload.id !== undefined
    }
  },
  props: {
    corpusId: {
      type: String,
      required: true
    },
    folderId: {
      type: String,
      default: null
    },
    // Highlight the currently selected folder
    currentFolderId: {
      type: String,
      default: null
    },
    exclude: {
      type: Array,
      default: null
    }
  },
  data: () => ({
    loading: false
  }),
  methods: {
    ...mapActions(useFolderPickerStore, ['nextFolders']),
    async fetchFolders () {
      this.loading = true
      try {
        await this.nextFolders(this.corpusId, this.folderId)
      } finally {
        this.loading = false
      }
    }
  },
  computed: {
    ...mapState(useFolderPickerStore, { folders: 'folders', corpusFolders: 'corpus', pagination: 'pagination' }),
    subfolders () {
      let folderIds
      if (this.folderId) {
        const parent = this.folders[this.folderId]
        if (!parent) throw new Error(`Parent folder ${this.folderId} not found in store`)
        folderIds = parent.subfolders || []
      } else {
        folderIds = this.corpusFolders[this.corpusId] || []
      }

      if (this.exclude?.length) {
        folderIds = folderIds.filter(id => !this.exclude.includes(id))
      }

      return folderIds.map(id => this.folders[id])
    },
    hasNext () {
      const pagination = this.pagination(this.corpusId, this.folderId)
      // Assume there can be more folders to load if there simply never have been any loaded folders
      return !pagination || pagination.next
    }
  },
  watch: {
    corpusId: {
      handler () {
        if ((!this.subfolders || !this.subfolders.length) && this.hasNext) this.fetchFolders()
      },
      immediate: true
    }
  }
})
