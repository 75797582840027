import { UUID, WorkerRunSummary } from '@/types'
import axios from 'axios'

export * from './date'
export * from './entity'
export * from './iiif'
export * from './polygon'
export * from './process'
export * from './rights'
export * from './text'

/**
 * A helper method to try to parse errors into human-readable strings.
 * This also parses anything that is not an Error, since JavaScript allows throwing anything, not just errors.
 *
 * @param error Any error.
 * @returns A string representing the error in a way as human-readable as possible.
 */
export const errorParser = (error: unknown): string => {
  if (typeof error === 'string') return error

  /*
   * There are two types of strings in JS!
   * We can't call null.toString() or undefined.toString(), but we can call String(null).toString()…
   */
  if (!error) return String(error).toString()

  let message = null
  if (axios.isAxiosError(error) && error.response?.data) {
    if (
      typeof error.response.data === 'string' ||
      error.response.data instanceof String ||
      Array.isArray(error.response.data)
    ) message = error.response.data
    else if (error.response.data.__all__) message = error.response.data.__all__
    else if (error.response.data.detail) message = error.response.data.detail
    else if (error.response.data.non_field_errors) message = error.response.data.non_field_errors
  }

  if (!message && error instanceof Error && error.message) message = error.message
  if (!message) return String(error).toString()

  if (Array.isArray(message)) return message.join(' - ')
  if (typeof message === 'string') return message
  return message.toString()
}

/**
 * Remove empty string values from an object
 * Since Vue components use empty strings for default options in <select> tags,
 * this prevents them from being sent as empty GET parameters.
 *
 * Note that this function cannot work with any object that has required properties in TypeScript,
 * since it cannot ensure that those required properties are not removed.
 */
export function removeEmptyStrings<T extends string> (obj: Record<string, T> = {} as Record<string, T>): Record<string, T> {
  const newObj: Record<string, T> = {}
  Object.keys(obj || {}).forEach((prop) => {
    if (prop !== 'url' && ![null, undefined, NaN, ''].includes(obj[prop])) { newObj[prop] = obj[prop] }
  })
  return newObj
}

/**
 * Given any value, this always returns an array.
 * Falsy values (false, NaN, undefined, null, '', 0, …) will return [].
 * Objects become an array of their values, and other primitives become [value].
 */
export function ensureArray<T> (value: T | ArrayLike<T> | { [key: string | number | symbol]: T }): T[] {
  if (!value) return []
  if (Array.isArray(value)) return value
  if (typeof value === 'object') return Object.values(value)
  return [value]
}

/**
 * Get a CSS RGBA color from an hexadecimal color string
 *
 * @param hex A color expressed in hexadecimal format (`#RRGGBB` or `RRGGBB`)
 * @param opacity Opacity, in percents (0 to 100)
 * @returns A color expressed using the `rgba()` CSS syntax
 */
export const colorFromHex = (hex: string, opacity: number): string => {
  hex = hex.replace('#', '')
  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)

  return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity / 100 + ')'
}

/**
 * Convert a float representing a size in bytes to a human-readable string.
 *
 * @param bytes Size in bytes.
 * @returns String expressing the size with a multiple of bytes.
 */
export const formatBytes = (bytes: number): string => {
  if (bytes === 0) return '0 B'
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const i = Math.floor(Math.log(bytes) / Math.log(1024))
  return parseFloat((bytes / Math.pow(1024, i)).toFixed(2)) + ' ' + sizes[i]
}

export interface PaginationParams {
  page?: string
  cursor?: string
}

/*
 * Return pagination parameters within a given URL.
 *
 * @param {url} the URL to be passed.
 * @returns {object} Object containing the different pagination query parameters.
 */
export const getPaginationParams = (url: string | URL): PaginationParams => {
  const searchString = new URL(url).search

  const search = new URLSearchParams(searchString)
  const page = search.get('page')
  const cursor = search.get('cursor')

  const params: PaginationParams = {}
  if (page !== null) params.page = page
  if (cursor !== null) params.cursor = cursor

  return params
}

export const hasWorkerRun = <T extends { worker_run: WorkerRunSummary | null }>(mlResult: T): mlResult is T & { worker_run: WorkerRunSummary } => mlResult.worker_run !== null
