
import { useModelStore, useNotificationStore } from '@/stores'
import { ModelUserConfigurationField } from '@/types/workerConfiguration'
import { mapActions, mapState } from 'pinia'
import { defineComponent, PropType } from 'vue'
import { UUID } from '@/types'
import ModelPicker from '@/components/Model/ModelPicker.vue'
import Modal from '@/components/Modal.vue'
import { UUID_REGEX } from '@/config'
import { errorParser } from '@/helpers'

export default defineComponent({
  emits: {
    'update:modelValue' (value: UUID) {
      return typeof value === 'string' && (value === '' || UUID_REGEX.test(value))
    }
  },
  props: {
    field: {
      type: Object as PropType<ModelUserConfigurationField>,
      required: true
    },
    modelValue: {
      type: String as PropType<UUID>,
      required: true
    },
    workerId: {
      type: String as PropType<UUID>,
      required: true
    }
  },
  components: {
    ModelPicker,
    Modal
  },
  data: () => ({
    loading: false,
    page: 1,
    modelsModal: false
  }),
  methods: {
    ...mapActions(useNotificationStore, ['notify']),
    ...mapActions(useModelStore, ['retrieveModel'])
  },
  computed: {
    ...mapState(useModelStore, ['models']),
    modelDisplayString () {
      return this.models[this.modelId]?.name || this.modelId
    },
    modelId: {
      get (): string {
        return this.modelValue
      },
      set (value: string) {
        this.$emit('update:modelValue', value)
        if (value) {
          this.notify({ type: 'success', text: `Model ${this.models[value].name} added to configuration` })
        } else {
          this.notify({ type: 'info', text: 'Model removed from configuration' })
        }
      }
    }
  },
  watch: {
    modelId: {
      immediate: true,
      async handler (newValue, oldValue) {
        if (newValue && newValue !== oldValue && !this.models[newValue]) {
          try {
            await this.retrieveModel(newValue)
          } catch (err) {
            this.notify({ type: 'error', text: `An error occurred retrieving model ${newValue}: ${errorParser(err)}` })
          }
        }
      }
    }
  }
})
