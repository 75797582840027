<template>
  <main class="container is-fluid columns is-centered">
    <div class="column is-half-desktop is-four-fifths-tablet">
      <h1 class="title">Reset your password</h1>
      <div class="notification is-danger" v-if="error">
        {{ error }}
      </div>
      <div class="notification is-success" v-else-if="success">
        Password reset instructions have been sent successfully.
        <router-link :to="{ name: 'home' }">Back to homepage</router-link>
      </div>
      <p v-else>We will send you an e-mail with a link to reset your password.</p>
      <form v-on:submit.prevent="submit">
        <div class="field">
          <label class="label" for="email">E-mail address</label>
          <div class="control">
            <input
              class="input"
              v-model="email"
              type="email"
              required
              :disabled="loading || null"
            />
          </div>
        </div>
        <div class="field is-grouped is-grouped-centered">
          <div class="control">
            <button
              type="submit"
              class="button is-primary"
              :class="{ 'is-loading': loading }"
              :disabled="!email || loading || null"
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  </main>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  data: () => ({
    email: '',
    error: null,
    success: false,
    loading: false
  }),
  methods: {
    ...mapActions('auth', ['sendResetEmail']),
    async submit () {
      this.success = false
      this.error = null
      this.loading = true
      try {
        await this.sendResetEmail({ email: this.email })
        this.success = true
      } catch (err) {
        this.error = err
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
