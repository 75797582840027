import { defineStore } from 'pinia'
import {
  TypedMetaData,
  MetaDataCreate,
  MetaData,
  UUID
} from '@/types'
import * as api from '@/api'
import { useNotificationStore } from '.'
import { errorParser } from '@/helpers'

interface State {
  metadata: {
    [elementId: UUID]: MetaData[]
  }
}

export const useMetaDataStore = defineStore('metadata', {
  state: (): State => ({
    metadata: {}
  }),
  actions: {
    async createMetadata (elementId: UUID, data: MetaDataCreate) {
      try {
        const resp = await api.createMetadata(elementId, data)
        if (this.metadata[elementId]) {
          this.metadata[elementId].push(resp)
        } else {
          this.metadata[elementId] = [resp]
        }
      } catch (err) {
        useNotificationStore().notify({ type: 'error', text: errorParser(err) })
        throw err
      }
    },
    async updateMetadata (elementId: UUID, metadata: TypedMetaData) {
      try {
        if (!this.metadata[elementId]) return
        const resp = await api.updateMetadata(metadata)
        const elementMetadata = this.metadata[elementId]
        const index = elementMetadata.findIndex(md => md.id === resp.id)
        if (index < 0) throw new Error(`Metadata ${metadata.id} not found on element ${elementId}`)
        elementMetadata.splice(index, 1, resp)
      } catch (err) {
        useNotificationStore().notify({ type: 'error', text: errorParser(err) })
      }
    },
    async deleteMetadata (elementId: UUID, metadata: TypedMetaData) {
      try {
        await api.deleteMetadata(metadata.id)
        const elementMetadata = this.metadata[elementId]
        const index = elementMetadata.findIndex(md => md.id === metadata.id)
        if (index < 0) throw new Error(`Metadata ${metadata.id} not found on element ${elementId}`)
        elementMetadata.splice(index, 1)
        this.metadata[elementId] = elementMetadata
      } catch (err) {
        useNotificationStore().notify({ type: 'error', text: errorParser(err) })
      }
    },
    async listMetadata (elementId: UUID) {
      try {
        this.metadata[elementId] = await api.listMetadata(elementId)
      } catch (err) {
        useNotificationStore().notify({ type: 'error', text: errorParser(err) })
        throw err
      }
    }
  }
})
