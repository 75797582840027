
import Mousetrap from 'mousetrap'
import { defineComponent } from 'vue'

export default defineComponent({
  emits: {
    'update:modelValue': (value: boolean) => typeof value === 'boolean'
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: null
    },
    showButton: {
      type: Boolean,
      default: true
    },
    // Fixes the modal to 90%
    isLarge: {
      type: Boolean,
      default: false
    },
    allowOverflow: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    close () {
      this.$emit('update:modelValue', false)
    },
    onOpen () {
      /*
       * Prevent double scrollbars when the modal is longer than the screen height
       * Inspired by buefy: https://github.com/buefy/buefy/blob/c7d46c37d70e71e448bebc13ff623b4d73a68bdb/src/components/modal/Modal.vue#L147
       */
      document.documentElement.classList.add('is-clipped')
      Mousetrap.bind('esc', this.close)
    },
    onClose () {
      document.documentElement.classList.remove('is-clipped')
      Mousetrap.unbind('esc')
    }
  },
  watch: {
    modelValue: {
      immediate: true,
      handler (newValue, oldValue) {
        if (newValue) this.onOpen()
        else if (oldValue !== undefined) this.onClose()
      }
    }
  },
  unmounted () {
    // Ensure we activate scrolling again if the modal was opened but suddenly gets destroyed
    this.onClose()
  }
})
