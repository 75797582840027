import { ElementTiny, PageNumberPagination, UUID } from '@/types'
import { defineStore } from 'pinia'
import { useNotificationStore } from './notification'
import { CorpusEntitiesListParameters, PageNumberPaginationParameters, listCorpusEntities, listEntityElements, listTranscriptionEntities, retrieveEntity } from '@/api'
import { errorParser } from '@/helpers'
import { Entity, EntityLight, TranscriptionEntity } from '@/types/entity'

export interface EntityTranscriptionResults extends PageNumberPagination<TranscriptionEntity> {
  loaded: number
}

interface State {
  /**
   * A single entity retrieved for the entity details page
   */
  entity: Entity | null,
  /**
   * A page of entities retrieved for the entity list page
   */
  entities: PageNumberPagination<EntityLight> | null,
  /**
   * A page of elements related to an entity
   */
  elements: PageNumberPagination<ElementTiny> | null,
  /**
   * Entities per transcription, with pagination status
   * { [transcription ID]: { count, results, loaded } }
   */
  inTranscription: {
    [transcriptionID: UUID]: EntityTranscriptionResults
  }
}

export const useEntityStore = defineStore('entity', {
  state: (): State => ({
    entity: null,
    entities: null,
    elements: null,
    inTranscription: {}
  }),
  actions: {
    async get (id: UUID) {
      this.entity = await retrieveEntity(id)
    },

    async list (id: UUID, payload: CorpusEntitiesListParameters) {
      try {
        this.entities = await listCorpusEntities(id, payload)
      } catch (err) {
        useNotificationStore().notify({ type: 'error', text: errorParser(err) })
        throw err
      }
    },

    async listElements (id: UUID, params: PageNumberPaginationParameters) {
      try {
        this.elements = await listEntityElements(id, params)
      } catch (err) {
        useNotificationStore().notify({ type: 'error', text: errorParser(err) })
      }
    },

    async listInTranscription (transcriptionId: UUID, page = 1) {
      // Init inTranscription on first response
      if (page === 1) delete this.inTranscription[transcriptionId]
      try {
        const data = await listTranscriptionEntities(transcriptionId, { page })

        let current = this.inTranscription[transcriptionId]
        if (!current || current.results === null) current = { loaded: 0, results: [], count: data.count, previous: null, next: null, number: 0 }
        current.results.push(...data.results)
        current.loaded += data.results.length

        this.inTranscription[transcriptionId] = current

        // Load other pages
        if (data.next) this.listInTranscription(transcriptionId, page + 1)
      } catch (err) {
        useNotificationStore().notify({ type: 'error', text: errorParser(err) })
      }
    }
  }
})
