
import {
  mapState as mapVuexState,
  mapActions as mapVuexActions
} from 'vuex'
import { mapState, mapActions } from 'pinia'
import { isAxiosError } from 'axios'
import { defineComponent, PropType } from 'vue'
import { UUID_REGEX } from '@/config'
import { UUID, Element } from '@/types'
import { DatasetPopulate } from '@/api'
import { Dataset, DatasetSet } from '@/types/dataset'
import { useCorporaStore, useDatasetStore, useNotificationStore } from '@/stores'
import { truncateMixin, corporaMixin } from '@/mixins'
import { ensureArray } from '@/helpers'
import { isEqual } from 'lodash'
import Modal from '@/components/Modal.vue'
import DatasetCreate from '@/components/Corpus/Datasets/EditModal.vue'

export default defineComponent({
  mixins: [
    truncateMixin,
    corporaMixin
  ],
  components: {
    DatasetCreate,
    Modal
  },
  emits: ['update:modelValue'],
  props: {
    corpusId: {
      type: String as PropType<UUID>,
      validator: value => typeof value === 'string' && UUID_REGEX.test(value),
      required: true
    },
    parentId: {
      type: String as PropType<UUID | null>,
      validator: value => (value === null) || (typeof value === 'string' && UUID_REGEX.test(value)),
      default: null
    },
    modelValue: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    selectedDatasetId: '' as UUID | '',
    recursive: false,
    // Use top level (inverse of recursive) when working without a parent
    topLevel: true,
    types: [] as string[],
    count: 1000,
    setPercentages: {} as Record<string, number>,
    loading: false,
    datasetModal: false,
    loadedParent: false,
    fieldErrors: {} as Record<string, string[] | Record<string, string[]> | null>,
    datasetColors: [
      'is-link',
      'is-danger',
      'is-success',
      'is-info',
      'is-warning',
      'is-primary'
    ],
    validRatio: true
  }),
  computed: {
    ...mapVuexState('elements', ['elements']),
    ...mapState(useCorporaStore, ['loaded']),
    ...mapState(useDatasetStore, ['datasets']),
    hasContribPrivilege () {
      return this.corpus?.id && this.canWrite(this.corpus)
    },
    parent (): Element | null {
      // Retrieve optional parent element from query parameter
      return this.parentId && this.elements[this.parentId]
    },
    availableDatasets (): Dataset[] {
      return Object.values(this.datasets).filter(dataset => dataset.corpus_id === this.corpusId)
    },
    dataset (): Dataset | null {
      return this.selectedDatasetId ? this.datasets[this.selectedDatasetId] : null
    },
    orderedSets (): DatasetSet[] {
      return (this.dataset?.sets || []).sort((a, b) => a.name.localeCompare(b.name))
    },
    watchSets () {
      /**
       * Allow the watcher on sets ratios to be reactive with deep attributes
       * Enables to automatically restore a valid total ratio by updating the last input
       */
      return { ...this.setPercentages }
    }
  },
  methods: {
    ...mapVuexActions('elements', { retrieveElement: 'get' }),
    ...mapActions(useNotificationStore, ['notify']),
    ...mapActions(useDatasetStore, ['listCorpusDatasets', 'populateDataset']),
    updateModal (value: boolean) {
      this.$emit('update:modelValue', value)
    },
    selectAllTypes () {
      if (!this.corpus?.id) return
      this.types = (Object.values(this.corpus.types)).map(t => t.slug)
    },
    async submit () {
      this.fieldErrors = {}
      if (!this.dataset) {
        this.fieldErrors.dataset = ['This field is required']
        return
      }
      if (Object.values(this.setPercentages).reduce((s, value) => s + value, 0) !== 100) {
        this.fieldErrors.sets = ['The sum of all ratios must be 100%']
        return
      }
      this.loading = true
      const payload: DatasetPopulate = {
        recursive: this.parentId ? this.recursive : !this.topLevel,
        types: this.types,
        count: this.count,
        sets: Object.fromEntries(
          Object.entries(this.setPercentages)
            // Drop any value equal to zero to avoid a backend validation error
            .filter(entry => entry[1] !== 0)
            .map(([name, value]) => [name, value / 100])
        )
      }
      if (this.parentId) payload.parent_id = this.parentId
      try {
        await this.populateDataset(this.dataset.id, payload)
        // On success, redirects to dataset details
        this.notify({ type: 'success', text: `${this.count} elements have been successfully distributed.` })
        this.$emit('update:modelValue', false)
        this.$router.push({ name: 'dataset-details', params: { datasetId: this.dataset.id } })
      } catch (err) {
        if (isAxiosError(err) && err.response?.status === 400 && err.response.data) {
          this.fieldErrors = Object.fromEntries(new Set(Object.entries(err?.response?.data).map(([k, v]) => [k, ensureArray(v) as string[]])))
        }
      } finally {
        this.loading = false
      }
    },
    selectDataset (datasetId: string) {
      this.selectedDatasetId = datasetId
    }
  },
  watch: {
    parentId: {
      immediate: true,
      async handler (newValue: UUID | null) {
        if (!newValue || !UUID_REGEX.test(newValue) || this.parent) return
        try {
          await this.retrieveElement({ id: newValue })
        } catch {
          // The component displays a warning instead of the form
        } finally {
          this.loadedParent = true
        }
      }
    },
    corpus: {
      immediate: true,
      async handler (newValue) {
        // List datasets in case none are initially found
        if (!newValue.id || this.availableDatasets.length > 0) return
        try {
          await this.listCorpusDatasets(this.corpusId)
        } catch (err) {
          this.notify({ type: 'error', text: 'An error occurred fetching datasets for this corpus.' })
        }
      }
    },
    dataset (newValue: Dataset | null) {
      if (!newValue) return
      const setNames = newValue.sets.map((s: DatasetSet) => s.name)
      if (isEqual(new Set(setNames), new Set(['train', 'dev', 'test']))) {
        // In case sets are train, dev and test, use the usual distribution
        this.setPercentages = { train: 80, dev: 10, test: 10 }
      } else {
        // Otherwise distribute equally
        const ratio = Math.floor(100 / setNames.length)
        // Round the last ratio value so we are exactly at 100% in total
        const lastRatio = 100 - (ratio * (setNames.length - 1))
        this.setPercentages = {
          ...Object.fromEntries(setNames.slice(0, -1).map((set: string) => [set, ratio])),
          [setNames.at(-1) as string]: lastRatio
        }
      }
    },
    'corpus.types': {
      // Initially selects all types
      immediate: true,
      handler: 'selectAllTypes'
    },
    watchSets: {
      immediate: false,
      deep: true,
      handler (newValue: Record<string, number>, oldValue: Record<string, number>) {
        /**
         * Automatically completes the difference to 100% on the last set
         */
        const lastSet = this.orderedSets.at(-1)?.name
        if (!lastSet) return

        // Avoid negative values
        if (this.setPercentages[lastSet] < 0) {
          this.setPercentages[lastSet] = 0
          return
        }

        const sum = Object.values(newValue).reduce((s, value) => s + value, 0)
        if (sum === 100) {
          this.validRatio = true
          return
        }

        // Allow to move the last slice freely
        if (newValue[lastSet] !== oldValue[lastSet]) {
          this.validRatio = false
          return
        }

        // Finally, automatically adjust the last value
        this.setPercentages[lastSet] = newValue[lastSet] + (100 - sum)
      }
    }
  }
})
