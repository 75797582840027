<template>
  <main class="container is-fluid content">
    <h1 class="title">Server unreachable</h1>
    <p>
      A connection error occurred while fetching authentication data from the Arkindex server.<br />
      Please try again later.
    </p>
  </main>
</template>

<script>
export default {
}
</script>
