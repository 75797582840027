<template>
  <main class="container is-fluid">
    <template v-if="selectedCorpora.length">
      <button class="button has-text-danger is-pulled-right mb-3" v-on:click="deleteAllSelection">
        Unselect all
      </button>
      <div class="is-clearfix"></div>
      <div
        v-for="[corpusId, elements] in selectedCorpora"
        :key="corpusId"
      >
        <CorpusSelection :corpus-id="corpusId" v-if="elements.length" />
        <hr />
      </div>
    </template>
    <div v-else class="notification is-info">Empty selection</div>
  </main>
</template>

<script>
import { mapState } from 'vuex'
import CorpusSelection from '@/components/Navigation/CorpusSelection'
export default {
  components: {
    CorpusSelection
  },
  computed: {
    ...mapState('selection', ['count', 'selection']),
    /**
     * Filter corpora to ensure we only display corpora with some selected elements;
     * empty arrays should not matter
     */
    selectedCorpora () {
      return Object.entries(this.selection).filter(([, elements]) => elements?.length)
    }
  },
  methods: {
    deleteAllSelection () {
      this.$store.dispatch('selection/clear')
    }
  }
}
</script>
