import { clone, assign } from 'lodash'
import axios from 'axios'
import * as api from '@/api'
import { errorParser } from '@/helpers'

export const initialState = () => ({
  files: {},
  loaded: false
})

export const mutations = {
  set (state, files) {
    const newFiles = clone(state.files)
    files.forEach(file => { newFiles[file.id] = file })
    state.files = newFiles
  },
  toggleLoaded (state) {
    state.loaded = !state.loaded
  },
  remove (state, { id }) {
    delete state.files[id]
  },
  reset (state) {
    assign(state, initialState())
  },
  setAllSelected (state, selected) {
    if (typeof (selected) !== 'boolean') {
      throw new Error('"selected" value must be a boolean.')
    }
    state.files = Object.fromEntries(Object.values(state.files).map(file => [file.id, { ...file, selected }]))
  }
}

export const actions = {
  async list ({ commit, state }, { corpus }) {
    if (state.loaded) commit('reset')
    try {
      let page = 1
      let next, results
      do {
        ({ next, results } = await api.listDataFiles({ corpus, page }))
        commit('set', results)
        if (next) page++
      } while (next)
      commit('toggleLoaded')
    } catch (err) {
      commit('notifications/notify', { type: 'error', text: errorParser(err) }, { root: true })
    }
  },

  /*
   * Upload a DataFile from a local file or a URL.
   * Expects a File instance as `file` or a URL string as `url`.
   */
  async upload ({ commit }, { corpus, file = null, url = null }) {
    if (!(Boolean(file) ^ Boolean(url))) throw new Error('Either a local file or a URL must be set to upload a DataFile')
    try {
      const payload = { corpus }
      let blob = null
      if (url) {
        /*
         * When fetching from a URL, we will get a Blob without a filename.
         * Some browsers may not send a filename in Content-Disposition on API requests for Blob instances,
         * so we deduce the true filename from the URL, by getting its path,
         * removing trailing slashes and getting the last portion of the path.
         * http://something.com/a/b/c/d.json/?bla=bleh#anchor → /a/b/c/d.json/ → /a/b/c/d.json → d.json
         */
        payload.name = new URL(url).pathname.replace(/\/$/, '').split('/').pop()

        let blobResp
        try {
          blobResp = await fetch(url, { mode: 'cors' })
        } catch (err) {
          if (err.message.includes('NetworkError')) {
            throw new Error(`Remote URL download failed: ${err.message} The remote server might not support or allow downloading with CORS.`)
          }
          throw err
        }

        if (!blobResp.ok) {
          throw new Error('Remote URL download failed: HTTP ' + blobResp.status)
        }

        blob = await blobResp.blob()
      } else {
        payload.name = file.name
        blob = file
      }

      payload.content_type = blob.type
      payload.size = blob.size

      const datafile = await api.createDataFile(payload)
      commit('set', [{
        ...datafile,
        progress: null
      }])

      /*
       * S3 upload
       * Uses an inner try-catch because beyond this point,
       * error handling also has to set the `error` status on the file.
       */
      try {
        await axios.put(datafile.s3_put_url, blob, {
          headers: {
            'Content-Type': payload.content_type
          },
          withCredentials: false,
          onUploadProgress (event) {
            /*
             * Actual progress may not always be available if Content-Length is not available,
             * or if the browser does not support it; we will set `progress` to null
             * to let the progress bar be visible, but indeterminate ("infinite loading")
             */
            let progress = null
            if (event.lengthComputable) {
              progress = event.loaded / event.total
            }
            commit('set', [{ ...datafile, progress }])
          }
        })

        // Mark as infinite loading while checking the file
        commit('set', [{ ...datafile, progress: null }])

        // Set the DataFile to checked and auto-select
        await api.updateDataFile({ id: datafile.id, status: 'checked' })
        commit('set', [{ ...datafile, status: 'checked', selected: true }])
      } catch (err) {
        // Remove progress data if it existed
        commit('set', [datafile])
        /*
         * Set the DataFile status to error
         * When this is successful, re-throw the Error for the outer try-catch.
         */
        await api.updateDataFile({ id: datafile.id, status: 'error' })
        commit('set', [{ ...datafile, status: 'error' }])
        throw err
      }
    } catch (err) {
      commit('notifications/notify', { type: 'error', text: errorParser(err) }, { root: true })
      throw err
    }
  },

  async delete ({ commit }, { id }) {
    try {
      await api.destroyDataFile(id)
      commit('remove', { id })
    } catch (err) {
      commit('notifications/notify', { type: 'error', text: errorParser(err) }, { root: true })
    }
  }
}

export const getters = {
  selectedFiles (state) {
    if (!state.files) return
    return Object.values(state.files).filter(file => file.selected)
  }
}

export default {
  namespaced: true,
  state: initialState(),
  mutations,
  actions,
  getters
}
