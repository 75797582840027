<template>
  <figure class="is-inline-block">
    <div class="image" :style="imageStyle">
      <div class="placeholdertext notification is-danger" v-if="imgError">Media loading failed</div>
      <div class="placeholdertext" v-else-if="!loaded">Loading...</div>
      <img
        :src="source"
        v-on:load="loaded = true"
        v-on:error="imgError = true"
        :style="imgStyle"
      />
    </div>
    <router-link
      :to="{ name: 'element-details', params: { id: element.id } }"
      class="is-pulled-left"
      :title="`${element.name} (${typeName(element.type)})`"
    >
      {{ truncateShort(element.name) }} ({{ truncateShort(typeName(element.type)) }})
    </router-link>
  </figure>
</template>

<script>
import { mapState } from 'pinia'
import { iiifUri, boundingBox } from '@/helpers'
import { truncateMixin, corporaMixin } from '@/mixins'
import { useDisplayStore } from '@/stores'

export default {
  mixins: [
    truncateMixin,
    corporaMixin
  ],
  props: {
    element: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    loaded: false,
    imgError: false
  }),
  computed: {
    ...mapState(useDisplayStore, ['iiifWidth']),
    corpusId () {
      return this.element.corpus.id
    },
    imageStyle () {
      if (this.loaded || !this.element.zone) return {}
      return {
        'padding-bottom': this.zoneSize.height / this.zoneSize.width * 100 + '%'
      }
    },
    zoneSize () {
      return boundingBox(this.element.zone)
    },
    source () {
      if (this.element.thumbnail_url) return this.element.thumbnail_url
      if (this.element.zone) return iiifUri(this.element.zone, { imageWidth: this.iiifWidth(2 / 3) })
      return null
    },
    imgStyle () {
      return { display: this.loaded ? 'block' : 'none' }
    }
  }
}
</script>

<style lang="scss" scoped>
figure {
  width: 100%;
}
.image {
  background-color: lightgray;
  img {
    width: 100%;
    vertical-align: middle;
  }
  .placeholdertext {
    position: absolute;
    top: 3em;
    left: 0;
    right: 0;
    text-align: center;
  }
}
</style>
