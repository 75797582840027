
import { PropType, defineComponent } from 'vue'

import { mapState, mapActions } from 'pinia'
import { mapGetters } from 'vuex'
import { useExportStore } from '@/stores'
import { UUID } from '@/types'
import { CorpusExport } from '@/types/export'
import { corporaMixin } from '@/mixins'

import { API_BASE_URL, EXPORT_STATES } from '@/config'
import { ago } from '@/helpers'

import Paginator from '@/components/Paginator.vue'
import Modal from '@/components/Modal.vue'
import DeleteModal from '@/components/Corpus/Exports/DeleteModal.vue'

export default defineComponent({
  mixins: [
    corporaMixin
  ],
  components: {
    DeleteModal,
    Modal,
    Paginator
  },
  emits: {
    'update:modelValue': (value: boolean) => typeof value === 'boolean'
  },
  props: {
    corpusId: {
      type: String as PropType<UUID>,
      required: true
    },
    modelValue: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    page: 1,
    loading: false,
    EXPORT_STATES,
    deleteModal: false,
    toDelete: null as CorpusExport | null,
    source: 'default'
  }),
  computed: {
    ...mapGetters('auth', ['isVerified', 'hasFeature']),
    ...mapState(useExportStore, ['corpusExports', 'sources']),
    title () {
      if (this.corpus && this.corpus.name) return `Exports of project ${this.corpus.name}`
      return 'Project exports'
    },
    canStart (): boolean {
      return this.canWrite(this.corpus)
    },
    buttonTitle () {
      if (this.canStart) return
      return 'Contributor access is required to start a project export.'
    },
    hasContributorAccess (): boolean {
      return this.isVerified && (
        (this.corpus && this.canWrite(this.corpus))
      )
    }
  },
  methods: {
    ...mapActions(useExportStore, ['list', 'listSources', 'start']),
    async load (page = 1) {
      this.loading = true
      try {
        await this.list(this.corpusId, page)
      } finally {
        this.loading = false
      }
    },
    async startExport () {
      if (!this.canStart || this.loading) return
      this.loading = true
      try {
        await this.start(this.corpusId, this.source)
        await this.load()
      } finally {
        this.loading = false
      }
    },
    canDelete (corpusExport: CorpusExport): boolean {
      return ['done', 'running'].includes(corpusExport.state) && this.hasContributorAccess
    },
    openDeleteModal (corpusExport: CorpusExport) {
      this.toDelete = corpusExport
      this.deleteModal = true
    },
    downloadLink (corpusExport: CorpusExport): string | undefined {
      if (corpusExport.state !== 'done') return
      return `${API_BASE_URL}/export/${corpusExport.id}/`
    },
    dateAgo (date: string): string {
      return ago(new Date(date))
    }
  },
  watch: {
    modelValue: {
      handler (newValue) {
        if (newValue) {
          if (this.hasFeature('enterprise') && this.isVerified) this.listSources()
          this.load(1)
        }
      },
      immediate: true
    },
    page: 'load'
  }
})
