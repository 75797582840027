
import { mapActions } from 'pinia'
import { errorParser, ensureArray } from '@/helpers'
import Modal from '@/components/Modal.vue'
import { useNotificationStore, useModelStore } from '@/stores'
import { CreateModelPayload } from '@/api'
import { PropType, defineComponent } from 'vue'
import { isAxiosError } from 'axios'
import { Model } from '@/types/model'

export default defineComponent({
  components: {
    Modal
  },
  emits: {
    reload: (value: boolean) => typeof value === 'boolean'
  },
  props: {
    mode: {
      type: String,
      required: true
    },
    modelInstance: {
      type: Object as PropType<Model>,
      default: null
    }
  },
  data: () => ({
    openModal: false,
    payload: {
      name: '',
      description: ''
    } as CreateModelPayload,
    loading: false,
    fieldErrors: {} as Partial<Record<keyof CreateModelPayload, string[]>>
  }),
  computed: {
    allowOpen () {
      if (this.mode === 'edit' && this.modelInstance) return this.modelInstance.rights.includes('write')
      return true
    },
    openButtonTitle () {
      if (this.mode === 'edit') {
        return this.allowOpen
          ? 'Edit model'
          : 'A contributor access level is required to edit a model'
      }
      return 'Create a new model'
    },
    modalTitle () {
      if (this.mode === 'edit' && this.modelInstance) return `Edit model "${this.modelInstance.name}"`
      return 'Create a new model'
    },
    saveButtonName () {
      if (this.mode === 'edit') return 'Update'
      return 'Create'
    }
  },
  methods: {
    ...mapActions(useModelStore, ['updateModel', 'createModel', 'listModels']),
    ...mapActions(useNotificationStore, ['notify']),
    async saveAction () {
      if (this.loading || (this.mode === 'edit' && !this.modelInstance) || !this.payload.name.length) return
      this.loading = true
      this.fieldErrors = {}
      try {
        if (this.mode === 'edit' && this.modelInstance) {
          await this.updateModel(this.modelInstance.id, this.payload)
          this.notify({ type: 'info', text: 'Model updated successfully.' })
        } else {
          await this.createModel(this.payload)
          this.$emit('reload', true)
          this.notify({ type: 'success', text: 'Model successfully created.' })
        }
        this.openModal = false
      } catch (err) {
        this.notify({ type: 'error', text: errorParser(err) })
        if (isAxiosError(err)) {
          this.fieldErrors = err?.response?.data
            ? Object.fromEntries(Object.entries(err?.response?.data).map(([k, v]) => [k, ensureArray(v)]))
            : {}
        }
      } finally {
        this.loading = false
      }
    }
  },
  watch: {
    modelInstance: {
      immediate: true,
      handler () {
        if (!this.modelInstance) return
        this.payload.name = this.modelInstance.name
        this.payload.description = this.modelInstance.description
      }
    }
  }
})
