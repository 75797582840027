import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-35b91e64"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "has-text-grey-light mr-2"
}
const _hoisted_2 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: _normalizeClass(["dropdown-toggle is-flex", { 'disabled': _ctx.disabled }]),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggle && _ctx.toggle(...args)))
    }, [
      (!_ctx.disabled)
        ? (_openBlock(), _createElementBlock("a", _hoisted_1, [
            _renderSlot(_ctx.$slots, "trigger", {}, () => [
              _createElementVNode("span", null, [
                _createElementVNode("i", {
                  class: _normalizeClass(["icon-down-open trigger-icon", { 'active': _ctx.toggled }])
                }, null, 2)
              ])
            ], true)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("h2", {
        title: _ctx.disabled ? _ctx.title + ' not available' : ''
      }, _toDisplayString(_ctx.title), 9, _hoisted_2)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["mt-2", { 'is-hidden': !_ctx.toggled || _ctx.disabled }])
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 2)
  ]))
}