<template>
  <main class="container is-fluid columns is-centered">
    <div class="column is-half-desktop is-four-fifths-tablet">
      <div class="notification is-danger" v-if="error && typeof error === 'string'">
        <button class="delete" v-on:click="error = null"></button>
        {{ error }}
      </div>

      <form v-on:submit.prevent="login">
        <div class="field">
          <label class="label">E-mail address</label>
          <div class="control">
            <input
              type="email"
              v-model="email"
              class="input"
              :disabled="loading || null"
              required
              tabindex="1"
            />
          </div>
        </div>
        <div class="field">
          <div class="columns is-gapless label-columns">
            <div class="column">
              <label class="label">Password</label>
            </div>
            <div class="column is-narrow">
              <router-link
                :to="{ name: 'password-reset' }"
                tabindex="-1"
              >
                Forgot your password?
              </router-link>
            </div>
          </div>
          <div class="control">
            <input
              type="password"
              v-model="password"
              class="input"
              :disabled="loading || null"
              required
              tabindex="2"
            />
          </div>
        </div>
        <div class="field is-grouped is-grouped-centered">
          <p class="control">
            <button
              type="submit"
              class="button is-primary"
              :class="{ 'is-loading': loading }"
              :disabled="!email || !password || loading || null"
              tabindex="3"
            >
              Login
            </button>
          </p>
        </div>
      </form>
    </div>
  </main>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { errorParser } from '@/helpers'
export default {
  data: () => ({
    email: '',
    password: '',
    loading: false,
    error: null
  }),
  mounted () {
    this.tryRedirect(this.user)
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters('auth', ['isLoggedOn'])
  },
  methods: {
    async login () {
      this.loading = true
      try {
        await this.$store.dispatch('auth/login', {
          email: this.email,
          password: this.password
        })
      } catch (err) {
        this.error = errorParser(err)
      } finally {
        this.loading = false
      }
    },
    tryRedirect () {
      if (!this.isLoggedOn) return
      if (this.$route.query && this.$route.query.next) this.$router.replace(this.$route.query.next)
      else this.$router.push({ name: 'corpus-list' })
    }
  },
  watch: {
    // Use a watched property in case another request runs and updates the current user
    user () {
      this.tryRedirect()
    }
  }
}
</script>

<style scoped>
.columns.label-columns:not(:last-child) {
  margin-bottom: 0.5rem;
}
</style>
