
import { defineComponent, PropType } from 'vue'
import { Folder } from '@/stores/folderpicker'
import Modal from '@/components/Modal.vue'
import FolderList from './FolderList.vue'

export default defineComponent({
  inheritAttrs: false,
  components: {
    Modal,
    FolderList
  },
  emits: {
    'update:modelValue' (folder: Folder) {
      return folder.id !== undefined
    }
  },
  props: {
    corpusId: {
      type: String,
      required: true
    },
    modelValue: {
      type: Object as PropType<Folder | null>,
      default: null
    },
    // Allow overriding the input's placeholder, but set a default
    placeholder: {
      type: String,
      default: 'Pick a folder…'
    },
    // Exclude specific elements and all of their children from the list
    exclude: {
      type: Array,
      default: null
    }
  },
  data: () => ({
    opened: false
  })
})
