import axios from 'axios'
import { PageNumberPagination, UUID } from '@/types'
import { PageNumberPaginationParameters, unique } from '.'
import { ElementTranscription, Transcription } from '@/types/transcription'

export interface ListTranscriptionsParameters extends PageNumberPaginationParameters {
  element_type?: string
  recursive?: boolean
  worker_run?: UUID | false
  worker_version?: UUID | false
}

// List transcriptions of an element.
export const listTranscriptions = unique(
  async (elementId: UUID, params: ListTranscriptionsParameters): Promise<PageNumberPagination<ElementTranscription>> => (
    await axios.get(`/element/${elementId}/transcriptions/`, { params })).data
)

export interface CreateTranscriptionPayload extends Pick<Transcription, 'text'>, Partial<Pick<Transcription, 'orientation' | 'confidence'>> {
  worker_run_id?: UUID | null
}

// Add a manual transcription
export const createTranscription = unique(
  async (elementId: UUID, data: CreateTranscriptionPayload): Promise<Transcription> => (await axios.post(`/element/${elementId}/transcription/`, data)).data
)

export type UpdateTranscriptionPayload = Partial<Pick<Transcription, 'text' | 'orientation'>>

// Patch a transcription
export const updateTranscription = unique(
  async (transcriptionId: UUID, data: UpdateTranscriptionPayload): Promise<Transcription> => (await axios.patch(`/transcription/${transcriptionId}/`, data)).data
)

// Delete a transcription
export const deleteTranscription = unique(async (transcriptionId: UUID) => await axios.delete(`/transcription/${transcriptionId}/`))
