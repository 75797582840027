
import { PropType, defineComponent } from 'vue'
import { mapState } from 'pinia'
import { parseEntities, orientationStyle } from '@/helpers'
import Token from './Token.vue'
import { useEntityStore } from '@/stores'
import { Transcription } from '@/types/transcription'
import { UUID } from '@/types'

export default defineComponent({
  props: {
    transcription: {
      type: Object as PropType<Transcription>,
      required: true
    },
    /**
     * Filter TranscriptionEntities by WorkerRun UUID.
     * If unset, no TranscriptionEntities are displayed.
     */
    workerRunFilter: {
      type: String as PropType<UUID | 'manual' | ''>,
      default: ''
    }
  },
  components: {
    Token
  },
  computed: {
    ...mapState(useEntityStore, ['inTranscription']),
    loaded () {
      const { results, count, loaded } = this.inTranscription?.[this.transcription.id] ?? {}
      return results && loaded >= count
    },
    transcriptionEntities () {
      const transcriptionEntities = this.inTranscription?.[this.transcription.id]?.results
      if (!this.loaded || !transcriptionEntities?.length || !this.workerRunFilter) return []
      if (this.workerRunFilter === 'manual') {
        return transcriptionEntities.filter(transcriptionEntity => transcriptionEntity.worker_run === null)
      }
      return transcriptionEntities.filter(transcriptionEntity => transcriptionEntity.worker_run?.id === this.workerRunFilter)
    },
    tokens () {
      return parseEntities(this.transcription.text, this.transcriptionEntities)
    }
  },
  methods: {
    orientationStyle
  }
})
