import axios from 'axios'
import { unique } from '.'

// List processes
export const listProcesses = unique(async params => (await axios.get('/process/', { params })).data)

// List templates
export const listTemplates = listProcesses

// Retrieve a process details
export const retrieveProcess = unique(async id => (await axios.get(`/process/${id}/`)).data)

// Create a new process
export const createProcess = async payload => (await axios.post('/process/corpus/', payload)).data

// Update a process
export const updateProcess = async ({ id, payload }) => (await axios.patch(`/process/${id}/`, payload)).data

// Retry a process
export const retryProcess = unique(async id => (await axios.post(`/process/${id}/retry/`)).data)

// Delete a process
export const deleteProcess = unique(async id => await axios.delete(`/process/${id}/`))

// Start a process
export const startProcess = unique(async ({ id, payload }) => (await axios.post(`/process/${id}/start/`, payload)).data)

// List elements involved in a process
export const listProcessElements = unique(async ({ id, ...params }) => (await axios.get(`/process/${id}/elements/`, { params })).data)

// Create a template based on a process
export const createProcessTemplate = unique(async ({ id, payload }) => (await axios.post(`/process/${id}/template/`, payload)).data)

// Apply a process template on a process
export const applyProcessTemplate = unique(async ({ id, payload }) => (await axios.post(`/process/${id}/apply/`, payload)).data)

// Clear a process (remove all worker runs and templates)
export const clearProcess = unique(async id => await axios.delete(`/process/${id}/clear/`))

// Select elements with a worker activity failure on the process
export const selectProcessFailures = unique(async id => await axios.post(`/process/${id}/select-failures/`))

export const listProcessSets = unique(async ({ processId, ...params }) => (await axios.get(`/process/${processId}/sets/`, { params })).data)

export const createProcessSet = unique((processId, setId, payload) => axios.post(`/process/${processId}/set/${setId}/`, payload))

export const deleteProcessSet = unique((processId, setId) => axios.delete(`/process/${processId}/set/${setId}/`))

// Create a process from the failures on another process
export const createProcessFailures = async id => (await axios.post(`/process/${id}/process-failures/`)).data
