<template>
  <div>
    <div class="is-inline-block is-size-4 mb-4" v-if="corpus">
      <router-link :to="{ name: 'navigation', params: { corpusId } }">
        {{ corpus.name }}
      </router-link>
    </div>
    <div class="dropdown is-right is-pulled-right is-hoverable dropdown-menu-min-width">
      <div class="dropdown-trigger">
        <button
          class="button is-info"
        >
          <span>Actions</span>
          <i class="icon-down-open"></i>
        </button>
      </div>
      <div class="dropdown-menu">
        <div class="dropdown-content">
          <a
            :disabled="!canExecute || null"
            class="dropdown-item"
            v-on:click="createProcess"
            :title="canExecute ? 'Build a new ML process from those elements.' : executeDisabledTitle"
          >
            <i class="icon-cog-alt"></i>
            Create process
          </a>
          <a
            :disabled="!canCreate || null"
            class="dropdown-item"
            v-on:click="moveSelectionModal = canCreate"
            :title="canCreate ? 'Move selected elements.' : executeDisabledTitle"
          >
            <i class="icon-direction"></i>
            Move elements
          </a>
          <a
            :disabled="!canCreate || null"
            class="dropdown-item"
            v-on:click="createParentModal = canCreate"
            :title="canCreate ? 'Link selected elements to another parent folder.' : createDisabledTitle"
          >
            <i class="icon-direction"></i>
            Link to another parent
          </a>
          <a
            :disabled="!canCreate || null"
            class="dropdown-item"
            v-on:click="datasetSelectionModal = canCreate"
            :title="canCreate ? 'Add those elements to a dataset.' : createDisabledTitle"
          >
            <i class="icon-bookmark"></i>
            Add to a dataset
          </a>
          <a
            :disabled="!canCreate || null"
            class="dropdown-item"
            v-on:click="addClassificationModal = canCreate"
            :title="canCreate ? 'Add a classification to all selected elements.' : createDisabledTitle"
          >
            <i class="icon-plus"></i>
            Add classification
          </a>
          <a
            :disabled="!canCreate || allValidated || null"
            class="dropdown-item"
            v-on:click="validateClassificationModal = canCreate && !allValidated"
            :title="canCreate && !allValidated ? 'Validate the classification for all selected elements.' : createDisabledTitle"
          >
            <i class="icon-check"></i>
            Validate classification
          </a>
          <a
            :disabled="!canExecute || null"
            class="dropdown-item has-text-danger"
            v-on:click="deleteResultsModal = canExecute"
            :title="canExecute ? 'Delete worker results on the selected elements and their children.' : executeDisabledTitle"
          >
            <i class="icon-trash"></i>
            Delete worker results
          </a>
          <a
            :disabled="!canExecute || null"
            class="dropdown-item has-text-danger"
            v-on:click="deleteModal = canExecute"
            :title="canExecute ? 'Delete this complete selection.' : executeDisabledTitle"
          >
            <i class="icon-trash"></i>
            Delete selected elements
          </a>
        </div>
      </div>
    </div>

    <Modal allow-overflow v-model="addClassificationModal" title="Add classification">
      <div class="control" v-if="hasMLClasses[corpusId]">
        <MLClassSelect
          :corpus-id="corpusId"
          v-model="selectedNewClassification"
          placeholder="ML class"
          v-on:submit="createClassification"
        />
      </div>
      <div v-else class="notification is-warning">No classification for this project</div>
      <template v-slot:footer="{ close }">
        <button class="button" v-on:click="close">Cancel</button>
        <button
          class="button is-primary"
          :class="{ 'is-loading': isSavingNewClassification }"
          :disabled="isSavingNewClassification || !selectedNewClassification || null"
          v-on:click="createClassification"
        >
          <span v-if="!isSavingNewClassification">Add</span>
        </button>
      </template>
    </Modal>

    <Modal v-model="validateClassificationModal" title="Validate classification">
      <div>
        Are you sure you want to validate the classifications for the selection of the {{ corpus.name }} project ?
      </div>
      <template v-slot:footer="{ close }">
        <button class="button" v-on:click="close">Cancel</button>
        <button class="button is-success" v-on:click.prevent="validateClassification">Validate</button>
      </template>
    </Modal>

    <Modal v-model="deleteModal" :title="'Delete selected elements'">
      <p>
        Are you sure you want to delete all selected elements on project <strong>{{ corpus.name }}</strong>?<br />
        Child elements on those elements will also be deleted recursively.<br />
        This action is irreversible.
      </p>
      <template v-slot:footer="{ close }">
        <button class="button" v-on:click="close">Cancel</button>
        <button
          class="button is-danger"
          :class="{ 'is-loading': deleteLoading }"
          v-on:click="performDelete"
        >
          Delete
        </button>
      </template>
    </Modal>

    <Modal v-model="createParentModal" title="Link selected elements to another parent">
      <FolderPicker v-model="pickedFolder" :corpus-id="corpusId" :exclude="selectedElementIds" />
      <template v-slot:footer="{ close }">
        <button class="button" v-on:click="close">Cancel</button>
        <button
          class="button is-primary"
          :class="{ 'is-loading': createParentLoading }"
          :disabled="!pickedFolder || null"
          v-on:click="performCreateParent"
        >
          Link elements
        </button>
      </template>
    </Modal>

    <Modal v-model="moveSelectionModal" title="Move selected elements">
      <FolderPicker v-model="pickedFolder" :corpus-id="corpusId" :exclude="selectedElementIds" />
      <span>
        Moving elements will remove all links towards pre-existing (direct) parents.
        <br />
        It will also move all their children elements and all attached transcriptions, entities, and classifications. All current paths will be replaced by the newly created paths.
        <br />
        This action is irreversible.
      </span>
      <template v-slot:footer="{ close }">
        <button class="button" v-on:click="close">Cancel</button>
        <button
          class="button is-primary"
          :class="{ 'is-loading': moveLoading }"
          :disabled="!pickedFolder || null"
          v-on:click="performMove"
        >
          Move elements
        </button>
      </template>
    </Modal>

    <DeleteResultsModal v-model="deleteResultsModal" :corpus-id="corpusId" selection />

    <DatasetFromSelectionModal v-model="datasetSelectionModal" :corpus-id="corpusId" />

    <ElementList
      :elements="elements"
      max-size
    />
  </div>
</template>

<script>
import { mapState as mapVuexState, mapGetters } from 'vuex'
import { mapActions, mapState } from 'pinia'
import { useClassificationStore } from '@/stores'

import { corporaMixin } from '@/mixins'
import { createProcessRedirect } from '@/helpers'

import MLClassSelect from '@/components/MLClassSelect.vue'
import Modal from '@/components/Modal.vue'
import DeleteResultsModal from '@/components/Process/Workers/DeleteResultsModal.vue'
import DatasetFromSelectionModal from '@/components/Navigation/DatasetFromSelectionModal.vue'
import ElementList from './ElementList'
import FolderPicker from '@/components/Navigation/FolderPicker'

export default {
  mixins: [
    corporaMixin
  ],
  components: {
    ElementList,
    DeleteResultsModal,
    MLClassSelect,
    Modal,
    FolderPicker,
    DatasetFromSelectionModal
  },
  props: {
    corpusId: {
      type: String,
      required: true
    }
  },
  data: () => ({
    selectedNewClassification: '',
    isSavingNewClassification: false,
    addClassificationModal: false,
    validateClassificationModal: false,
    allValidated: false,
    deleteModal: false,
    deleteLoading: false,
    deleteResultsModal: false,
    moveSelectionModal: false,
    moveLoading: false,
    createParentModal: false,
    createParentLoading: false,
    pickedFolder: null,
    datasetSelectionModal: false
  }),
  mounted () {
    if (this.hasMLClasses[this.corpusId] === undefined) {
      this.listCorpusMLClasses(this.corpusId, { page_size: 1 })
    }
  },
  computed: {
    ...mapGetters('auth', ['isVerified']),
    ...mapVuexState('elements', { storeElements: 'elements' }),
    ...mapVuexState('selection', ['selection']),
    ...mapState(useClassificationStore, ['hasMLClasses']),
    canCreate () {
      return this.corpus && this.isVerified && this.canWrite(this.corpus)
    },
    canExecute () {
      return this.corpus && this.isVerified && this.canAdmin(this.corpus)
    },
    createDisabledTitle () {
      let prefix = 'You do not have the required right'
      if (!this.isVerified) prefix = 'Your email address should be verified'
      else if (!this.canCreate) prefix = 'You must have a contributor access to this project in order'
      else if (this.allValidated) return 'All elements have already been validated'
      return `${prefix} to perform this action.`
    },
    executeDisabledTitle () {
      let prefix = 'You do not have the required right'
      if (!this.isVerified) prefix = 'Your email address should be verified'
      else if (!this.canExecute) prefix = 'You must have an admin access to this project in order'
      return `${prefix} to perform this action.`
    },
    elements () {
      return (this.selection[this.corpusId] ?? [])
        .map(id => this.storeElements[id])
        /*
         * Even if the element ID is in the selection store,
         * it might not be in the elements store, so we would get an `undefined`
         */
        .filter(element => element)
    },
    selectedElementIds () {
      return this.elements.map(item => item.id)
    },
    hasContribPrivilege () {
      return this.isVerified && this.corpus && this.canWrite(this.corpus)
    }
  },
  methods: {
    ...mapActions(useClassificationStore, ['listCorpusMLClasses']),
    createProcess () {
      if (!this.canCreate) return
      const payload = { corpus: this.corpusId, selection: true }
      createProcessRedirect(this.$store, this.$router, payload)
    },
    async createClassification () {
      this.isSavingNewClassification = true
      try {
        await this.$store.dispatch('selection/createClassifications', {
          corpusId: this.corpusId,
          mlClassId: this.selectedNewClassification
        })
      } finally {
        this.$store.dispatch('selection/get', {})
        this.selectedNewClassification = null
        this.isSavingNewClassification = false
        this.addClassificationModal = false
      }
    },
    async validateClassification () {
      try {
        await this.$store.dispatch('selection/validateClassifications', {
          corpusId: this.corpusId
        })
      } finally {
        this.validateClassificationModal = false
        this.allValidated = true
      }
    },
    async performDelete () {
      if (!this.canExecute) return
      this.deleteLoading = true
      try {
        await this.$store.dispatch('selection/deleteElements', { corpusId: this.corpusId })
        this.deleteModal = false
      } finally {
        this.deleteLoading = false
      }
    },
    async performMove () {
      if (!this.hasContribPrivilege || !this.pickedFolder) return
      this.moveLoading = true
      try {
        await this.$store.dispatch('selection/moveSelection', { corpusId: this.corpusId, destination: this.pickedFolder.id })
        this.moveSelectionModal = false
      } finally {
        this.moveLoading = false
      }
    },
    async performCreateParent () {
      if (!this.hasContribPrivilege || !this.pickedFolder) return
      this.createParentLoading = true
      try {
        await this.$store.dispatch('selection/createParent', { corpusId: this.corpusId, parentId: this.pickedFolder.id })
        this.createParentModal = false
      } finally {
        this.createParentLoading = false
      }
    }
  }
}
</script>

<style scoped>
.dropdown-menu {
  min-width: 0em;
}
a.dropdown-item[disabled] {
  color: lightgray !important;
  cursor: not-allowed;
}
</style>
