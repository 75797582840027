
import { PropType, defineComponent } from 'vue'
import { cloneDeep } from 'lodash'
import { DictUserConfigurationField } from '@/types/workerConfiguration'

type KVPair = {
  key: string | null,
  value: string | null
}

export default defineComponent({
  emits: {
    'update:modelValue': (value: Record<string, string> | string) => ['string', 'object'].includes(typeof value)
  },
  props: {
    field: {
      type: Object as PropType<DictUserConfigurationField>,
      required: true
    },
    modelValue: {
      type: [Object, String],
      required: true
    }
  },
  data: () => ({
    /*
     * KVPairs makes a copy of valuesDict which can then be updated
     * with new empty rows or new [key, value] pairs by the user (as
     * it is not a computed property but a copy); updatedDict recreates
     * a {k: v...} object from the [{key:k, value:v}...] items of KVPairs,
     * ignoring empty or incomplete rows, to update the configuration
     * in the parent component.
     */
    KVPairs: [] as KVPair[]
  }),
  mounted () {
    if (!this.valuesDict) return
    this.KVPairs = cloneDeep(this.valuesDict)
  },
  computed: {
    valuesDict () {
      const aList = []
      if (typeof this.modelValue === 'object') {
        for (const [key, value] of Object.entries(this.modelValue)) {
          aList.push({ key, value })
        }
      }
      aList.push({
        key: null,
        value: null
      })
      return aList
    },
    updatedDict () {
      const obj: Record<string, string> = {}
      for (const item of this.KVPairs) {
        if (item.key && item.value) {
          obj[item.key] = item.value
        }
      }
      return obj
    }
  },
  methods: {
    addRow () {
      this.KVPairs.push({
        key: null,
        value: null
      })
    }
  }
})
