<template>
  <div
    class="columns is-paddingless"
    :class="{ 'is-multiline': simpleNavigation }"
  >
    <div class="column is-flex is-narrow">
      <ul class="pagination-list">
        <li v-if="!response || !response.results || response.count < 1">No results</li>
        <li v-else>
          <template v-if="pageIndex">Items {{ pageIndex.start }} to {{ pageIndex.end }} out of </template>
          <template v-if="Number.isFinite(response.count)">{{ pluralize(response.count) }}</template>
        </li>
      </ul>
    </div>
    <div class="column">
      <nav class="pagination is-pulled-right" role="navigation" aria-label="pagination">
        <template v-if="simpleNavigation">
          <a
            class="pagination-previous"
            :disabled="!response.previous || null"
            :title="simpleNavigationText.previous"
            v-on:click="goBackward"
          >
            Previous
          </a>
          <a
            class="pagination-next"
            :disabled="!response.next || null"
            :title="simpleNavigationText.next"
            v-on:click="goForward"
          >
            Next
          </a>
        </template>
        <template v-else>
          <ul class="pagination-list is-flex-right">
            <li
              v-if="lastPage > 3"
              class="pagination-link page-input"
              :class="{ 'open': pageNavigation }"
              v-on:click="pageNavigation = !pageNavigation"
            >
              <span title="Go to a specific page">
                Go to
                <template v-if="!pageNavigation">…</template>
                <template v-else>page</template>
              </span>
              <template v-if="pageNavigation">
                <input
                  type="number"
                  min="1"
                  :max="lastPage"
                  required
                  v-model.number="selectedPage"
                  class="input"
                  :class="checkValue(selectedPage) ? 'is-success' : 'is-danger'"
                  v-on:click="event => event.stopPropagation()"
                  v-on:keyup.enter="handleGoto"
                />
                <button
                  class="button is-small"
                  v-on:click="handleGoto"
                >
                  <i class="icon-arrow-right"></i>
                </button>
              </template>
            </li>
            <li v-if="response.number > 1">
              <a
                class="pagination-link"
                :aria-label="label(1)"
                title="Go to first page"
                v-on:click="goto(1)"
              >
                1
              </a>
            </li>
            <li v-if="response.number > 3">
              <span class="pagination-ellipsis">&hellip;</span>
            </li>
            <li v-if="response.number > 2">
              <a
                class="pagination-link"
                :aria-label="label(response.number - 1)"
                :title="label(response.number - 1)"
                v-on:click="goto(response.number - 1)"
              >
                {{ response.number - 1 }}
              </a>
            </li>
            <li v-if="lastPage > 1">
              <a
                class="pagination-link is-current"
                :aria-label="`Page ${response.number}`"
                aria-current="page"
                :title="label(response.number)"
              >
                {{ response.number }}
              </a>
            </li>
            <li v-if="response.number < lastPage - 1">
              <a
                class="pagination-link"
                :aria-label="label(response.number + 1)"
                :title="label(response.number + 1)"
                v-on:click="goto(response.number + 1)"
              >
                {{ response.number + 1 }}
              </a>
            </li>
            <li v-if="response.number < lastPage - 2">
              <span class="pagination-ellipsis">&hellip;</span>
            </li>
            <li v-if="response.number < lastPage">
              <a
                class="pagination-link"
                :aria-label="label(lastPage)"
                title="Go to last page"
                v-on:click="goto(lastPage)"
              >
                {{ lastPage }}
              </a>
            </li>
          </ul>
        </template>
      </nav>
    </div>
  </div>
</template>

<script>
import { getPaginationParams } from '@/helpers'
export default {
  emits: ['navigate'],
  props: {
    response: {
      type: Object,
      default: () => ({})
    },
    singular: {
      type: String,
      default: 'result'
    },
    plural: {
      type: String,
      default: 'results'
    },
    simpleNavigation: {
      // Provides previous and next buttons only
      type: Boolean,
      default: false
    },
    pageSize: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    selectedPage: -1,
    pageNavigation: false
  }),
  mounted () {
    if (this.response.number) this.selectedPage = this.response.number
  },
  computed: {
    previousPage () {
      return this.response.previous && getPaginationParams(this.response.previous)
    },
    nextPage () {
      return this.response.next && getPaginationParams(this.response.next)
    },
    number () {
      // Current page number
      return this.response.number
    },
    pageIndex () {
      // Pagination position when using a page parameter
      if (!this.number) return
      const start = (this.number - 1) * this.pageSize + 1
      return { start, end: start + this.response.results.length - 1 }
    },
    lastPage () {
      // Determine the last page
      if (!this.response.next && this.number) return this.number
      if (!this.response.results || !this.response.count) return
      const pageSize = this.response.results.length
      return Math.ceil(this.response.count / pageSize)
    },
    simpleNavigationText () {
      /*
       * Return title text for previous and next buttons depending on pages availability and pagination type
       * If there is a numbered previous page (page X) and no next page the return value will be
       * { previous: 'Go to page X', next: 'No next page' }
       */
      return {
        previous: this.response.previous ? (this.number && this.label(this.number - 1)) || 'Previous page' : 'No previous page',
        next: this.response.next ? (this.number && this.label(this.number + 1)) || 'Next page' : 'No next page'
      }
    }
  },
  methods: {
    goBackward () {
      if (!this.response.previous) return
      // Navigate to previous page
      this.$emit('navigate', this.previousPage)
    },
    goForward () {
      if (!this.response.next) return
      // Navigate to next page
      this.$emit('navigate', this.nextPage)
    },
    goto (page) {
      // Navigate to a specific page
      this.$emit('navigate', { page })
    },
    label (pageNumber) { return `Go to page ${pageNumber}` },
    pluralize (count) {
      // u00A0 is a non-breaking space
      return count + '\u00A0' + (count === 1 ? this.singular : this.plural)
    },
    checkValue (page) {
      const pageNumber = Number(page)
      if (!Number.isInteger(pageNumber)) return false
      return pageNumber && pageNumber > 0 && pageNumber <= this.lastPage && pageNumber !== this.number
    },
    handleGoto () {
      if (this.pageNavigation !== true) this.pageNavigation = true
      else if (this.checkValue(this.selectedPage)) {
        this.pageNavigation = false
        this.goto(this.selectedPage)
      }
    }
  },
  watch: {
    $route (route) {
      this.pageNavigation = false
      if (route.query.page) this.selectedPage = route.query.page
    }
  }
}
</script>

<style lang="scss" scoped>
.is-flex-right {
  justify-content: flex-end;
}
.columns {
  margin-bottom: 0;
  & > .column {
    padding: .5rem .75rem 0 .75rem
  }
}
.page-input {
  cursor: pointer;
  &.open {
    padding-right: 0;
  }
  & input {
    height: 100%;
    background: none;
    margin-left: 1ch;
    font-weight: bold;
    width: 8ch;
    border-radius: 0;
  }
  & button {
    border: none;
    background: none;
  }
}
</style>
