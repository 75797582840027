
import { isAxiosError } from 'axios'
import { mapState, mapActions } from 'pinia'
import { corporaMixin, truncateMixin } from '@/mixins'
import { UUID_REGEX } from '@/config'
import { UUID } from '@/types'
import { Dataset, DatasetSet } from '@/types/dataset'
import Modal from '@/components/Modal.vue'
import { defineComponent, PropType } from 'vue'
import { useDatasetStore } from '@/stores'

export default defineComponent({
  mixins: [
    corporaMixin,
    truncateMixin
  ],
  components: {
    Modal
  },
  emits: {
    'update:modelValue': (value: boolean) => typeof value === 'boolean'
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    /**
     * Id of the corpus the selection belongs to
     */
    corpusId: {
      type: String as PropType<UUID>,
      validator: value => typeof value === 'string' && UUID_REGEX.test(value),
      required: true
    }
  },
  data: () => ({
    loading: false,
    /**
     * ID of the dataset that will receive selected elements
     */
    selectedDataset: null as Dataset | null,
    /**
     * Name of the set elements will be added to
     */
    selectedSet: null as DatasetSet | null,
    fieldErrors: {} as { [key: string]: string[] | undefined }
  }),
  computed: {
    ...mapState(useDatasetStore, ['corpusDatasets', 'singleCorpusDatasets']),
    title (): string {
      return `Add ${this.corpus?.name} selection to a dataset`
    },
    canAdd (): boolean {
      return this.selectedDataset !== null && this.selectedSet !== null
    },
    availableDatasets (): Dataset[] | null {
      if (this.corpusDatasets[this.corpusId] === undefined) return null
      return this.singleCorpusDatasets(this.corpusId)
    }
  },
  methods: {
    ...mapActions(useDatasetStore, ['listCorpusDatasets', 'addDatasetElementsSelection']),
    updateModelValue (value: boolean) { this.$emit('update:modelValue', value) },
    async addToSelection () {
      if (!this.canAdd) return
      if (!this.selectedDataset || !this.selectedSet) return

      this.loading = true
      this.fieldErrors = {}
      try {
        await this.addDatasetElementsSelection(
          this.corpusId,
          this.selectedDataset.id,
          this.selectedSet
        )
        this.selectedDataset = null
        this.selectedSet = null
        this.$emit('update:modelValue', false)
      } catch (err) {
        if (isAxiosError(err) && err.response?.status === 400 && err.response.data) {
          this.fieldErrors = err.response.data
        }
      } finally {
        this.loading = false
      }
    }
  },
  watch: {
    modelValue (newValue: boolean) {
      if (newValue && this.availableDatasets === null) {
        this.listCorpusDatasets(this.corpusId)
      }
    }
  }
})
