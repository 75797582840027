<template>
  <Modal
    :model-value="modal"
    v-on:update:model-value="$emit('update:modal', $event)"
    :is-large="isLarge"
  >
    <template v-slot:header>
      <p class="modal-card-title">
        Add a manual transcription on {{ truncateShort(typeName(element.type)) }}
        <strong>{{ truncateShort(element.name) }}</strong>
      </p>
    </template>

    <form class="columns is-multiline is-vcentered" v-on:submit.prevent="createTranscription">
      <div v-if="element.zone" class="column" :class="{ 'is-full': !isPortrait }">
        <figure>
          <ElementImage
            class="image"
            :style="imageStyle"
            :element="element"
          />
        </figure>
      </div>
      <div class="column" :class="{ 'is-full': !isPortrait }">
        <div class="pb-4" v-if="manualTranscriptions.length">
          <strong class="mb-2">Manual</strong>
          <table class="table is-hoverable is-fullwidth">
            <tbody>
              <EditableTranscription
                v-for="(transcription, index) in manualTranscriptions"
                :index="index"
                :element="element"
                :transcription="transcription"
                :key="transcription.id"
              />
            </tbody>
          </table>
        </div>

        <div
          class="pb-4"
          v-for="[workerRunId, transcriptions] in workerRunTranscriptions"
          :key="workerRunId"
        >
          <WorkerRunSummary
            class="mb-2"
            :worker-run-details="workerRunSummaries[workerRunId]"
          />
          <table class="table is-hoverable is-fullwidth">
            <tbody>
              <EditableTranscription
                v-for="(transcription, index) in transcriptions"
                :index="index"
                :element="element"
                :transcription="transcription"
                :key="transcription.id"
              />
            </tbody>
          </table>
        </div>

        <strong class="is-size-4">Add a transcription</strong>
        <div class="control mb-2">
          <div class="select">
            <select
              v-model="orientation"
              :disabled="canCreate"
              required
              :title="canWrite(corpus) ? '' : 'A write right on the project is required to create a transcription'"
            >
              <option
                v-for="(textOrientation, key) in TEXT_ORIENTATIONS"
                :key="key"
                :value="key"
                :title="textOrientation.display"
              >
                {{ textOrientation.display }}
              </option>
            </select>
            <template v-if="fieldErrors.orientation">
              <p
                class="help is-danger"
                v-for="err in fieldErrors.orientation"
                :key="err"
              >
                {{ err }}
              </p>
            </template>
          </div>
        </div>
        <div class="field">
          <div class="control">
            <textarea
              ref="textInput"
              v-model="text"
              v-on:keydown.enter.exact.prevent="createTranscription"
              class="textarea"
              :class="{ 'is-loading': loading }"
              :style="orientationStyle(orientation)"
              :disabled="canCreate"
              placeholder="Text…"
              :title="canWrite(corpus) ? '' : 'A write right on the project is required to create a transcription'"
            ></textarea>
            <template v-if="fieldErrors.text">
              <p class="help is-danger" v-for="err in fieldErrors.text" :key="err">{{ err }}</p>
            </template>
          </div>
        </div>
      </div>
    </form>
    <template v-slot:footer="{ close }">
      <button class="button" v-on:click="close">Cancel</button>
      <button
        class="button is-success"
        :class="{ 'is-loading': loading }"
        :disabled="!isValid || canCreate"
        :title="createTitle"
        v-on:click="createTranscription"
      >
        Create
      </button>
    </template>
  </Modal>
</template>

<script>
import { groupBy, orderBy } from 'lodash'
import { mapMutations } from 'vuex'
import { mapState, mapActions } from 'pinia'
import { TEXT_ORIENTATIONS } from '@/config'
import { errorParser, getSize, boundingBox, mirrorX, rotateAround, orientationStyle } from '@/helpers'
import { truncateMixin, corporaMixin } from '@/mixins'
import ElementImage from '@/components/Image/ElementImage.vue'
import Modal from '@/components/Modal.vue'
import EditableTranscription from './EditableTranscription'
import WorkerRunSummary from '@/components/Process/Workers/WorkerRuns/WorkerRunSummary.vue'
import { useNotificationStore, useTranscriptionStore, useWorkerStore } from '@/stores'

export default {
  mixins: [
    truncateMixin,
    corporaMixin
  ],
  components: {
    ElementImage,
    Modal,
    EditableTranscription,
    WorkerRunSummary
  },
  emits: ['update:modal'],
  props: {
    modal: {
      type: Boolean,
      required: true
    },
    element: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    loading: false,
    text: '',
    // API fields validation errors
    fieldErrors: {},
    TEXT_ORIENTATIONS
  }),
  mounted () {
    this.$nextTick(() => {
      this.$refs.textInput.focus()
    })
  },
  computed: {
    ...mapState(useTranscriptionStore, { eltsTranscriptions: 'transcriptions' }),
    ...mapState(useWorkerStore, ['workerVersions']),
    canCreate () {
      if (this.text.trim().length <= 0) return this.loading || !this.canWrite(this.corpus)
      return !this.loading && this.isValid && !this.canWrite(this.corpus)
    },
    createTitle () {
      if (!this.canWrite(this.corpus)) return 'A write right on the project is required to create a transcription'
      const text = this.text.trim()
      if (!text && !this.orientation) return 'Please fill out the creation form'
      else if (!text) return 'A valid text is required to create the transcription'
      else if (!this.orientation || !(this.orientation in TEXT_ORIENTATIONS)) return 'A valid text orientation is required'
      return 'Create transcription'
    },
    orientation: {
      get () {
        return this.$store.state.annotation.textOrientation
      },
      set (newValue) {
        this.setTextOrientation(newValue)
      }
    },
    /**
     * Transcriptions sorted by descending confidence and ascending text.
     * This sorting is computed once and then re-used by other computed properties
     * to perform the grouping.
     */
    sortedTranscriptions () {
      return orderBy(
        this.eltsTranscriptions[this.element.id],
        ['confidence', 'text'],
        ['desc', 'asc']
      )
    },
    manualTranscriptions () {
      return this.sortedTranscriptions.filter(transcription => !transcription.worker_run)
    },
    workerRunTranscriptions () {
      const grouped = groupBy(
        this.sortedTranscriptions.filter(transcription => transcription.worker_run),
        'worker_run.id'
      )
      return orderBy(Object.entries(grouped), ([id]) => this.workerRunSummaries[id])
    },
    /**
     * Worker run summary serializers mapped to their IDs.
     * @returns {{ [id: string]: { id: string, summary: string } }}
     */
    workerRunSummaries () {
      return Object.fromEntries(
        this.sortedTranscriptions
          .filter(transcription => transcription?.worker_run)
          .map(transcription => [transcription.worker_run.id, transcription.worker_run])
      )
    },
    elementZone () {
      const zone = this.element.zone
      if (!zone || !zone.image || !zone.polygon) return null
      return zone
    },
    corpusId () {
      // Corpus ID for corporaMixin
      return this.element.corpus.id
    },
    isValid () {
      return this.text.trim().length > 0 && this.orientation in TEXT_ORIENTATIONS
    },
    isPortrait () {
      // Return true if the zone is in portrait mode, false otherwise
      if (!this.element.zone) return false
      const bbox = boundingBox(this.element.zone)
      const center = [bbox.x + bbox.width / 2, bbox.y + bbox.height / 2]
      let boundingPolygon = [
        [bbox.x, bbox.y],
        [bbox.x + bbox.width, bbox.y],
        [bbox.x, bbox.y + bbox.height],
        [bbox.x + bbox.width, bbox.y + bbox.height]
      ]
      if (this.element.mirrored) boundingPolygon = boundingPolygon.map(point => mirrorX(point, center))
      if (this.element.rotation_angle) boundingPolygon = boundingPolygon.map(point => rotateAround(point, center, this.element.rotation_angle))
      const [width, height] = getSize(boundingPolygon)
      return height > width
    },
    isLarge () {
      return this.elementZone !== null && boundingBox(this.elementZone).width > 500
    },
    imageStyle () {
      const style = { margin: 'auto' }
      if (this.isPortrait) {
        style['max-height'] = '75vh'
      } else {
        style['max-height'] = '50vh'
        style['max-width'] = '75vw'
      }
      return style
    }
  },
  methods: {
    ...mapActions(useNotificationStore, ['notify']),
    ...mapActions(useTranscriptionStore, ['create', 'list']),
    ...mapMutations('annotation', ['setTextOrientation']),
    orientationStyle,
    async load () {
      if (this.eltsTranscriptions[this.element.id] === undefined) {
      // Load element transcriptions if necessary
        await this.list(this.element.id)
      }
    },
    setErrors (error) {
      // Set field errors from API return value
      if (!error) this.fieldErrors = {}
      else if (!error.response || typeof error.response.data !== 'object') this.fieldErrors = { error: errorParser(error) }
      else this.fieldErrors = error.response.data
    },
    async createTranscription () {
      if (!this.isValid) return
      if (!this.canWrite(this.corpus)) {
        return this.notify({ type: 'error', text: 'A write right on the project is required to create a transcription' })
      }
      try {
        this.loading = true
        await this.create(
          this.element.id,
          {
            text: this.text,
            orientation: this.orientation
          }
        )
        this.notify({ type: 'success', text: 'Transcription created.' })
        // Close modal in case of success
        this.$emit('update:modal', false)
      } catch (e) {
        this.setErrors(e)
        this.notify({ type: 'error', text: `An error occurred during transcription creation: ${errorParser(e)}` })
      } finally {
        this.loading = false
      }
    }
  },
  watch: {
    element: {
      immediate: true,
      handler: 'load'
    }
  }
}
</script>
