<template>
  <div class="columns m-0 is-align-items-center">
    <div class="column p-0">
      <nav class="breadcrumb py-2">
        <ul>
          <li>
            <router-link :to="{ name: 'navigation', params: { corpusId } }" class="has-text-weight-semibold">
              {{ truncateShort(corpus.name) }}
            </router-link>
          </li>

          <template v-if="neighbor">
            <li
              v-for="(parent, index) in neighbor.path"
              :key="index"
            >
              <!-- Handle nulls when a path refers to an element that no longer exists -->
              <span class="is-italic pr-3" v-if="parent === null">
                Deleted element
              </span>
              <template v-else>
                <span class="pl-3 has-text-grey" :title="typeName(parent.type)">
                  {{ truncateShort(typeName(parent.type)) }}&nbsp;
                </span>
                <router-link
                  class="pl-0 pr-3 parent-name"
                  :to="elementLink(parent.id)"
                  :title="parent.name"
                >
                  {{ truncateLong(parent.name) }}
                </router-link>
              </template>
            </li>
          </template>

          <li v-if="loading">
            <span class="loader mx-3"></span>
          </li>

          <li>
            <span class="is-flex is-align-items-center px-3">
              <span class="has-text-grey" :title="typeName(element.type)">
                {{ truncateShort(typeName(element.type)) }}&nbsp;
              </span>
              <EditableName
                :instance="element"
                :enabled="isVerified && canWrite(corpus)"
                :save-function="renameElement"
                class="has-text-weight-bold"
              />
            </span>
          </li>
        </ul>
      </nav>
    </div>

    <div class="column is-narrow p-0" v-if="neighbor">
      <router-link
        :to="pathLink(neighbor.previous?.id)"
        :class="{ 'disabled': neighbor.previous === null }"
      >
        <i class="icon-arrow-left"></i>
      </router-link>

      {{ neighbor.ordering + 1 }}

      <router-link
        :to="pathLink(neighbor.next?.id)"
        :class="{ 'disabled': neighbor.next === null }"
      >
        <i class="icon-arrow-right"></i>
      </router-link>
    </div>
  </div>
</template>

<script>
import Mousetrap from 'mousetrap'
import { mapActions, mapGetters } from 'vuex'
import { corporaMixin, truncateMixin } from '@/mixins'
import EditableName from '@/components/EditableName.vue'

export default {
  mixins: [
    truncateMixin,
    corporaMixin
  ],
  props: {
    element: {
      type: Object,
      required: true,
      validator: element => typeof element === 'object' && element.id && element.corpus?.id && element.type
    },
    neighbor: {
      type: Object,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    /**
     * Whether to assign the Ctrl+Left and Ctrl+Right shortcuts to browsing the previous and next elements on this path.
     */
    keyboardShortcuts: {
      type: Boolean,
      default: false
    }
  },
  components: {
    EditableName
  },
  mounted () {
    if (this.keyboardShortcuts) {
      Mousetrap.bind('ctrl+left', () => {
        if (this.neighbor?.previous) this.$router.push(this.pathLink(this.neighbor.previous.id))
      })
      Mousetrap.bind('ctrl+right', () => {
        if (this.neighbor?.next) this.$router.push(this.pathLink(this.neighbor.next.id))
      })
    }
  },
  beforeUnmount () {
    if (this.keyboardShortcuts) {
      Mousetrap.unbind('ctrl+left')
      Mousetrap.unbind('ctrl+right')
    }
  },
  computed: {
    ...mapGetters('auth', ['isVerified']),
    corpusId () {
      return this.element.corpus.id
    }
  },
  methods: {
    ...mapActions('elements', { updateElement: 'patch' }),
    elementLink (eltId, routeName = 'element-details', parent = null) {
      /* Returns a link to a route (default to element's details) from an element ID */
      if (!eltId) return {}
      return {
        name: routeName,
        params: { id: eltId },
        query: { from: parent?.id }
      }
    },
    /**
     * Returns a link to same route but for another element ID.
     * When available, this path's direct parent ID will be used as the ?from= query parameter.
     */
    pathLink (eltId) {
      return this.elementLink(eltId, this.$route.name, this.neighbor?.path[this.neighbor?.path?.length - 1])
    },
    /**
     * Used by the EditableName component
     */
    async renameElement (name) {
      await this.updateElement({
        id: this.element.id,
        name
      })
    }
  }
}
</script>

<style>
a.disabled {
  pointer-events: none;
  opacity: 0.4;
}
@media screen and (max-width: 1200px) {
  a.parent-name {
    display: inline;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 30ch;
  }
}
</style>
