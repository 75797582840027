
import { CLASSIFICATION_STATES } from '@/config'
import ConfidenceTag from '@/components/ConfidenceTag.vue'
import { useClassificationStore } from '@/stores'
import { mapStores } from 'pinia'
import { defineComponent, PropType } from 'vue'
import { Classification, UUID } from '@/types'

export default defineComponent({
  components: {
    ConfidenceTag
  },
  props: {
    classification: {
      type: Object as PropType<Classification>,
      required: true
    },
    elementId: {
      type: String as PropType<UUID>,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    loading: false
  }),
  computed: {
    ...mapStores(useClassificationStore),
    validated () {
      return this.classification.state === CLASSIFICATION_STATES.validated
    },
    rejected () {
      return this.classification.state === CLASSIFICATION_STATES.rejected
    },
    isManual () {
      return !this.classification.worker_run
    }
  },
  methods: {
    async validate () {
      if (this.validated || this.disabled || this.loading) return
      this.loading = true
      try {
        await this.classificationStore.validate(this.classification.id, this.elementId)
      } finally {
        this.loading = false
      }
    },
    async reject () {
      if (this.rejected || this.disabled || this.loading) return
      this.loading = true
      try {
        await this.classificationStore.reject(this.classification.id, this.elementId)
      } finally {
        this.loading = false
      }
    }
  }
})
