import { mergeProps as _mergeProps, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "field" }
const _hoisted_2 = ["placeholder", "value"]
const _hoisted_3 = {
  key: 0,
  class: "menu"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FolderList = _resolveComponent("FolderList")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "control",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.opened = true))
    }, [
      _createElementVNode("input", _mergeProps({
        class: "input",
        readonly: "",
        placeholder: _ctx.placeholder,
        value: _ctx.modelValue?.name
      }, _ctx.$attrs), null, 16, _hoisted_2)
    ]),
    _createVNode(_component_Modal, {
      modelValue: _ctx.opened,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.opened) = $event)),
      title: _ctx.placeholder
    }, {
      default: _withCtx(() => [
        (_ctx.opened)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_FolderList, {
                class: "tree",
                "corpus-id": _ctx.corpusId,
                exclude: _ctx.exclude,
                "current-folder-id": _ctx.modelValue?.id,
                onSelectedFolder: _cache[1] || (_cache[1] = folder => _ctx.$emit('update:modelValue', folder))
              }, null, 8, ["corpus-id", "exclude", "current-folder-id"])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue", "title"])
  ]))
}