
import { PropType, defineComponent } from 'vue'
import { mapState, mapActions } from 'pinia'
import { errorParser } from '@/helpers'
import Paginator from '@/components/Paginator.vue'
import ElementList from '@/components/Navigation/ElementList.vue'
import WorkerRunSummary from '@/components/Process/Workers/WorkerRuns/WorkerRunSummary.vue'
import { useEntityStore } from '@/stores'
import { UUID } from '@/types'
import { EntityType } from '@/types/entity'
import { toNumber } from 'lodash'

export default defineComponent({
  components: {
    Paginator,
    ElementList,
    WorkerRunSummary
  },
  props: {
    id: {
      type: String as PropType<UUID>,
      required: true
    }
  },
  mounted () {
    this.load(this.id, toNumber(this.$route.query.page?.toString() ?? 1))
  },
  beforeRouteUpdate (to) {
    this.load(to.params.id.toString(), toNumber(this.$route.query.page?.toString() ?? 1))
  },
  data: () => ({
    error: null as string | null
  }),
  computed: {
    ...mapState(useEntityStore, ['entity', 'elements']),
    hasMetas (): boolean {
      if (!this.entity) return false
      if (this.entity.metas === null) {
        return false
      } else {
        return Object.keys(this.entity.metas).length !== 0
      }
    }
  },
  methods: {
    ...mapActions(useEntityStore, ['get', 'listElements']),
    async load (id: UUID, page: number) {
      if (this.entity?.id === id && this.elements?.number === page) return
      this.error = null
      try {
        await this.get(id)
        await this.listElements(id, { page })
      } catch (err) {
        this.error = errorParser(err)
      }
    },
    entityStyle (type: EntityType) {
      return { 'background-color': `#${type.color}`, color: '#ffffff' }
    }
  }
})
