<template>
  <main class="container is-fluid columns is-centered">
    <div class="column is-half-desktop is-four-fifths-tablet">
      <h1 class="title">Reset your password</h1>
      <div class="notification is-success" v-if="success">
        Your password has been successfully reset.
        <router-link :to="{ name: 'login' }">Login</router-link>
      </div>
      <form v-on:submit.prevent="submit" v-else>
        <div class="notification is-danger" v-if="fieldErrors.error">
          <button class="delete" v-on:click="fieldErrors.error = null"></button>
          {{ fieldErrors.error }}
        </div>
        <div class="notification is-warning" v-if="fieldErrors.token">
          <button class="delete" v-on:click="fieldErrors.token = null"></button>
          {{ fieldErrors.token }}
        </div>
        <div class="field">
          <label class="label" for="password">New password</label>
          <div class="control">
            <input
              type="password"
              required
              class="input"
              :class="{ 'is-danger': fieldErrors.password }"
              :disabled="loading || null"
              v-model="password"
            />
            <template v-if="fieldErrors.password">
              <p class="help is-danger" v-for="err in fieldErrors.password" :key="err">{{ err }}</p>
            </template>
          </div>
        </div>
        <div class="field">
          <label class="label" for="confirmPassword">Confirm password</label>
          <div class="control">
            <input
              type="password"
              required
              class="input"
              :class="{ 'is-danger': fieldErrors.confirmPassword }"
              :disabled="loading || null"
              v-model="confirmPassword"
            />
            <template v-if="fieldErrors.confirmPassword">
              <p class="help is-danger" v-for="err in fieldErrors.confirmPassword" :key="err">{{ err }}</p>
            </template>
          </div>
        </div>
        <div class="field is-grouped is-grouped-centered">
          <div class="control">
            <button
              class="button is-primary"
              type="submit"
              :disabled="!password || !confirmPassword || null"
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  </main>
</template>

<script>
import { mapActions } from 'vuex'
import { errorParser } from '@/helpers'
export default {
  props: {
    uidb64: {
      type: String,
      required: true
    },
    token: {
      type: String,
      required: true
    }
  },
  data: () => ({
    password: '',
    confirmPassword: '',
    loading: false,
    success: false,
    fieldErrors: {}
  }),
  methods: {
    ...mapActions('auth', ['resetPassword']),
    setErrors (error) {
      // Set field errors from API return value
      if (!error) this.fieldErrors = {}
      else if (!error.response || typeof error.response.data !== 'object') this.fieldErrors = { error: errorParser(error) }
      else this.fieldErrors = error.response.data
    },
    async submit () {
      if (!this.checkPasswords()) return

      this.success = false
      this.loading = true
      this.setErrors(null)
      try {
        await this.resetPassword({
          uidb64: this.uidb64,
          token: this.token,
          password: this.confirmPassword
        })
        this.success = true
      } catch (err) {
        this.setErrors(err)
      } finally {
        this.loading = false
        this.password = ''
        this.confirmPassword = ''
      }
    },
    checkPasswords () {
      if (this.passwordMatch) return true
      this.fieldErrors = { confirmPassword: ['Passwords do not match'] }
      return false
    }
  },
  computed: {
    passwordMatch () {
      return this.password && this.confirmPassword && this.password === this.confirmPassword
    }
  }
}
</script>
