
import { orderBy } from 'lodash'
import { PropType, defineComponent } from 'vue'
import { truncateMixin } from '@/mixins'

export default defineComponent({
  emits: {
    'update:modelValue': (value: string) => typeof value === 'string'
  },
  mixins: [
    truncateMixin
  ],
  props: {
    name: {
      type: String,
      required: true
    },
    values: {
      type: Object as PropType<Record<string, number>>,
      required: true
    },
    modelValue: {
      type: String,
      required: true
    }
  },
  data: () => ({
    nameSort: true,
    loading: false
  }),
  computed: {
    sortingOption () {
      if (this.nameSort) return 'Sort by occurrences'
      return 'Sort by name'
    },
    sortedItems (): [string, number][] {
      if (this.modelValue) return [[this.modelValue, this.values[this.modelValue]]]
      else {
        const arrayed = Object.entries(this.values).filter(item => item[1] > 0)
        if (this.nameSort) return orderBy(arrayed, 0, 'asc')
        else return orderBy(arrayed, [1, 0], ['desc', 'asc'])
      }
    }
  },
  methods: {
    updateChecked (facet: string, value: boolean) {
      if (value) this.$emit('update:modelValue', facet)
      else this.$emit('update:modelValue', '')
    }
  }
})
