<template>
  <main class="container is-fluid">
    <h1 class="title">Elements on the same image</h1>

    <div class="columns">
      <ul class="column">
        <li class="field is-horizontal">
          <div class="field-label">
            <label class="label">ID</label>
          </div>
          <div class="field-body">
            <div class="field">
              <ItemId class="control" :item-id="imageId" />
            </div>
          </div>
        </li>
        <template v-if="image">
          <li class="field is-horizontal">
            <div class="field-label">
              <label class="label">Status</label>
            </div>
            <div class="field-body">
              <div class="field is-capitalized">
                {{ image.status }}
              </div>
            </div>
          </li>
          <li class="field is-horizontal">
            <div class="field-label">
              <label class="label">Width</label>
            </div>
            <div class="field-body">
              <div class="field">
                {{ image.width }}
              </div>
            </div>
          </li>
          <li class="field is-horizontal">
            <div class="field-label">
              <label class="label">Height</label>
            </div>
            <div class="field-body">
              <div class="field">
                {{ image.height }}
              </div>
            </div>
          </li>
        </template>
        <li class="field is-horizontal" v-if="fullImage">
          <div class="field-label">
            <label class="label">URL</label>
          </div>
          <div class="field-body">
            <div class="field">
              <a
                target="_blank"
                class="control"
                :href="fullImage"
              >
                Full IIIF image
              </a>
            </div>
          </div>
        </li>
      </ul>
      <div class="column is-narrow image-column">
        <img
          v-if="imageThumbnail"
          :src="imageThumbnail"
        />
      </div>
    </div>

    <div v-if="loading">
      Loading…
    </div>
    <Paginator
      v-else
      :response="imageStore.elements"
      singular="element"
      plural="elements"
    >
      <template v-slot:default="{ results }">
        <ElementList
          :elements="results"
          as-table
        />
      </template>

      <template v-slot:no-results>
        <span class="has-text-danger">
          No elements found on this image.
        </span>
      </template>
    </Paginator>
  </main>
</template>

<script>
import { mapStores } from 'pinia'

import { retrieveImage } from '@/api'
import { iiifUri } from '@/helpers'
import { corporaMixin } from '@/mixins'
import { useImageStore } from '@/stores'

import ItemId from '@/components/ItemId.vue'
import Paginator from '@/components/Paginator.vue'
import ElementList from '@/components/Navigation/ElementList.vue'

// Displayed image details retrieved from the backend
export default {
  mixins: [
    corporaMixin
  ],
  components: {
    ItemId,
    Paginator,
    ElementList
  },
  props: {
    imageId: {
      type: String,
      required: true
    }
  },
  data: () => ({
    image: null,
    loading: false,
    opened: false
  }),
  async mounted () {
    try {
      this.image = (await retrieveImage(this.imageId)).data
    } catch (err) {
      this.$store.commit('notifications/notify', { type: 'error', text: 'An error occurred retrieving image details.' })
    }
  },
  computed: {
    ...mapStores(useImageStore),
    corpusId () {
      if (!this.imageStore.elements?.results?.length) return null
      return this.imageStore.elements.results[0].corpus.id
    },
    imageThumbnail () {
      if (!this.image) return
      return iiifUri({ image: this.image }, { height: 200 })
    },
    fullImage () {
      if (!this.image) return
      return iiifUri({ image: this.image })
    }
  },
  methods: {
    async load (page) {
      this.loading = true
      try {
        await this.imageStore.listElements({
          id: this.imageId,
          page: page ?? 1
        })
      } finally {
        this.loading = false
      }
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => vm.load(to.query.page))
  },
  beforeRouteUpdate (to) {
    this.load(to.query.page)
  }
}
</script>

<style scoped>
.image-column {
  text-align: center;
  min-width: 25%;
  max-width: 50%;
}
</style>
