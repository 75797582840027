<template>
  <transition name="notification-fade" appear>
    <div class="notification" :class="NOTIFICATION_TYPES[type]">
      <button class="delete" v-on:click="close"></button>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div v-if="markdown" v-html="md.render(truncatedText)"></div>
      <div v-else>{{ truncatedText }}</div>
      <router-link v-if="link && link.route" :to="link.route">
        {{ truncateLong(link.text || 'link') }}
      </router-link>
    </div>
  </transition>
</template>

<script>
import MarkdownIt from 'markdown-it'
import { NOTIFICATION_TYPES } from '@/config'
import { truncateMixin } from '@/mixins'
import { useNotificationStore } from '@/stores'

export default {
  mixins: [
    truncateMixin
  ],
  props: {
    id: {
      type: Number,
      required: true
    },
    type: {
      type: String,
      required: true,
      validator: value => NOTIFICATION_TYPES[value]
    },
    text: {
      type: String,
      required: true
    },
    timeout: {
      type: Number,
      default: 5000,
      validator: value => value >= 0
    },
    link: {
      // Valid route to provide a link in the notification
      type: Object,
      default: () => ({})
    },
    markdown: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    NOTIFICATION_TYPES,
    timeoutId: null,
    md: MarkdownIt({ breaks: true })
  }),
  mounted () {
    if (this.timeout > 0) this.timeoutId = setTimeout(this.close, this.timeout)
  },
  computed: {
    truncatedText () {
      return this.truncateNotification(this.text)
    }
  },
  methods: {
    close () {
      if (this.timeoutId !== null) clearTimeout(this.timeoutId)
      const store = useNotificationStore()
      store.remove(this.id)
    }
  }
}
</script>

<style scoped>
.notification {
  margin: 0;
  padding: 1rem;
  padding-right: 2.5rem;
  word-break: break-all;
}
.notification:not(:last-child) {
  margin-bottom: .5rem;
}
</style>
