import { vModelText as _vModelText, withModifiers as _withModifiers, withKeys as _withKeys, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, vModelSelect as _vModelSelect } from "vue"

const _hoisted_1 = { class: "field" }
const _hoisted_2 = { class: "control" }
const _hoisted_3 = ["rows", "disabled"]
const _hoisted_4 = { class: "field is-horizontal" }
const _hoisted_5 = { class: "field-body" }
const _hoisted_6 = { class: "field" }
const _hoisted_7 = { class: "control" }
const _hoisted_8 = { class: "select is-small" }
const _hoisted_9 = ["disabled"]
const _hoisted_10 = ["value", "title"]
const _hoisted_11 = { class: "field" }
const _hoisted_12 = { class: "control has-text-right" }
const _hoisted_13 = ["disabled"]
const _hoisted_14 = ["disabled", "title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("form", {
    class: "form",
    onSubmit: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.updateTranscription && _ctx.updateTranscription(...args)), ["prevent"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _withDirectives(_createElementVNode("textarea", {
          ref: "textInput",
          class: _normalizeClass(["input", { 'is-loading': _ctx.loading }]),
          style: _normalizeStyle(_ctx.orientationStyle(_ctx.newOrientation)),
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newText) = $event)),
          onKeydown: _cache[1] || (_cache[1] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => (_ctx.updateTranscription && _ctx.updateTranscription(...args)), ["exact","prevent"]), ["enter"])),
          placeholder: "Transcription text",
          rows: _ctx.rows,
          disabled: _ctx.loading || undefined,
          required: ""
        }, null, 46, _hoisted_3), [
          [_vModelText, _ctx.newText]
        ]),
        ('text' in _ctx.fieldErrors)
          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.fieldErrors.text, (err) => {
              return (_openBlock(), _createElementBlock("p", {
                class: "help is-danger",
                key: err
              }, _toDisplayString(err), 1))
            }), 128))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _withDirectives(_createElementVNode("select", {
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.newOrientation) = $event)),
                disabled: _ctx.loading || undefined,
                required: ""
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.TEXT_ORIENTATIONS, (textOrientation, key) => {
                  return (_openBlock(), _createElementBlock("option", {
                    key: key,
                    value: key,
                    title: textOrientation.display
                  }, _toDisplayString(textOrientation.display), 9, _hoisted_10))
                }), 128))
              ], 8, _hoisted_9), [
                [_vModelSelect, _ctx.newOrientation]
              ]),
              ('orientation' in _ctx.fieldErrors)
                ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.fieldErrors.orientation, (err) => {
                    return (_openBlock(), _createElementBlock("p", {
                      class: "help is-danger",
                      key: err
                    }, _toDisplayString(err), 1))
                  }), 128))
                : _createCommentVNode("", true)
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("button", {
              type: "button",
              class: "button",
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('close'))),
              disabled: _ctx.loading || undefined
            }, " Cancel ", 8, _hoisted_13),
            _createElementVNode("button", {
              type: "submit",
              class: "button is-primary",
              disabled: !_ctx.canUpdate || _ctx.loading || undefined,
              title: _ctx.canUpdate ? 'Update transcription' : _ctx.updateDisabledTitle
            }, " Save ", 8, _hoisted_14)
          ])
        ])
      ])
    ])
  ], 32))
}