
import { PropType, defineComponent } from 'vue'
import { mapState, mapActions } from 'pinia'
import { orderBy, groupBy } from 'lodash'

import { useTranscriptionStore } from '@/stores'

import WorkerRunSummaryComponent from '@/components/Process/Workers/WorkerRuns/WorkerRunSummary.vue'
import TranscriptionComponent from './Transcription.vue'
import { Element, UUID, WorkerRunSummary } from '@/types'
import { Transcription } from '@/types/transcription'

export default defineComponent({
  props: {
    element: {
      type: Object as PropType<Element>,
      required: true,
      validator: (value: Element) => {
        return Object.keys(value).includes('id')
      }
    }
  },
  components: {
    TranscriptionComponent,
    WorkerRunSummaryComponent
  },
  computed: {
    ...mapState(useTranscriptionStore, ['transcriptions']),
    /**
     * Transcriptions sorted by descending confidence and ascending text.
     * This sorting is computed once and then re-used by other computed properties
     * to perform the grouping.
     */
    sortedTranscriptions (): Transcription[] {
      return orderBy(
        this.transcriptions[this.element.id],
        ['confidence', 'text'],
        ['desc', 'asc']
      )
    },
    manualTranscriptions (): Transcription[] {
      return this.sortedTranscriptions.filter(transcription => !transcription.worker_run)
    },
    /**
     * Transcriptions grouped by worker run ID, and sorted by their worker run summary.
     */
    workerRunTranscriptions (): [UUID, Transcription[]][] {
      const grouped = groupBy(
        this.sortedTranscriptions.filter(transcription => transcription.worker_run),
        'worker_run.id'
      )
      return orderBy(Object.entries(grouped), ([id]) => this.workerRunSummaries[id])
    },
    /**
     * Worker run summary serializers mapped to their IDs.
     */
    workerRunSummaries (): {[workerRunId: UUID]: WorkerRunSummary} {
      return Object.fromEntries(
        this.sortedTranscriptions
          .filter(transcription => transcription?.worker_run)
          .map(transcription => [transcription.worker_run?.id, transcription.worker_run])
      )
    }
  },
  methods: {
    ...mapActions(useTranscriptionStore, ['list'])
  },
  watch: {
    element: {
      immediate: true,
      async handler (newValue) {
        if (!newValue) return
        if (!this.transcriptions[newValue.id]) await this.list(newValue.id)
      }
    }
  }
})
