
import { IntegerUserConfigurationField } from '@/types/workerConfiguration'
import { PropType, defineComponent } from 'vue'

export default defineComponent({
  emits: {
    'update:modelValue': (value: number | string) => ['string', 'number'].includes(typeof value)
  },
  props: {
    field: {
      type: Object as PropType<IntegerUserConfigurationField>,
      required: true
    },
    modelValue: {
      type: [Number, String],
      required: true
    }
  }
})
