import axios from 'axios'
import { unique } from '.'
import { MetaData, MetaDataCreate, TypedMetaData, UUID } from '@/types'

// Create a metadata.
export const createMetadata = unique(
  async (elementId: UUID, data: MetaDataCreate): Promise<MetaData> =>
    (await axios.post(`/element/${elementId}/metadata/`, data)).data
)

// Update a metadata.
export const updateMetadata = unique(
  async ({ id, ...data }: TypedMetaData): Promise<MetaData> =>
    (await axios.patch(`/metadata/${id}/`, data)).data
)

// Delete a metadata.
export const deleteMetadata = unique(async (id: UUID) => await axios.delete(`/metadata/${id}/`))

// List element metadata.
export const listMetadata = unique(
  async (id: UUID): Promise<MetaData[]> => (await axios.get(`/element/${id}/metadata/`)).data
)
