<template>
  <div>
    <div v-if="!element" class="loading-content loader"></div>
    <template v-else-if="canWrite(corpus)">
      <DropdownContent id="annotation-tools" title="Tools">
        <Tools :corpus-id="element.corpus.id" />
      </DropdownContent>
      <DropdownContent id="batch-annotation" title="Batch annotation">
        <fieldset :disabled="batchFormDisabled" :title="batchFormDisabled ? 'The batch annotation settings are only available for element creation, type edition or deletion tools.' : ''">
          <span v-if="tool === 'deletion'">
            <input
              id="switchBatchDeletion"
              type="checkbox"
              class="switch is-rounded is-danger"
              v-model="batchDeletion"
            />
            <label for="switchBatchDeletion">Enable batch deletion (no confirmation)</label>
          </span>
          <span v-else-if="tool === 'type-edit'">
            <input
              id="switchBatchTypeEdition"
              type="checkbox"
              class="switch is-rounded is-info"
              v-model="batchTypeEdition"
            />
            <label for="switchBatchTypeEdition">Batch type edition</label>
          </span>
          <span v-else>
            <input
              id="switchBatchCreation"
              type="checkbox"
              class="switch is-rounded is-info"
              v-model="batchCreation"
            />
            <label for="switchBatchCreation">Enable batch creation (no prompt)</label>
          </span>
        </fieldset>

        <fieldset :disabled="elementTypeSelectDisabled" :title="elementTypeSelectDisabled ? 'Element type selection is only available for element creation and type edition tools.' : ''">
          <div class="field mt-2">
            <label class="label">Element type</label>
            <div class="control">
              <div class="select is-fullwidth" :class="{ 'is-danger': showDefaultTypeError }">
                <select v-model="defaultType">
                  <option value="" disabled selected>Type…</option>
                  <option v-for="t in sortedTypes" :key="t.slug" :value="t.slug">
                    {{ truncateSelect(t.display_name) }}
                  </option>
                </select>
              </div>
            </div>
            <p class="help is-danger" v-if="showDefaultTypeError">
              An element type is required to use batch creation or type edition.
            </p>
          </div>
        </fieldset>
      </DropdownContent>
      <hr />
    </template>
  </div>
</template>

<script>
import { orderBy } from 'lodash'
import { mapState, mapMutations } from 'vuex'
import { truncateMixin, corporaMixin } from '@/mixins'

import DropdownContent from '@/components/DropdownContent.vue'
import Tools from '@/components/Image/Tools.vue'

export default {
  mixins: [
    truncateMixin,
    corporaMixin
  ],
  components: {
    DropdownContent,
    Tools
  },
  props: {
    elementId: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState('elements', ['elements']),
    ...mapState('annotation', ['tool']),
    element () {
      return this.elements[this.elementId]
    },
    corpusId () {
      return this.element?.corpus?.id
    },
    sortedTypes () {
      if (!this.corpus?.types) return []
      return orderBy(this.corpus.types, [t => t.display_name.toLowerCase(), t => t.slug])
    },
    /**
     * Disable the batch annotation form if the selected tool does not use it.
     * Only the element creation tools (rectangle and polygon) make use of the batch annotation settings.
     */
    batchFormDisabled () {
      return !['rectangle', 'polygon', 'deletion', 'type-edit'].includes(this.tool) || null
    },
    /**
     * Disable the element type selection dropdown if not using an element creation tool.
     */
    elementTypeSelectDisabled () {
      return !['rectangle', 'polygon', 'type-edit'].includes(this.tool) || null
    },
    batchCreation: {
      get () {
        return this.$store.state.annotation.batchCreation
      },
      set (newValue) {
        this.setBatchCreation(newValue)
        /*
         * Everything, except this panel, is ready to support the default classification setting.
         * The annotation module is going to expect a default class ID to be defined, so for now,
         * we just set it to never create a classification.
         * TODO: Fix the MLClassSelect to allow setting default classifications in this panel.
         */
        if (this.batchCreation) this.setDefaultClass({ corpusId: this.corpusId, classId: null })
      }
    },
    batchDeletion: {
      get () {
        return this.$store.state.annotation.batchDeletion
      },
      set (newValue) {
        this.setBatchDeletion(newValue)
      }
    },
    batchTypeEdition: {
      get () {
        return this.$store.state.annotation.batchTypeEdition
      },
      set (newValue) {
        this.setBatchTypeEdition(newValue)
      }
    },
    defaultType: {
      get () {
        // Default to the empty string to make the disabled placeholder option selected
        return this.$store.state.annotation.defaultType[this.corpusId] ?? ''
      },
      set (newValue) {
        this.setDefaultType({ corpusId: this.corpusId, type: newValue })
      }
    },
    showDefaultTypeError () {
      return (this.batchCreation || this.batchTypeEdition) && !this.defaultType
    }
  },
  methods: {
    ...mapMutations('annotation', [
      'setBatchCreation',
      'setBatchDeletion',
      'setBatchTypeEdition',
      'setDefaultType',
      'setDefaultClass',
      'toggle'
    ])
  },
  watch: {
    // If we know the user's rights on the corpus, and we find that the user is not a contributor, turn annotation off
    corpus: {
      immediate: true,
      handler (newCorpus) {
        if (Array.isArray(newCorpus?.rights) && !newCorpus.rights.includes('write')) this.toggle(false)
      }
    }
  }
}
</script>

<style scoped>
.loading-content {
  font-size: 2.5rem;
  margin: 2.5rem auto 0 auto;
}
.button.has-tooltip-multiline {
  width: 1rem;
  height: 1.5rem;
  margin-right: 1ch;
}
</style>
