<template>
  <span
    v-bind="$attrs"
    class="is-clickable"
    :class="{ 'is-singleline': !multiline }"
    v-on:click="workerRunModal = true"
    :title="`${workerRunDetails.summary}\nClick to view worker run details`"
  >
    <i class="icon-cog-alt"></i>
    <template v-if="!iconOnly"> {{ workerRunDetails.summary }}</template>
  </span>
  <WorkerRunDetails v-model="workerRunModal" :worker-run-id="workerRunDetails.id" :title="workerRunDetails.summary" />
</template>

<script>
import WorkerRunDetails from './WorkerRunDetails'

export default {
  components: {
    WorkerRunDetails
  },
  data: () => ({
    workerRunModal: false
  }),
  props: {
    workerRunDetails: {
      type: Object,
      required: true,
      validator: value => value?.id && value?.summary
    },
    multiline: {
      type: Boolean,
      default: false
    },
    iconOnly: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
.is-singleline {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
