import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "notification is-warning"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Folder = _resolveComponent("Folder")!

  return (!_ctx.folderId && !_ctx.loading && !_ctx.subfolders.length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, " No folders found. "))
    : (_openBlock(), _createElementBlock("ul", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.subfolders, (folder) => {
          return (_openBlock(), _createBlock(_component_Folder, {
            key: folder.id,
            folder: folder,
            exclude: _ctx.exclude,
            "current-folder-id": _ctx.currentFolderId,
            onSelectedFolder: _cache[0] || (_cache[0] = (f) => _ctx.$emit('selectedFolder', f))
          }, null, 8, ["folder", "exclude", "current-folder-id"]))
        }), 128)),
        (_ctx.hasNext)
          ? (_openBlock(), _createElementBlock("li", _hoisted_3, [
              _createElementVNode("button", {
                class: _normalizeClass(["button is-small", { 'is-loading': _ctx.loading }]),
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.fetchFolders && _ctx.fetchFolders(...args)))
              }, " Load more… ", 2)
            ]))
          : _createCommentVNode("", true)
      ]))
}