import axios from 'axios'
import { PageNumberPagination, UUID } from '@/types'
import { CorpusExport, ExportSource } from '@/types/export'
import { PageNumberPaginationParameters, unique } from '.'

// List a corpus' exports
export const listExports = unique(async (corpusId: UUID, params: PageNumberPaginationParameters = {}): Promise<PageNumberPagination<CorpusExport>> => (await axios.get(`/corpus/${corpusId}/export/`, { params })).data)

// Start an export on a corpus
export const startExport = unique(async (corpusId: UUID, source: string = "default"): Promise<CorpusExport> => (await axios.post(`/corpus/${corpusId}/export/`, { source })).data)

// Delete a corpus export
export const deleteExport = unique(
    async (id: UUID) => await axios.delete(`/export/${id}/`)
  )

/**
 * List the available sources to run exports on.
 * Available on Arkindex EE only.
 */
export const listExportSources = unique(async (): Promise<ExportSource[]> => (await axios.get('/export-sources/')).data)
