<template>
  <div class="has-shadow">
    <div class="elt-header is-flex">
      <div class="columns mx-0 is-align-items-center" v-if="element">
        <div class="column p-0 is-narrow is-hidden-mobile">
          <div
            v-if="morePaths > 0"
            :title="morePaths + ' more path' + (morePaths > 1 ? 's' : '') + ' for this element'"
          >
            <a
              v-on:click="toggleAllPaths(null)"
              class="navbar-link"
            >
              <div class="tag is-link">
                +{{ morePaths }}
              </div>
            </a>
          </div>
        </div>

        <ElementPath
          class="column"
          :element="element"
          :neighbor="sortedNeighbors[0]"
          :loading="loading"
          keyboard-shortcuts
        />

        <HeaderActions :corpus-id="corpusId" :element-id="element.id" />
      </div>
      <span v-else class="loader py-2"></span>
    </div>
    <transition name="paths">
      <div class="px-3" v-if="element && displayAllPaths">
        <ElementPath
          v-for="(neighbor, index) in sortedNeighbors.slice(1)"
          :key="index"
          :element="element"
          :neighbor="neighbor"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState as mapVuexState } from 'vuex'
import { mapState, mapActions } from 'pinia'
import { corporaMixin } from '@/mixins'
import HeaderActions from '@/components/HeaderActions.vue'
import ElementPath from './ElementPath'
import { useDisplayStore } from '@/stores'

export default {
  mixins: [
    corporaMixin
  ],
  props: {
    element: {
      type: Object,
      required: true
    }
  },
  components: {
    HeaderActions,
    ElementPath
  },
  data: () => ({
    loading: false
  }),
  computed: {
    ...mapVuexState('elements', {
      elementNeighbors (state) {
        return state.neighbors[this.element?.id] ?? []
      }
    }),
    ...mapState(useDisplayStore, ['displayAllPaths']),
    corpusId () {
      return this.element?.corpus?.id ?? null
    },
    sortedNeighbors () {
      if (!this.element?.id || !this.elementNeighbors?.length) return []

      const eltNeighbors = [...this.elementNeighbors]
      // If we know the parent folder, display paths containing that parent at the closest position
      if (eltNeighbors.length > 1 && this.fromFolderId) {
        eltNeighbors.sort(
          ({ path }) => {
            /*
             * Sort paths depending on the parent's position: 0 if a direct parent, 1 if grandparent, Infinite if not found
             * As parents are listed from top to bottom, look for the parent index from the end of the array (closer to the element)
             */
            const index = path.findLastIndex(elt => elt.id === this.fromFolderId)
            return index === -1 ? Infinity : path.length - 1 - index
          }
        )
      }

      return eltNeighbors
    },
    morePaths () {
      return this.elementNeighbors.length - 1
    },
    fromFolderId () {
      // Query parameter allowing to order path corresponding to the navigation scheme
      return this.$route.query.from
    }
  },
  methods: {
    ...mapActions(useDisplayStore, ['toggleAllPaths']),
    async load () {
      if (!this.element || this.elementNeighbors.length) return
      this.loading = true
      try {
        await this.$store.dispatch('elements/listNeighbors', { id: this.element.id })
      } finally {
        this.loading = false
      }
    }
  },
  watch: {
    element: {
      handler: 'load',
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.elt-header {
  & > .columns {
    width: 100%;
    margin-top: 0rem;
    margin-bottom: 0rem;
    .column {
      padding-top: 0rem;
      padding-bottom: 0rem;
    }
  }
}
.has-shadow {
  box-shadow: 0.1rem 0.1rem 0.6rem lightgray;
}
.paths-enter-active {
  transition: all .4s;
}
.paths-leave-active {
  transition: all .4s;
}
.paths-enter-from, .paths-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}
</style>
