<template>
  <div v-if="element" class="mb-3">
    <div class="is-pulled-right ml-2" v-if="isAnnotable">
      <button
        v-if="!annotationEnabled"
        class="button is-primary"
        title="Open annotation panel"
        v-on:click="toggle(true)"
      >
        <span class="icon">
          <i class="icon-plus"></i>
        </span>
        <span>Annotate</span>
      </button>
      <button
        v-else-if="annotationEnabled"
        class="button is-danger"
        title="Close annotation panel"
        v-on:click="toggle(false)"
      >
        <span>close</span>
      </button>
    </div>
    <span class="subtitle is-5">
      <span :title="element.type" class="has-text-grey mr-1">{{ typeName(element.type) }}</span>
      <strong :title="element.name">{{ element.name }}</strong>
    </span>
    <router-link
      v-if="element && element.id !== mainElementId"
      :to="{ name: 'element-details', params: { id: element.id } }"
    >
      <i class="icon-link" :title="`Navigate to ${element.name}`"></i>
    </router-link>
    <a
      class="icon-edit"
      title="Edit this element"
      v-on:click="editionModal = true"
    ></a>
    <DeleteModal :target="element" :redirect="element.id === mainElementId">
      <template v-slot:default="{ open, canDelete }">
        <a
          class="icon-trash"
          :class="canDelete ? 'has-text-danger' : 'has-text-grey-light'"
          :title="canDelete ? 'Delete this element' : 'Admin access on the project is required to delete this element'"
          v-on:click="open"
        ></a>
      </template>
    </DeleteModal>
    <p>
      <ItemId label="Element ID:" :item-id="elementId" /><br />
      <WorkerRunSummary v-if="element.worker_run" :worker-run-details="element.worker_run" />
      <span v-else-if="element.creator">Created by <strong>{{ element.creator }}</strong></span>
      <ConfidenceTag v-if="Number.isFinite(element.confidence)" :value="element.confidence" />
    </p>
    <!-- Display a message at the top of the panel when a selected children has no zone -->
    <div
      v-if="!element.zone?.polygon && !isFolder && element.id !== mainElementId"
      class="notification is-info my-2 p-3"
    >
      This element has no zone.
    </div>
    <EditionForm
      v-if="editionModal"
      v-model:modal="editionModal"
      :element="element"
    />
    <!-- This empty div prevents classes such as is-pulled-right from bleeding into other parts of the frontend -->
    <div class="is-clearfix"></div>
  </div>
</template>

<script>
import { mapState as mapVuexState, mapGetters, mapMutations } from 'vuex'
import { corporaMixin } from '@/mixins'
import { mapState } from 'pinia'
import ConfidenceTag from '@/components/ConfidenceTag.vue'
import ItemId from '@/components/ItemId.vue'
import WorkerRunSummary from '@/components/Process/Workers/WorkerRuns/WorkerRunSummary.vue'
import EditionForm from '@/components/Element/EditionForm'
import DeleteModal from '@/components/SingleDeletionModal.vue'
import { useClassificationStore } from '@/stores'

export default {
  mixins: [
    corporaMixin
  ],
  components: {
    ConfidenceTag,
    ItemId,
    WorkerRunSummary,
    EditionForm,
    DeleteModal
  },
  props: {
    elementId: {
      type: String,
      required: true
    }
  },
  data: () => ({
    deleteLoading: false,
    editionModal: false
  }),
  computed: {
    ...mapVuexState('elements', ['elements']),
    ...mapVuexState('annotation', {
      annotationEnabled: 'enabled'
    }),
    ...mapState(useClassificationStore, ['classifications']),
    ...mapGetters('elements', {
      // canWrite and canAdmin are already defined in corporaMixin
      canWriteElement: 'canWrite',
      canAdminElement: 'canAdmin'
    }),
    element () {
      return this.elements[this.elementId]
    },
    isFolder () {
      return this.getType(this.element.type)?.folder ?? false
    },
    isAnnotable () {
      const mainElement = this.elements[this.mainElementId]
      if (!mainElement) return false
      const mainFolder = this.getType(mainElement.type)?.folder ?? false
      return (
        mainElement.image !== null &&
        !mainFolder &&
        this.corpus &&
        this.canWrite(this.corpus)
      )
    },
    corpusId () {
      return this.element?.corpus?.id ?? null
    },
    mainElementId () {
      const routeParams = this.$route && this.$route.params
      return routeParams && routeParams.id
    }
  },
  methods: {
    ...mapMutations('annotation', ['toggle'])
  },
  watch: {
    elementId: {
      immediate: true,
      handler (id) {
        if (!id) return
        /*
         * Do not retrieve the element again if it already exists in the store,
         * unless it lacks some of the attributes only available from RetrieveElement.
         * Some elements in the store can come from list endpoints such as those of the children tree.
         * This ensures there are no strange behaviors where some actions are only sometimes disabled when they shouldn't,
         * or some element attributes are not displayed at all.
         */
        if (!this.element || this.element.id !== id || !this.element.rights || !this.classifications[this.element.id]) this.$store.dispatch('elements/get', { id })
      }
    }
  }
}
</script>
