import axios from 'axios'
import { UUID, Bucket } from '@/types'
import { unique } from '.'

export interface S3ImportParameters {
  bucket_name: string
  corpus_id: UUID
  element_id?: UUID | null
  prefix?: string | null
  folder_type?: string
  element_type?: string
}

// Retrieve available s3 buckets
export const listBuckets = unique(async (): Promise<Bucket[]> => (await axios.get('/ingest/buckets/')).data)

// Run a file import process from a bucket
export const importFromBucket = async (payload: S3ImportParameters): Promise<UUID> => (await axios.post('/ingest/create/', payload)).data.id
