
import { mapState, mapActions } from 'pinia'
import { defineComponent, PropType } from 'vue'
import {
  mapState as mapVuexState,
  mapActions as mapVuexActions,
  mapGetters as mapVuexGetters
} from 'vuex'

import { iiifUri } from '@/helpers'
import { truncateMixin, corporaMixin } from '@/mixins'
import { useDisplayStore, useClassificationStore } from '@/stores'

import DeleteModal from '@/components/SingleDeletionModal.vue'
import PreviewDropdown from './PreviewDropdown.vue'
import { Dataset, DatasetSet } from '@/types/dataset'
import { ElementList } from '@/types'
import RemoveModal from '@/components/Dataset/RemoveModal.vue'

export default defineComponent({
  mixins: [
    truncateMixin,
    corporaMixin
  ],
  components: {
    DeleteModal,
    PreviewDropdown,
    RemoveModal
  },
  props: {
    element: {
      type: Object as PropType<ElementList>,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    dataset: {
      type: Object as PropType<Dataset | null>,
      default: null
    },
    set: {
      type: Object as PropType<DatasetSet | null>,
      default: null
    }
  },
  data: () => ({
    imgFailed: false,
    removeElementModal: false
  }),
  computed: {
    ...mapVuexState('selection', ['selection']),
    ...mapState(useDisplayStore, ['displayElementClasses', 'compactDisplay', 'iiifHeight']),
    ...mapVuexGetters('auth', ['isVerified', 'hasFeature']),
    ...mapState(useClassificationStore, ['classifications']),
    thumbnailUrl () {
      if (this.element.thumbnail_url) return this.element.thumbnail_url
      if (this.element.zone) return iiifUri(this.element.zone, { height: this.iiifHeight(1 / 4) })
      return null
    },
    corpusId () {
      return this.element.corpus?.id || null
    },
    elementType () {
      if (this.element.type) return this.getType(this.element.type)
      return null
    },
    elementTypeName () {
      return this.elementType?.display_name ?? this.elementType?.slug ?? this.element.type ?? ''
    },
    selected () {
      return this.corpusId !== null && this.selection[this.corpusId]?.includes(this.element.id)
    },
    sortedClasses () {
      if (!this.displayElementClasses || !this.classifications[this.element.id]) return []
      const classes = [...this.classifications[this.element.id]]
      classes.sort((a, b) => a.ml_class.name.localeCompare(b.ml_class.name))
      return classes
    },
    /**
     * The backend generates thumbnail URLs for all folder elements, or for non-folder elements with a zone.
     * Since most folder elements do not have a zone anyway, we only display the preview modal for non-folder
     * elements with a thumbnail URL.
     */
    hasPreview () {
      return !this.elementType?.folder && this.element.thumbnail_url
    },
    routeQuery () {
      // Add extra information to the route when navigating among elements
      const eltId = this.$route?.params?.id
      if (!eltId) return {}
      return { from: eltId }
    }
  },
  methods: {
    ...mapActions(useClassificationStore, ['retrieveElementClassifications']),
    ...mapVuexActions('selection', ['select', 'unselect']),
    toggleSelection () {
      if (this.selected) this.unselect(this.element)
      else this.select({ elements: [this.element] })
    }
  }
})
