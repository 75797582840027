<template>
  <main class="container is-fluid content">
    <h1 class="title">Unverified email</h1>
    <div>
      Your account's e-mail address, <samp class="tag">{{ user.email }}</samp>, has not been verified. A verified e-mail is required to access this page.
      <br />
      Please check your inbox or ask for help from an administrator.
    </div>
    <div class="mt-3">
      <button
        v-if="!isVerified"
        type="button"
        class="button is-primary ml-2"
        v-on:click="sendVerificationEmail"
      >
        Resend verification email
      </button>
    </div>
    <p class="mt-3">
      <router-link :to="{ name: 'corpus-list' }">Homepage</router-link>
      &middot;
      <a v-on:click="goBack">Go back</a>
    </p>
  </main>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters('auth', ['isVerified'])
  },
  methods: {
    ...mapActions('auth', ['sendVerificationEmail']),
    goBack () {
      window.history.back()
    }
  }
}
</script>
