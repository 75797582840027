<template>
  <!--
    The dropdown is right-aligned to avoid overflowing the viewport when using it on the rightmost column of the project navigation.
    The dropdown is also set to be a "dropup" to not overflow when used on the very last row in elements navigation.
    There usually is more margin at the top than the bottom on long lists.
  -->
  <div class="dropdown is-right is-up" :class="{ 'is-active': hovered }">
    <div class="dropdown-trigger" v-on:mouseover="hovered = true" v-on:mouseleave="hovered = false">
      <slot>
        <button title="Preview this element on its image" class="button" type="button">
          <i class="icon-eye"></i>
        </button>
      </slot>
    </div>

    <div class="dropdown-menu">
      <div class="dropdown-content">
        <div class="notification is-warning" v-if="element && element.zone === null">
          This element is not on an image.
        </div>
        <div class="loader mx-auto" v-else-if="!element || element.zone === undefined"></div>
        <div class="columns is-multline is-centered" v-else>
          <div class="column is-narrow">
            <!--
              The width ratio is set as the dropdown is part of the navigation, which has 5 columns
              when in a project and 4 columns when in a folder, with some margins around the elements.
              This ratio might not play well with mobile devices or portrait displays.
              The dropdown is limited to half of the screen height, so the height ratio is set to 1/2.
            -->
            <ElementImage
              class="preview-image"
              :element="element"
              full-image
              :width-ratio="1 / 6"
              :height-ratio="1 / 2"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { corporaMixin, truncateMixin } from '@/mixins'
import ElementImage from '@/components/Image/ElementImage.vue'

export default {
  mixins: [
    corporaMixin,
    truncateMixin
  ],
  components: {
    ElementImage
  },
  props: {
    elementId: {
      type: String,
      required: true
    }
  },
  data: () => ({
    hovered: false
  }),
  computed: {
    ...mapState('elements', ['elements']),
    element () {
      return this.elements[this.elementId]
    },
    corpusId () {
      return this.element.corpus.id
    }
  },
  methods: {
    /**
     * Make a GET request to retrieve the element if the modal is opened and
     * if the element's zone is not available.
     */
    fetchElement () {
      if (!this.hovered || this.element?.zone !== undefined) return
      this.$store.dispatch('elements/get', { id: this.elementId })
    }
  },
  watch: {
    elementId: {
      immediate: true,
      handler: 'fetchElement'
    },
    hovered: 'fetchElement'
  }
}
</script>

<style scoped>
/*
 * The 12rem width is the default width of a Bulma dropdown.
 * Setting the maximum height to 50vh lets the dropdown take at most half of the screen,
 * but it can be smaller for images that are not as high.
 * There is no easy way to resize the dropdown properly to fit the image well before it
 * gets fully loaded, and even then just a simple scroll or a window resize would mess it up.
 */
.preview-image {
  width: 12rem;
  max-height: 50vh;
}
</style>
