
import { PropType, defineComponent } from 'vue'
import { TEXT_ORIENTATIONS } from '@/config'
import { orientationStyle } from '@/helpers'

import Actions from './Actions.vue'
import EditionForm from './EditionForm.vue'
import { Element } from '@/types'
import { Transcription } from '@/types/transcription'

export default defineComponent({
  components: {
    Actions,
    EditionForm
  },
  props: {
    index: {
      type: Number,
      required: true
    },
    element: {
      type: Object as PropType<Element>,
      required: true
    },
    transcription: {
      type: Object as PropType<Transcription>,
      required: true
    }
  },
  data: () => ({
    editing: false,
    TEXT_ORIENTATIONS
  }),
  methods: {
    orientationStyle
  }
})
