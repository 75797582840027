
import { defineComponent, PropType } from 'vue'
import { mapState } from 'pinia'
import { useDisplayStore, useClassificationStore } from '@/stores'
import ElementThumbnail from './ElementThumbnail.vue'
import ElementInLine from './ElementInLine.vue'
import { ElementList } from '@/types'
import { ProcessElementList } from '@/types/process'
import { Dataset, DatasetSet } from '@/types/dataset'

function isElementList (element: ElementList | ProcessElementList): element is ElementList {
  return 'type' in element
}

export default defineComponent({
  components: {
    ElementThumbnail,
    ElementInLine
  },
  props: {
    elements: {
      type: Array as PropType<ElementList[] | ProcessElementList[]>,
      required: true,
      validator: (elements: ElementList[] | ProcessElementList[]) => {
        return elements.every(element => element.id && element.name && element.corpus && (isElementList(element) ? element.type : element.type_id))
      }
    },
    /**
     * Make all actions unavailable on elements, to make the list appear as read-only.
     */
    disabled: {
      type: Boolean,
      default: false
    },
    /**
     * Force elements to be displayed as a table and not as thumbnails.
     */
    asTable: {
      type: Boolean,
      default: false
    },
    /**
     * Increase the amount of displayed thumbnail columns from 4 to 5.
     */
    maxSize: {
      type: Boolean,
      default: false
    },
    /**
     * These two props are only used when this component is included in the Dataset/Details vue,
     * to be transmitted down to ElementThumbnail.
     */
    dataset: {
      type: Object as PropType<Dataset | null>,
      default: null
    },
    set: {
      type: Object as PropType<DatasetSet | null>,
      default: null
    }
  },
  computed: {
    ...mapState(useDisplayStore, ['elementsTableLayout', 'displayDetails', 'displayElementClasses']),
    ...mapState(useClassificationStore, ['classifications']),
    size () {
      return this.maxSize
        ? 'is-one-fifth-desktop is-one-third'
        : 'is-one-quarter-desktop is-half'
    },
    /**
     * Whether or not any of the listed elements have loaded classes.
     * This returns True even if every element has no classes at all, just an empty array;
     * the classes column should be displayed anyway, with every row showing no classes.
     */
    hasClasses (): boolean {
      return this.elements.some(element => isElementList(element) && Array.isArray(this.classifications[element.id]))
    }
  },
  methods: { isElementList }
})
