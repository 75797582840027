import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b7384228"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "transcription-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Token = _resolveComponent("Token")!

  return (_openBlock(), _createElementBlock("div", {
    class: "content",
    style: _normalizeStyle(_ctx.orientationStyle(_ctx.transcription.orientation))
  }, [
    _createElementVNode("blockquote", _hoisted_1, [
      (_ctx.transcriptionEntities.length)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.tokens, (token, index) => {
            return (_openBlock(), _createBlock(_component_Token, _mergeProps(token, { key: index }), null, 16))
          }), 128))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createTextVNode(_toDisplayString(_ctx.transcription.text), 1)
          ], 64))
    ])
  ], 4))
}