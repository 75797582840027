
import { truncateMixin } from '@/mixins'
import ItemId from '@/components/ItemId.vue'
import { defineComponent, PropType } from 'vue'
import { ModelVersionLight } from '@/types/model'

export default defineComponent({
  props: {
    modelVersion: {
      type: Object as PropType<ModelVersionLight>,
      required: true
    }
  },
  components: {
    ItemId
  },
  mixins: [
    truncateMixin
  ]
})
