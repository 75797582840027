<template>
  <fieldset class="is-relative">
    <input
      type="text"
      ref="input"
      class="input is-static dropdown-trigger"
      :placeholder="placeholder"
      :disabled="isLoading || null"
      v-model="input"
      v-on:click.stop="toggleSuggestions(true)"
      v-on:focus="toggleSuggestions(true)"
      v-on:keyup="navigate"
    />
    <div
      v-if="isActive"
      class="dropdown-content is-paddingless"
    >
      <a class="dropdown-item" v-if="isLoading">
        <em>Loading…</em>
      </a>
      <!--
        The displayed suggestion string is not unique, and the keys can be anything,
        not just strings, so we just use JSON to ensure all keys are strings
        to avoid VUe warnings about duplicate keys in v-for.
      -->
      <a
        v-for="[key, suggestion] in filteredSuggestions"
        :key="JSON.stringify(key)"
        v-on:click.stop="select(key, true)"
        :title="suggestion"
        :class="{ 'selected': key === current }"
        class="dropdown-item"
      >
        <span
          v-for="(chunk, index) in hlText(suggestion)"
          :key="index"
          :class="{ 'has-text-info': chunk.hl }"
        >{{ chunk.text }}</span>
      </a>
    </div>
  </fieldset>
</template>

<script>
import { highlight } from '@/helpers'

export default {
  emits: [
    'erase',
    'select',
    'submit',
    'update:isActive',
    'update:modelValue'
  ],
  expose: ['focus'],
  props: {
    suggestions: {
      type: Array,
      default: () => ([]),
      validator: value => value.every(([, v]) => typeof v === 'string')
    },
    placeholder: {
      type: String,
      default: ''
    },
    /**
     * Displays a "Loading…" state on the suggestions dropdown.
     */
    isLoading: {
      type: Boolean,
      default: false
    },
    /**
     * Enables the suggestions.  This property is synchronous; it should be bound
     * with v-model.is-active and allows this component to let the parent know that
     * the user is focusing on it or has performed a keyboard action that toggles
     * the suggestions.
     */
    isActive: {
      type: Boolean,
      default: false
    },
    /**
     * Whether or not to automatically select a suggestion when the user presser Enter
     * and there is only one suggestion in the list.
     */
    selectSingleSuggestion: {
      type: Boolean,
      default: false
    },
    // Allows the input to behave just like a normal text input for a parent
    modelValue: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    // Current search text
    input: '',
    /*
     * The keyup handler gets called after the input's value is modified.
     * To check that we are pressing backspace on an input whose value was already empty,
     * previousInput always gets set to the input's value before the last change.
     */
    previousInput: '',
    // Hovered suggestion using key bindings
    current: null
  }),
  computed: {
    filteredSuggestions () {
      let suggestions = this.suggestions
      if (this.input.trim()) suggestions = suggestions.filter(([, display]) => this.hlText(display).length > 1)
      return suggestions
    }
  },
  methods: {
    hlText (suggestion) {
      return highlight(suggestion, this.input.split(/\s+/))
    },
    navigate (event) {
      this.$emit('update:isActive', true)
      // If there is only one filtered suggestion, it gets selected when hitting enter
      if (this.filteredSuggestions.length === 1 && this.selectSingleSuggestion) this.current = this.filteredSuggestions[0][0]
      const currentIndex = this.filteredSuggestions.findIndex(([key]) => key === this.current)
      if (['ArrowUp', 'ArrowDown'].includes(event.key) && this.filteredSuggestions.length) {
        let newIndex = currentIndex
        const max = this.filteredSuggestions.length

        if (event.key === 'ArrowDown') newIndex++
        else if (event.key === 'ArrowUp') newIndex = Math.max(-1, newIndex - 1)

        newIndex = newIndex % max
        /*
         * JavaScript's modulo can return numbers between -max and max,
         * so we add max when it is negative to get a proper arithmetic modulo
         */
        if (newIndex < 0) newIndex += max
        this.current = this.filteredSuggestions[newIndex][0]
      } else if (event.key === 'Enter') {
        if (currentIndex >= 0 || this.input.trim().length) {
          this.select(currentIndex >= 0 ? this.current : this.input.trim(), currentIndex >= 0)
          this.current = null
        } else this.$emit('submit')
      } else if (event.key === 'Escape') {
        this.$emit('update:isActive', false)
      } else if (event.key === 'Backspace' && this.input === '') {
        /*
         * Pressing Backspace without any input should cause the filter bar to erase any previous filter.
         * If the user has typed 'a', then presses Backspace, this.previousInput will be 'a' and this.input will be ''.
         * If the user presses Backspace, again, both will have the same values because `this.input does not change.
         * So we manually set `this.previousInput` to '' here, so that the next Backspace works.
         * Not doing this strange check would cause us to erase a filter as soon as the user presses backspace…
         */
        if (this.previousInput) this.previousInput = ''
        else this.$emit('erase')
      }
    },
    clickEventListener (event) {
      // Toggle dropdown off when user clicks outside component
      if (!this.$el.contains(event.target)) this.$emit('update:isActive', false)
    },
    toggleSuggestions (value) {
      this.current = null
      let isActive = this.isActive
      if (typeof value === 'boolean') isActive = value
      else isActive = !isActive
      if (isActive) window.addEventListener('click', this.clickEventListener)
      else window.removeEventListener('click', this.clickEventListener)
      /*
       * Only change the actual value of this.isActive *after* editing the event listener,
       * otherwise it might cause the suggestions to close even when we wanted to keep them opened.
       */
      this.$emit('update:isActive', isActive)
    },
    /**
     * Emit a select event and clean up the input.
     * `validated` specifies whether the parent component should apply a filter validation (if the value is a string
     * from the input) or not (if it is a selected suggestion).
     */
    select (value, validated = false) {
      this.$emit('select', { value, validated })
      this.current = null
      this.input = ''
      this.previousInput = ''
      this.focus()
    },
    focus () {
      this.$refs.input.focus()
      this.toggleSuggestions(true)
    }
  },
  beforeUnmount () {
    window.removeEventListener('click', this.clickEventListener)
  },
  watch: {
    input (newValue, oldValue) {
      this.previousInput = oldValue
      this.$emit('update:modelValue', newValue)
    },
    modelValue (newValue) {
      this.input = newValue
    },
    filteredSuggestions (newValue) {
      if (!newValue) this.current = null
      else if (this.current === null || !newValue[this.current]) this.current = Object.keys(newValue)[0]
    }
  }
}
</script>

<style lang="scss" scoped>
fieldset, .input {
  flex: 1;
  min-width: 30ch;
}
.dropdown-content {
  max-width: 60ch;
  position: absolute;
  // Height of an input with Bulma
  top: 2.25em;
  left: 0;
  z-index: 4;
  max-height: 20rem;
  overflow-y: auto;
}
.dropdown-item {
  white-space: normal;
  &:not(:last-child) {
    border-bottom: solid;
    border-width: thin;
    border-color: #eee;
  }
  &.selected {
    background-color: #f4f4f4;
  }
  & span:last-child {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
}
</style>
