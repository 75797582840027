
import { defineComponent, PropType } from 'vue'
import { ago } from '@/helpers'
import { useJobsStore } from '@/stores'
import { Job } from '@/types'

export default defineComponent({
  props: {
    job: {
      type: Object as PropType<Job>,
      required: true
    }
  },
  data: () => ({
    loading: false
  }),
  computed: {
    statusDate () {
      let stringDate = this.job.enqueued_at
      if (this.job.status === 'started') stringDate = this.job.started_at
      else if (['finished', 'failed'].includes(this.job.status)) stringDate = this.job.ended_at
      return stringDate ? new Date(stringDate) : null
    },
    canDelete () {
      return this.job.status !== 'started'
    },
    progressAttrs () {
      // Leave the `value` attribute unset when progress is not yet set
      if (this.job.progress !== null && Number.isFinite(this.job.progress)) return { value: this.job.progress }
      return {}
    }
  },
  methods: {
    async deleteJob () {
      if (!this.canDelete || this.loading) return
      this.loading = true
      try {
        const jobsStore = useJobsStore()
        await jobsStore.delete(this.job.id)
      } finally {
        this.loading = false
      }
    },
    ago
  }
})
