import { defineStore } from 'pinia'
import { listBuckets, importFromBucket, S3ImportParameters } from '@/api'
import { errorParser } from '@/helpers'
import { Bucket, UUID } from '@/types'
import { useNotificationStore } from './notification'

interface State {
  /**
   * S3 buckets that are available for imports.
   */
  buckets: { [key: Bucket['name']]: Bucket }
}

export const useIngestStore = defineStore('ingest', {
  state: (): State => ({
    buckets: {}
  }),
  actions: {
    async listBuckets () {
      const resp = await listBuckets()
      this.buckets = Object.fromEntries(resp.map(b => [b.name, b]))
    },

    async fromBucket (payload: S3ImportParameters): Promise<UUID> {
      try {
        return await importFromBucket(payload)
      } catch (err) {
        const notificationStore = useNotificationStore()
        notificationStore.notify({ type: 'error', text: errorParser(err) })
        throw err
      }
    }
  }
})
