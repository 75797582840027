import axios from 'axios'
import { unique } from '.'
import { UUID } from '@/types'
import { SearchQuery, SearchResponse } from '@/types/search'

export const searchCorpus = unique(async (corpusId: UUID, query: SearchQuery): Promise<SearchResponse> => (await axios.get(`/corpus/${corpusId}/search/`, { params: query })).data)

export interface SearchIndexParams {
  /**
   * Drop existing search index before reindexing.
   */
  drop?: boolean
}

export const startIndexation = unique(async (corpusId: UUID, payload: SearchIndexParams = {}) => (await axios.post(`/corpus/${corpusId}/index/`, payload)).data)
