<template>
  <span :title="'original date: ' + rawDate">
    <template v-if="lower && upper">
      From {{ display(lower) }} to {{ display(upper) }}
    </template>
    <template v-else-if="lower">
      After {{ display(lower) }}
    </template>
    <template v-else-if="upper">
      Before {{ display(upper) }}
    </template>
    <span
      v-else
      v-for="date in dates"
      :key="date.id"
    >
      {{ display(date) }}
      <template v-if="dates.length > 1"> [{{ date.type }}]</template>
    </span>
  </span>
</template>

<script>
import { MONTHS } from '@/config'
export default {
  props: {
    dates: {
      type: Array,
      required: true
    },
    rawDate: {
      type: String,
      required: true
    }
  },
  computed: {
    upper () {
      return this.dates.find(d => d.type === 'upper')
    },
    lower () {
      return this.dates.find(d => d.type === 'lower')
    }
  },
  methods: {
    display: function (date) {
      return `${date.year}${date.month || date.day ? ', ' : ''}${date.month ? MONTHS[date.month - 1] : ''}${date.day ? ' ' + date.day : ''}`
    }
  }
}
</script>
