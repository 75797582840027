
import {
  mapState as mapVuexState,
  mapGetters as mapVuexGetters,
  mapActions as mapVuexActions
} from 'vuex'
import { corporaMixin } from '@/mixins'
import { defineComponent, PropType } from 'vue'
import { errorParser } from '@/helpers'
import { UUID_REGEX } from '@/config'
import { UUID, Element, Classification, ElementType } from '@/types'
import { ElementDatasetSet } from '@/types/dataset'
import { mapStores, mapState, mapActions } from 'pinia'
import { useElementsStore, useClassificationStore, useNotificationStore } from '@/stores'

import DropdownContent from '@/components/DropdownContent.vue'
import MLClassSelect from '@/components/MLClassSelect.vue'
import Classifications from '@/components/Element/Classifications'
import ElementMetadata from '@/components/Element/Metadata'
import OrientationPanel from '@/components/Element/OrientationPanel.vue'
import Transcriptions from '@/components/Element/Transcription'
import TranscriptionsModal from '@/components/Element/Transcription/Modal.vue'
import TranscriptionCreationForm from '@/components/Element/Transcription/CreationForm.vue'
import ElementDatasets from '@/components/Element/Datasets/ElementDatasets.vue'

export default defineComponent({
  mixins: [
    corporaMixin
  ],
  components: {
    Classifications,
    DropdownContent,
    ElementMetadata,
    MLClassSelect,
    OrientationPanel,
    TranscriptionCreationForm,
    Transcriptions,
    TranscriptionsModal,
    ElementDatasets
  },
  props: {
    /**
     * Id of the element
     */
    elementId: {
      type: String as PropType<UUID>,
      validator: value => typeof value === 'string' && UUID_REGEX.test(value),
      required: true
    }
  },
  data: () => ({
    selectedNewClassification: '',
    validClassification: null,
    isSavingNewClassification: false,
    transcriptionModal: false
  }),
  computed: {
    ...mapStores(useClassificationStore),
    ...mapVuexState('elements', ['elements']),
    ...mapState(useElementsStore, ['elementDatasetSets']),
    ...mapState(useClassificationStore, {
      classes: 'classes',
      hasMLClasses: 'hasMLClasses',
      allClassifications: 'classifications'
    }),
    ...mapVuexGetters('elements', {
      // canWrite and canAdmin are already defined in corporaMixin
      canWriteElement: 'canWrite',
      canAdminElement: 'canAdmin'
    }),
    element (): Element | null {
      return this.elements?.[this.elementId] ?? null
    },
    corpusId (): UUID | null {
      return this.element?.corpus?.id ?? null
    },
    elementType (): ElementType | null {
      return this.element ? this.getType(this.element.type) : null
    },
    manualClassificationExists () {
      // A manual classification with the selected ML class exists; cannot create
      return this.selectedNewClassification &&
        this.element &&
        this.classifications &&
        this.classifications.find(c => (c.ml_class.id === this.selectedNewClassification && !c.worker_run))
    },
    classifications (): Classification[] {
      return this.allClassifications[this.elementId] ?? []
    },
    canCreateClassification () {
      return this.element && this.selectedNewClassification && !this.manualClassificationExists && !this.isSavingNewClassification
    },
    datasets (): ElementDatasetSet[] | null {
      return this.elementDatasetSets?.[this.elementId] ?? null
    }
  },
  methods: {
    ...mapVuexActions('elements', { retrieveElement: 'get' }),
    ...mapActions(useElementsStore, ['listElementDatasetSets']),
    ...mapActions(useNotificationStore, ['notify']),
    async createClassification () {
      if (!this.canCreateClassification) return
      this.isSavingNewClassification = true
      try {
        const newMLClass = this.classes[this.selectedNewClassification]
        await this.classificationStore.create(this.elementId, newMLClass.id)
      } catch (err) {
        this.notify({ type: 'error', text: errorParser(err) })
      } finally {
        this.selectedNewClassification = ''
        this.isSavingNewClassification = false
      }
    },
    async retrieve (elementId: UUID) {
      if (!elementId || elementId === undefined) return
      if (!this.element || this.element.id !== elementId || !this.element.rights || !this.allClassifications[elementId]) await this.retrieveElement({ id: elementId })
    },
    async getDatasetSets (elementId: UUID) {
      if (!elementId || elementId === undefined) return
      if (!Array.isArray(this.elementDatasetSets[elementId])) await this.listElementDatasetSets(elementId, { with_neighbors: true })
    }
  },
  watch: {
    elementId: {
      immediate: true,
      handler (value) {
        this.retrieve(value)
        this.getDatasetSets(value)
      }
    },
    classifications () {
      this.retrieve(this.elementId)
    },
    selectedNewClassification () {
      this.createClassification()
    }
  }
})
