import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "notification"
}
const _hoisted_2 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Job = _resolveComponent("Job")!
  const _component_Modal = _resolveComponent("Modal", true)!

  return (_openBlock(), _createBlock(_component_Modal, {
    title: "Background jobs",
    "model-value": _ctx.modelValue,
    "onUpdate:modelValue": _ctx.input
  }, {
    footer: _withCtx(() => [
      _createElementVNode("button", {
        class: _normalizeClass(["button", { 'is-loading': _ctx.loading }]),
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.list && _ctx.list(...args))),
        disabled: _ctx.loading || undefined
      }, " Refresh ", 10, _hoisted_2)
    ]),
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.jobs, (job, id) => {
        return (_openBlock(), _createBlock(_component_Job, {
          key: id,
          job: job
        }, null, 8, ["job"]))
      }), 128)),
      (_ctx.isEmpty(_ctx.jobs))
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, "There are no background jobs."))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["model-value", "onUpdate:modelValue"]))
}