import { defineStore } from 'pinia'
import { listAgents, listFarms, retrieveAgent } from '@/api'
import { UUID } from '@/types'
import { AgentDetails, AgentState, Farm } from '@/types/ponos'

interface State {
  agents: Record<UUID, (AgentDetails | AgentState)>
  farms: Record<UUID, Farm> | null
}

export const usePonosStore = defineStore('ponos', {
  state: (): State => ({
    agents: {},
    farms: null
  }),
  actions: {
    async retrieveAgent (agentId: UUID) {
      const agent = await retrieveAgent(agentId)
      this.agents[agent.id] = agent
    },

    async listAgents (page = 1) {
      const response = await listAgents({ page })
      this.agents = {
        ...this.agents,
        ...Object.fromEntries(response.results.map(agent => [agent.id, {
          ...(this.agents[agent.id] ?? {}),
          ...agent
        }]))
      }
      if (!response || !response.number || page !== response.number) {
        throw new Error('Pagination failed listing ponos agents')
      }
      if (response.next) await this.listAgents(page + 1)
    },

    async listFarms (page = 1) {
      const response = await listFarms({ page })
      this.farms = {
        ...(this.farms ?? {}),
        ...Object.fromEntries(response.results.map(farm => [farm.id, farm]))
      }
      if (!response || !response.number || page !== response.number) {
        throw new Error('Pagination failed listing ponos farms')
      }
      if (response.next) await this.listFarms(page + 1)
    }
  }
})