import { errorParser } from '@/helpers'
import * as api from '@/api'
import { defineStore } from 'pinia'
import { UUID } from '@/types'
import { EntityType } from '@/types/entity'
import { useNotificationStore } from '.'

interface State {
  entityTypes: {
    [corpusId: UUID]: {
      [typeId: UUID]: EntityType
    }
  }
}

export const useEntityTypesStore = defineStore('entityTypes', {
  state: (): State => ({
    entityTypes: {}
  }),
  actions: {
    async list (corpusId: UUID, page = 1) {
      // Do not start fetching corpus entity types if they have been retrieved already
      if (page === 1 && this.entityTypes[corpusId]) return
      const data = await api.listCorpusEntityTypes(corpusId, { page })
      if (!this.entityTypes[corpusId]) this.entityTypes[corpusId] = {}
      this.entityTypes[corpusId] = {
        ...this.entityTypes[corpusId],
        ...Object.fromEntries(data.results.map(type => [type.id, type]))
      }
      if (!data || !data.number || page !== data.number) {
        // Avoid any loop
        throw new Error(`Pagination failed listing entity types for project "${corpusId}"`)
      }
      // Continue loading pages
      if (data.next) await this.list(corpusId, page + 1)
    },
    async create (corpusId: UUID, type: Omit<EntityType, 'id'>) {
      try {
        const data = await api.createCorpusEntityType(corpusId, type)
        this.entityTypes[corpusId][data.id] = data
      } catch (err) {
        useNotificationStore().notify({ type: 'error', text: errorParser(err) })
        throw err
      }
    },
    async update (corpusId: UUID, typeId: UUID, type: Omit<EntityType, 'id'>) {
      try {
        if (!this.entityTypes[corpusId][typeId]) throw new Error(`Entity type ${typeId} not found in project ${corpusId}.`)
        const data = await api.updateCorpusEntityType(typeId, type)
        this.entityTypes[corpusId][typeId] = data
      } catch (err) {
        useNotificationStore().notify({ type: 'error', text: errorParser(err) })
        throw err
      }
    },
    async delete (corpusId: UUID, typeId: UUID) {
      try {
        if (!this.entityTypes[corpusId][typeId]) throw new Error(`Entity type ${typeId} not found in project ${corpusId}.`)
        await api.deleteCorpusEntityType(typeId)
        delete this.entityTypes[corpusId][typeId]
      } catch (err) {
        useNotificationStore().notify({ type: 'error', text: errorParser(err) })
        throw err
      }
    }
  }
})
