
import { StringUserConfigurationField } from '@/types/workerConfiguration'
import { PropType, defineComponent } from 'vue'

export default defineComponent({
  emits: ['update:modelValue'],
  props: {
    field: {
      type: Object as PropType<StringUserConfigurationField>,
      required: true
    },
    modelValue: {
      type: String,
      required: true
    }
  }
})
