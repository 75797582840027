<template>
  <main class="container is-fluid columns is-centered">
    <div class="column is-half-desktop is-four-fifths-tablet">
      <div class="notification is-danger" v-if="fieldErrors.error">
        <button class="delete" v-on:click="fieldErrors.error = null"></button>
        {{ fieldErrors.error }}
      </div>

      <form v-on:submit.prevent="register">
        <div class="field">
          <label class="label">Display name</label>
          <div class="control">
            <input
              v-model="displayName"
              class="input"
              :disabled="loading || null"
              required
              tabindex="1"
            />
            <template v-if="fieldErrors.display_name">
              <p class="help is-danger" v-for="err in fieldErrors.email" :key="err">{{ err }}</p>
            </template>
          </div>
        </div>
        <div class="field">
          <label class="label">E-mail address</label>
          <div class="control">
            <input
              type="email"
              v-model="email"
              class="input"
              :disabled="loading || null"
              required
              tabindex="2"
            />
            <template v-if="fieldErrors.email">
              <p class="help is-danger" v-for="err in fieldErrors.email" :key="err">{{ err }}</p>
            </template>
          </div>
        </div>
        <div class="field">
          <label class="label">Password</label>
          <div class="control">
            <input
              type="password"
              v-model="password"
              class="input"
              :disabled="loading || null"
              required
              tabindex="3"
            />
            <template v-if="fieldErrors.password">
              <p class="help is-danger" v-for="err in fieldErrors.password" :key="err">{{ err }}</p>
            </template>
          </div>
        </div>
        <div class="field">
          <label class="label">Confirm password</label>
          <div class="control">
            <input
              type="password"
              v-model="confirmPassword"
              class="input"
              :disabled="loading || null"
              required
              tabindex="4"
            />
            <template v-if="fieldErrors.confirmPassword">
              <p class="help is-danger" v-for="err in fieldErrors.confirmPassword" :key="err">{{ err }}</p>
            </template>
          </div>
        </div>
        <div class="field is-grouped is-grouped-centered">
          <p class="control">
            <button
              type="submit"
              class="button is-primary"
              :class="{ 'is-loading': loading }"
              :disabled="!canSubmit || null"
              :title="canSubmit || loading ? 'Register an account' : 'Some required fields are missing'"
              tabindex="4"
            >
              Register
            </button>
          </p>
        </div>
        <div class="field is-grouped is-grouped-centered">
          <p>
            Already have an account ?
            <router-link :to="{ name: 'login' }" tabindex="-1">
              Log in
            </router-link>
          </p>
        </div>
      </form>
    </div>
  </main>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { errorParser } from '@/helpers'
export default {
  data: () => ({
    email: '',
    displayName: '',
    password: '',
    confirmPassword: '',
    loading: false,
    error: null,
    fieldErrors: {}
  }),
  mounted () {
    this.tryRedirect(this.user)
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters('auth', ['isLoggedOn']),
    canSubmit () {
      return this.displayName && this.email && this.password && this.confirmPassword && !this.loading
    }
  },
  methods: {
    setErrors (error) {
      // Set field errors from API return value
      if (!error) this.fieldErrors = {}
      else if (!error.response || typeof error.response.data !== 'object') this.fieldErrors = { error: errorParser(error) }
      else this.fieldErrors = error.response.data
    },
    async register () {
      if (this.password !== this.confirmPassword) {
        this.fieldErrors = { confirmPassword: ["Passwords don't match"] }
        return
      }

      this.loading = true
      try {
        await this.$store.dispatch('auth/register', {
          email: this.email,
          password: this.password,
          display_name: this.displayName
        })
      } catch (err) {
        this.setErrors(err)
      } finally {
        this.loading = false
      }
    },
    tryRedirect () {
      if (!this.isLoggedOn) return
      if (this.$route.query && this.$route.query.next) this.$router.push(this.$route.query.next)
      else this.$router.push({ name: 'corpus-list' })
    }
  },
  watch: {
    // Use a watched property in case another request runs and updates the current user
    user () {
      this.tryRedirect()
    }
  }
}
</script>
