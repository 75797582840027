import axios from 'axios'
import { PageNumberPaginationParameters, unique } from '.'
import { PageNumberPagination, UUID } from '@/types'
import { EntityType } from '@/types/entity'

// List a corpus's entity types
export const listCorpusEntityTypes = unique(async (id: UUID, params: PageNumberPaginationParameters = {}): Promise<PageNumberPagination<EntityType>> => (await axios.get(`/corpus/${id}/entity-types/`, { params })).data)

type EntityTypeCreatePayload = Omit<EntityType, 'id'>
type EntityTypeUpdatePayload = Partial<EntityTypeCreatePayload>

// Create a new corpus entity type
export const createCorpusEntityType = async (corpusId: UUID, type: EntityTypeCreatePayload): Promise<EntityType> => (await axios.post('/entity/types/', { corpus: corpusId, ...type })).data

// Edit a corpus entity type
export const updateCorpusEntityType = async (id: UUID, data: EntityTypeUpdatePayload): Promise<EntityType> => (await axios.patch(`/entity/types/${id}/`, data)).data

// Delete a corpus entity type
export const deleteCorpusEntityType = unique(async (id: UUID) => (await axios.delete(`/entity/types/${id}/`)).data)
