<template>
  <form v-on:submit.prevent="createTranscription">
    <div class="field">
      <div class="control">
        <textarea
          v-model="text"
          v-on:keydown.enter.exact.prevent="createTranscription"
          class="textarea"
          :class="{ 'is-loading': loading }"
          :style="orientationStyle(orientation)"
          :disabled="loading || null"
          placeholder="Text…"
        ></textarea>
        <template v-if="fieldErrors.text">
          <p class="help is-danger" v-for="err in fieldErrors.text" :key="err">{{ err }}</p>
        </template>
      </div>
    </div>
    <div class="field is-grouped is-justify-content-space-between">
      <div class="control">
        <div class="select is-small">
          <select
            v-model="orientation"
            :disabled="loading || null"
            required
          >
            <option
              v-for="(textOrientation, key) in TEXT_ORIENTATIONS"
              :key="key"
              :value="key"
              :title="textOrientation.display"
            >
              {{ textOrientation.display }}
            </option>
          </select>
          <template v-if="fieldErrors.orientation">
            <p
              class="help is-danger"
              v-for="err in fieldErrors.orientation"
              :key="err"
            >
              {{ err }}
            </p>
          </template>
        </div>
      </div>
      <div class="control">
        <button
          type="submit"
          class="button is-primary"
          :class="{ 'is-loading': loading }"
          :disabled="loading || !isValid || null"
          :title="isValid ? 'Create a new transcription' : createDisabledTitle"
        >
          <span class="icon-plus"></span>
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { mapActions } from 'pinia'
import { mapMutations } from 'vuex'
import { TEXT_ORIENTATIONS } from '@/config'
import { errorParser, orientationStyle } from '@/helpers'
import { useTranscriptionStore, useNotificationStore } from '@/stores'

export default {
  props: {
    element: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    text: '',
    fieldErrors: {},
    loading: false,
    TEXT_ORIENTATIONS
  }),
  computed: {
    orientation: {
      get () {
        return this.$store.state.annotation.textOrientation
      },
      set (newValue) {
        this.setTextOrientation(newValue)
      }
    },
    isValid () {
      return this.text.trim().length > 0 && this.orientation in TEXT_ORIENTATIONS
    },
    createDisabledTitle () {
      const text = this.text.trim()
      if (!text && !this.orientation) return 'Please fill out the creation form'
      else if (!text) return 'A valid text is required to create the transcription'
      else if (!this.orientation || !(this.orientation in TEXT_ORIENTATIONS)) return 'A valid text orientation is required'
      return null
    }
  },
  methods: {
    ...mapActions(useTranscriptionStore, ['create']),
    ...mapActions(useNotificationStore, ['notify']),
    ...mapMutations('annotation', ['setTextOrientation']),
    orientationStyle,
    setErrors (error) {
      // Set field errors from API return value
      if (!error) this.fieldErrors = {}
      else if (!error.response || typeof error.response.data !== 'object') this.fieldErrors = { error: errorParser(error) }
      else this.fieldErrors = error.response.data
    },
    async createTranscription () {
      if (!this.isValid) return
      try {
        this.loading = true
        await this.create(
          this.element.id,
          {
            text: this.text,
            orientation: this.orientation
          }
        )
        this.notify({ type: 'success', text: 'Transcription created.' })
        // Reset form to default to allow typing a new transcription
        this.text = ''
      } catch (e) {
        this.setErrors(e)
        this.notify({ type: 'error', text: `An error occurred during transcription creation: ${errorParser(e)}` })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
