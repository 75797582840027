import { CreateTranscriptionPayload, ListTranscriptionsParameters, UpdateTranscriptionPayload, createTranscription, deleteTranscription, listTranscriptions, updateTranscription } from '@/api'
import { UUID } from '@/types'
import { Transcription } from '@/types/transcription'
import { defineStore } from 'pinia'
import { useNotificationStore } from './notification'
import { errorParser } from '@/helpers'

interface State {
  /**
   * Transcriptions by element
   */
  transcriptions: {
    [elementId: UUID]: {
      [id: UUID]: Transcription
    }
  },
}

export const useTranscriptionStore = defineStore('transcription', {
  state: (): State => ({
    transcriptions: {}
  }),
  actions: {
    async create (elementId: UUID, payload: CreateTranscriptionPayload) {
      const resp = await createTranscription(elementId, payload)
      if (!this.transcriptions[elementId]) this.transcriptions[elementId] = {}
      this.transcriptions[elementId][resp.id] = resp
      return resp
    },

    async update (elementId: UUID, id: UUID, payload: UpdateTranscriptionPayload) {
      const resp = await updateTranscription(id, payload)
      this.transcriptions[elementId][id] = resp
      return resp
    },

    async delete (elementId: UUID, id: UUID) {
      await deleteTranscription(id)
      delete this.transcriptions[elementId][id]
    },

    async list (elementId: UUID, payload: ListTranscriptionsParameters = {}) {
      try {
        if (!('page' in payload)) payload.page = 1
        const resp = await listTranscriptions(elementId, payload)
        if (!this.transcriptions[elementId]) this.transcriptions[elementId] = {}
        this.transcriptions[elementId] = {
          ...this.transcriptions[elementId],
          ...Object.fromEntries(resp.results.map(transcription => [transcription.id, transcription]))
        }
        // Avoid any loop
        if (payload.page && resp.number && payload.page !== resp.number) {
          throw new Error(`Pagination failed listing transcriptions for element "${elementId}"`)
        }
        // Load other pages
        if (resp.next && resp.number) {
          payload.page = resp.number + 1
          this.list(elementId, payload)
        }
      } catch (err) {
        useNotificationStore().notify({ type: 'error', text: errorParser(err) })
      }
    }
  }
})
