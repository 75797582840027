
import { truncateMixin } from '@/mixins'
import { PropType, defineComponent } from 'vue'
import { errorParser } from '@/helpers'
import { mapActions } from 'pinia'
import { useNotificationStore } from '@/stores'

export default defineComponent({
  mixins: [
    truncateMixin
  ],
  props: {
    instance: {
      type: Object as PropType<{name: string}>,
      required: true
    },
    enabled: {
      type: Boolean,
      default: true
    },
    saveFunction: {
      type: Function as PropType<(name: string) => Promise<unknown>>,
      required: true
    }
  },
  data () {
    return {
      editing: false,
      loading: false,
      name: this.instance.name
    }
  },
  methods: {
    ...mapActions(useNotificationStore, ['notify']),
    async save () {
      if (!this.editing || this.loading) return
      this.loading = true
      try {
        await this.saveFunction(this.name)
      } catch (err) {
        this.notify({ type: 'error', text: errorParser(err) })
      } finally {
        this.loading = false
        this.editing = false
      }
    }
  },
  watch: {
    instance () {
      if (!this.editing || this.loading) this.name = this.instance.name
      // Do not keep the editing form open if the element changes
      this.editing = false
    }
  }
})
