export const pluralize = (label: string, count: number) => count + ' ' + label + (count !== 1 ? 's' : '')

const RELATIVE_TIME_STEPS: [maxValue: number, unit: string][] = [
  [60, 'second'],
  [60, 'minute'],
  [24, 'hour'],
  [Infinity, 'day']
]

/**
 * Maximum amount of seconds before `ago` stops returning a relative date and instead uses an absolute date.
 */
const MAX_AGO = 86400 * 30

/**
 * Outputs the difference between a date and the current timestamp as a human-readable string.
 * When the difference exceeds 30 days, an absolute RFC 3339 date is returned,
 * with a space between the date and time and without a timezone.
 *
 * @param from A date to compare to the current timestamp.
 * @returns Human-readable difference between the date and the current timestamp.
 */
export const ago = (from: Date): string => {
  const totalSeconds = (Date.now() - from.getTime()) / 1000
  if (totalSeconds > MAX_AGO) return from.toISOString().slice(0, 19).replace('T', ' ')
  return humanTimedelta(totalSeconds) + ' ago'
}

/**
 * Convert a number representing a duration in seconds to a human-readable representation.
 * @param totalSeconds Duration in seconds to convert.
 */
export const humanTimedelta = (totalSeconds: number): string => {
  let diff = totalSeconds
  for (const [maxValue, name] of RELATIVE_TIME_STEPS) {
    if (diff >= maxValue) diff /= maxValue
    else return pluralize(name, Math.floor(diff))
  }
  /*
   * We went beyond the maximum value for all units:
   * the last unit should have had an infinite maximum value, so this should have never happened.
   */
  throw new Error('Out of units!')
}

/**
 * Convert a number representing a duration in seconds to a human-readable representation,
 * as a succession of units down to milliseconds: `2 hours 3 minutes 4 seconds 5 milliseconds`.
 * @param totalSeconds Duration in seconds to convert.
 */
export const preciseHumanTimedelta = (totalSeconds: number): string => {
  let diff = totalSeconds
  let output = ''

  /*
   * If there is a fractional part, add milliseconds in the output
   * Uses toFixed to avoid floating-point issues
   */
  let ms = Number.parseInt((diff % 1 * 1000).toFixed(), 10)
  if (ms >= 1000) {
    // Oops, we still got floating-point issues; add seconds and hide the problem
    diff += Math.floor(ms / 1000)
    ms %= 1000
  }

  if (ms > 0) output = pluralize('millisecond', ms)

  diff = Math.floor(diff)

  for (const [maxValue, unit] of RELATIVE_TIME_STEPS) {
    // If for this unit we have a value other than zero, prepend it to the output
    if (diff % maxValue >= 1) {
      output = pluralize(unit, Math.floor(diff % maxValue)) + ' ' + output
    }
    /*
     * If there is more than the maximum value for this unit, divide and continue.
     * The maximum value is exclusive, so reaching exactly that value also counts.
     */
    if (diff >= maxValue) diff /= maxValue
    // Else, we know all of the next units will be zero, so we can stop there
    else break
  }

  // Since we skip units that amount to zero in the loop, we might have an empty output for values below 1 millisecond
  return output.trimEnd() || '0 seconds'
}

/**
 * Convert a number representing a duration in seconds into the equivalent of Python's str(timedelta):
 * `[D days,] H:MM:SS.uuuuuu`
 * @param totalSeconds Duration in seconds to convert.
 */
export const secondsToTimedelta = (totalSeconds: number): string => {
  const hours = Math.floor(totalSeconds / 3600 % 24).toString()
  const minutes = Math.floor(totalSeconds / 60 % 60).toString().padStart(2, '0')
  const seconds = Math.floor(totalSeconds % 60).toString().padStart(2, '0')
  const us = Math.floor(totalSeconds * 1e6 % 1e6).toString().padStart(6, '0')

  const output = `${hours}:${minutes}:${seconds}.${us}`

  /*
   * When the timedelta is above 1 day, include the days separately.
   * This matches Python's str(timedelta), but not Django or DRF's,
   * because Django removes the "days" word to stay language-neutral.
   * We include it again because this output is intended for human consumption, not to be sent to an API.
   */
  if (totalSeconds >= 86400) return `${pluralize('day', Math.floor(totalSeconds / 86400))}, ${output}`
  return output
}

/**
 * Parse a string representing a Python timedelta into seconds.
 * This supports Django's and Python's formats, `[D [days,]] H:MM:SS[.uuuuuu]`
 */
export function timedeltaToSeconds (timedelta: string): number {
  const match = timedelta.match(/^(?:(\d+) (?:days?, )?)?(\d+):(\d+):(\d+)(?:.(\d+))?$/)
  // Matching against a regex can return null if there was no match
  if (match === null) throw new Error('Invalid timedelta')

  /*
   * match[0] contains the full matched string which we don't need. The rest contains the capturing groups.
   * The capturing groups for days and microseconds can be `undefined` since they are optional.
   */
  const parsed = match.slice(1).map(value => value === undefined ? 0 : Number.parseInt(value))

  // Number.parseInt can return NaN
  if (parsed.some(value => isNaN(value))) throw new Error('Invalid timedelta')

  let [days, hours, minutes, seconds, us] = parsed
  hours += days * 24
  minutes += hours * 60
  seconds += minutes * 60
  seconds += us / 1e6
  return seconds
}
