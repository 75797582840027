import { assign, clone, merge, zip } from 'lodash'
import * as api from '@/api'
import { ELEMENT_LIST_MAX_AUTO_PAGES } from '@/config'
import { errorParser } from '@/helpers'
import { useJobsStore, useClassificationStore } from '@/stores'

export const initialState = () => ({
  // { [id]: element }
  elements: {},
  /**
   * A list of parents IDs fetched recursively for a given element.
   * { [elementId]: id[] }
   */
  parents: {},
  /**
   * @type {{ [elementId: import('@/types').UUID]: import('@/api').ElementNeighbor[] }}
   */
  neighbors: {},
  // { [id]: { children: { count: … } }
  links: {},
  // { [id]: { count, previous, next, number } }
  childrenPagination: {},
  /**
   * Boolean toggled when an element is created or deleted.
   * This is used by the navigation in order to update the elements list and count in case of an
   * action triggered from the tree or the navigation stores.
   */
  elementsUpdate: false
})

/**
 * Add an element to the store state, or merge extra data into an existing element.
 * This is a private method because the `addChildren` mutation needs to call the `set` mutation,
 * but a mutation cannot call other mutations.
 */
const mergeElement = (state, element) => {
  state.elements = {
    ...state.elements,
    [element.id]: { ...merge(state.elements[element.id] || {}, element) }
  }
}

export const mutations = {
  set: mergeElement,

  bulkSet (state, elements) {
    state.elements = {
      ...state.elements,
      ...elements.reduce((obj, child) => {
        obj[child.id] = { ...merge(state.elements[child.id] || {}, child) }
        return obj
      }, {})
    }
    state.links = {
      ...state.links,
      ...elements.reduce((obj, child) => {
        obj[child.id] = merge({ parents: [], children: [] }, state.links[child.id] || {})
        return obj
      }, {})
    }
  },

  updatedElements (state) {
    state.elementsUpdate = !state.elementsUpdate
  },

  remove (state, id) {
    // Entirely removes an element and its paths from the store
    delete state.elements[id]
    // Delete element own path and its reference in other paths
    const newLinks = { ...state.links }
    delete newLinks[id]
    // Delete element from other paths
    Object.values(newLinks).reduce((l, { parents, children }) => {
      l.push(parents, children)
      return l
    }, []).forEach(elts => {
      const index = elts.findIndex(e => e === id)
      if (index >= 0) elts.splice(index, 1)
    })
    state.links = newLinks
  },

  /**
   * Add children to an element. This both adds the children elements to `elements` and the IDs to `links`.
   * Expects an element ID and a list of children elements.
   */
  addChildren (state, { element, children = [] }) {
    if (!children) return
    children.forEach(child => mergeElement(state, child))
    // Holds all of the updated links
    const newLinks = {
      [element]: {
        parents: [],
        children: [],
        ...state.links[element]
      }
    }
    for (const child of children) {
      // Add child to element's children
      if (!newLinks[element].children.includes(child.id)) newLinks[element].children.push(child.id)

      // Add element to child's parents
      if (!newLinks[child.id]) {
        newLinks[child.id] = {
          parents: [],
          children: [],
          ...state.links[child.id]
        }
      }
      if (!newLinks[child.id].parents.includes(element)) newLinks[child.id].parents.push(element)
    }
    // Apply updated links to the state
    state.links = {
      ...state.links,
      ...newLinks
    }
  },

  /**
   * Add parents to an element. This does not update links because the parents can be listed recursively.
   */
  addParents (state, { element, parents = [], corpus }) {
    if (!corpus) throw new Error('A corpus is required.')
    if (!parents) return
    state.elements = {
      ...state.elements,
      ...parents.reduce((obj, parent) => {
        obj[parent.id] = { corpus, ...merge(parent, state.elements[parent.id] || {}) }
        return obj
      }, {})
    }
    // Holds all of the updated parents
    const newParents = {
      [element]: state.parents[element] || []
    }
    for (const parent of parents) {
      // Add parent to element's parents
      if (!newParents[element].includes(parent.id)) newParents[element].push(parent.id)
    }
    // Apply updated parents to the state
    state.parents = {
      ...state.parents,
      ...newParents
    }
  },

  /**
   * Add neighbors to an element.
   */
  addNeighbors (state, { element, neighbors }) {
    const newNeighbors = clone(neighbors)
    let nullParentError = false
    if (newNeighbors && Array.isArray(newNeighbors)) {
      newNeighbors.forEach(neighbor => {
        /*
         * Detect and remove null values in each neighbor element's parents.
         * When the path array in ElementPath has IDs that point to elements that no longer exist,
         * it will cause a null to appear in the `parents` array.
         * We will still update the state, but throw an error after committing to show a notification
         * and get an explicit alert on Sentry.
         */
        if (!nullParentError && neighbor.path.some(parent => parent === null)) nullParentError = true
        neighbor.path = neighbor.path.filter(parent => parent !== null)
      })
    }
    state.neighbors = {
      ...state.neighbors,
      [element]: newNeighbors
    }
    if (nullParentError) throw new Error('Unknown parent elements were found in neighbors')
  },

  /**
   * Remove all children links from an element, for cache invalidation.
   */
  resetChildren (state, { element }) {
    if (!state.links[element] || !state.links[element].children) return

    // Remove children links from the parent
    const newLinks = {
      [element]: {
        ...state.links[element],
        children: []
      }
    }

    // Remove the parent element from its children's parent links
    for (const childId of state.links[element].children) {
      if (!state.links[childId] || !state.links[childId].parents) continue

      const parentIndex = state.links[childId].parents.indexOf(element)
      if (parentIndex < 0) continue

      newLinks[childId] = {
        ...state.links[childId],
        parents: [...state.links[childId].parents]
      }
      newLinks[childId].parents.splice(parentIndex, 1)
    }

    // Apply changes to the state
    state.links = { ...state.links, ...newLinks }
  },

  setChildrenPagination (state, { element, ...data }) {
    /*
     * Add the last update date for If-Modified-Since headers, using RFC 2822 section 3.3 format:
     * Wed, 28 Jul 1993 12:39:07 GMT
     */
    data.retrievedOn = new Date().toUTCString()
    state.childrenPagination = {
      ...state.childrenPagination,
      [element]: data
    }
  },

  reset (state) {
    assign(state, initialState())
  }
}

export const actions = {
  async get ({ commit, rootState }, { id }) {
    try {
      const element = await api.retrieveElement(id)
      commit('set', useClassificationStore().cleanElementList([element])[0])
      // If we just retrieved the currently selected element, update its metadata
      if (element.id === rootState.annotation.selectedElement?.id) {
        commit('annotation/selectElement', element, { root: true })
      }
      return element
    } catch (err) {
      commit('notifications/notify', { type: 'error', text: errorParser(err) }, { root: true })
      throw err
    }
  },

  async create ({ commit, rootState }, payload) {
    try {
      // Element creation endpoint returns a fully serialized element
      const element = await api.createElement(payload)
      commit('set', element)
      // If we just retrieved the currently selected element, update its metadata
      if (element.id === rootState.annotation.selectedElement?.id) {
        commit('annotation/selectElement', element, { root: true })
      }

      // Add parent link for this element
      if (payload.parent) commit('addChildren', { element: payload.parent, children: [element] })
      else commit('addChildren', { element: element.id, children: [] })

      // Toggle elementsUpdate boolean for navigation
      commit('updatedElements')

      return element
    } catch (err) {
      commit('notifications/notify', { type: 'error', text: errorParser(err) }, { root: true })
      throw err
    }
  },

  async patch ({ commit, rootState }, payload) {
    try {
      const updatedElement = await api.updateElement(payload)
      commit('set', updatedElement)
      // If we just retrieved the currently selected element, update its metadata
      if (updatedElement.id === rootState.annotation.selectedElement?.id) {
        commit('annotation/selectElement', updatedElement, { root: true })
      }
      // Toggle elementsUpdate boolean for navigation e.g. a name update
      commit('updatedElements')
      return updatedElement
    } catch (err) {
      commit('notifications/notify', { type: 'error', text: errorParser(err) }, { root: true })
      throw err
    }
  },

  async delete ({ commit, rootState }, { id }) {
    try {
      await api.deleteElement({ id, delete_children: true })
      commit('remove', id)
      if (id === rootState.annotation.selectedElement?.id) { commit('annotation/selectElement', null, { root: true }) }
      commit('notifications/notify', { type: 'success', text: 'Element deletion has been scheduled.' }, { root: true })
    } finally {
      useJobsStore().list()
    }
  },

  async move ({ commit }, payload) {
    try {
      await api.moveElement(payload)
      commit('remove', payload.source)
      commit('notifications/notify', { type: 'success', text: 'Element moving has been scheduled.' }, { root: true })
    } catch (err) {
      commit('notifications/notify', { type: 'error', text: errorParser(err) }, { root: true })
      throw err
    } finally {
      useJobsStore().list()
    }
  },

  async createParent ({ commit, dispatch }, { childId, parentId }) {
    try {
      await api.createParentElement({ childId, parentId })
      dispatch('listNeighbors', { id: childId })
    } catch (err) {
      const message = err.response?.data?.parent ?? err.response?.data?.child ?? err
      commit('notifications/notify', { type: 'error', text: errorParser(message) }, { root: true })
    }
  },

  /**
   * Start, restart or continue fetching an element's children for up to `max` API result pages.
   *
   * If no pagination was stored in the `childrenPagination` state, starts from the first page.
   * If a pagination existed and a next page is available, resumes paginating.
   * If a pagination existed and there was no next page (i.e. all children have been listed),
   * requests for the first page with a `If-Modified-Since` HTTP header; if some children were modified,
   * it erases the element's children and restarts paginating from the first page.
   *
   * `max` sets the limit of API pages that should be iteratively retrieved.
   * It can be set to `Infinity` to load all pages.
   * `hasChildren` requests the API to fill the `has_children` attribute on elements.
   */
  async nextChildren ({ state, commit }, { id, max = ELEMENT_LIST_MAX_AUTO_PAGES, hasChildren = false, zone = true }) {
    try {
      let count = 0
      while (count < max) {
        const pagination = state.childrenPagination[id] || { number: 0 }

        const payload = { id, page: pagination.number + 1 }
        if (pagination.number > 0 && !pagination.next) {
          // No next page; prepare to use If-Modified-Since and possibly restart the pagination
          payload.page = 1
          payload.modifiedSince = pagination.retrievedOn
        }
        if (hasChildren) payload.with_has_children = true
        if (!zone) payload.with_zone = false

        const data = await api.listElementChildren(payload)

        if (payload.modifiedSince) {
          // HTTP 304: abort pagination here as this means nothing changed.
          if (!data) break
          // HTTP 200 with If-Modified-Since: erase the existing children and continue paginating from the first page
          commit('resetChildren', { element: id })
        } else if (!data) throw new Error('API returned HTTP 304 without a If-Modified-Since condition')

        // Save the children and new pagination data
        const { results, ...newPagination } = data
        const children = useClassificationStore().cleanElementList(results)
        commit('addChildren', { element: id, children })
        commit('setChildrenPagination', { element: id, ...newPagination })

        // No next page
        if (!newPagination.next) break
        count++
      }
    } catch (err) {
      commit('notifications/notify', { type: 'error', text: errorParser(err) }, { root: true })
      throw err
    }
  },

  /**
   * Fetch an element's parents recursively.
   */
  async getParentsBulk ({ commit }, { id, corpus }) {
    if (!corpus) throw new Error('A corpus is required.')
    try {
      let parentsPagination = { number: 0 }
      do {
        const payload = {
          id,
          page: parentsPagination.number + 1,
          with_zone: false,
          with_corpus: false,
          recursive: true
        }

        const data = await api.listElementParents(payload)

        // Save the parents and new pagination data
        const { results, ...newPagination } = data

        // Save parents classifications
        const parents = useClassificationStore().cleanElementList(results)
        commit('addParents', { element: id, parents, corpus })
        parentsPagination = newPagination
      } while (parentsPagination.next)
    } catch (err) {
      commit('notifications/notify', { type: 'error', text: errorParser(err) }, { root: true })
      throw err
    }
  },

  async listNeighbors ({ commit }, payload) {
    try {
      const data = await api.listElementNeighbors(payload.id)
      commit('addNeighbors', { element: payload.id, neighbors: data })
    } catch (err) {
      commit('addNeighbors', { element: payload.id, neighbors: null })
      commit('notifications/notify', { type: 'error', text: errorParser(err) }, { root: true })
    }
  }
}

export const getters = {
  canWrite: state => id => {
    return (state.elements[id]?.rights ?? []).includes('write')
  },
  canAdmin: state => id => {
    return (state.elements[id]?.rights ?? []).includes('admin')
  },
  /**
   * Returns the first parent element of this element. Used to redirect to a parent when deleting the element
   * @type {import('@/types').UUID}
   */
  firstParentId: state => id => {
    // Find all known paths for the element and reverse them so they start with the most direct parents
    const allPaths = state.neighbors?.[id]?.map(({ path }) => [...path].reverse()) ?? []
    /*
     * Prevent errors on null parents since ListElementNeighbors can return null parents for paths with 'ghost elements':
     * find the first defined parent in any path, starting with the most direct parents.
     * This uses zip().flat(1) so that we can look for all the direct parents in all paths first, then the grandparents, etc.
     * instead of going through each ancestry line one by one.
     * Lodash's zip() operates like itertools.zip_longest and not like the standard Python zip(),
     * so it can return arrays with undefined values if all paths are not the same length,
     * so we have to handle both null and undefined.
     */
    return zip(...allPaths).flat(1).find(parent => parent)?.id
  }
}

export default {
  namespaced: true,
  state: initialState(),
  mutations,
  actions,
  getters
}
