
import { mapState, mapActions } from 'pinia'
import { defineComponent } from 'vue'
import { useDisplayStore } from '@/stores'

export default defineComponent({
  props: {
    /**
     * An identifier that must be unique across the whole frontend.
     * Used to store the dropdown's opened/closed state in the store.
     */
    id: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState(useDisplayStore, ['dropdowns']),
    toggled () {
      return this.dropdowns[this.id] ?? true
    }
  },
  methods: {
    ...mapActions(useDisplayStore, ['toggleDropdown']),
    toggle () {
      this.toggleDropdown(this.id, !this.toggled)
    }
  }
})
