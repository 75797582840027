
import { isEqual } from 'lodash'
import { mapState } from 'pinia'
import { UUID_REGEX } from '@/config'
import { corporaMixin } from '@/mixins'
import Paginator from '@/components/Paginator.vue'
import SearchForm from '@/components/Search/Form.vue'
import SearchResult from '@/components/Search/Result.vue'
import { useSearchStore } from '@/stores'
import { defineComponent } from 'vue'
import { Corpus, UUID } from '@/types'
import { SearchQuery } from '@/types/search'
import { LocationQueryRaw } from 'vue-router'

export default defineComponent({
  components: {
    Paginator,
    SearchForm,
    SearchResult
  },
  mixins: [
    corporaMixin
  ],
  props: {
    corpusId: {
      type: String,
      default: '',
      validator: value => typeof value === 'string' && (value === '' || UUID_REGEX.test(value))
    }
  },
  mounted () {
    useSearchStore().$reset()
    if (this.selectedCorpusId) this.search()
    // Support the older search URLs that used ?corpus=… instead of a path parameter
    else if (this.$route.query?.corpus) this.selectedCorpusId = this.$route.query.corpus.toString()
  },
  beforeRouteUpdate (to) {
    this.search(to.params.corpusId as UUID, to.query)
  },
  data: () => ({
    loading: false
  }),
  computed: {
    ...mapState(useSearchStore, ['documents']),
    indexableCorpora () {
      if (!this.corpora) return []
      // TODO: Remove the cast once the corpora module is migrated to Pinia
      return Object.values(this.corpora as Record<UUID, Corpus>).filter(c => c.indexable)
    },
    selectedCorpusId: {
      get (): UUID | null {
        return this.corpusId
      },
      set (corpusId: UUID | null) {
        const newRoute = { ...this.$route, params: {} }
        if (corpusId) newRoute.params = { corpusId }
        if (!isEqual(this.$route.params, newRoute.params)) this.$router.push(newRoute)
      }
    },
    query: {
      get () {
        return this.$route.query
      },
      set (query: SearchQuery) {
        if (!isEqual(this.$route.query, query)) {
          // The query has changed, update the route.
          this.$router.push({
            ...this.$route,
            // Make TypeScript happy, as a SearchQuery is too restrictive to be a generic vue-router query string
            query: Object.assign({} as LocationQueryRaw, query)
          })
        } else if (this.corpusId) {
          // If the search button is hit, the query hasn't changed, and a corpus is selected, re-run the search
          this.search(this.corpusId, query)
        }
      }
    },
    page: {
      get () {
        if (!this.query.page) return 1
        return Number.parseInt(this.query.page.toString(), 10) || 1
      },
      set (page: number) {
        this.query = { ...this.query, page: page.toString() }
      }
    }
  },
  methods: {
    async search (corpusId?: UUID, query: Partial<SearchQuery> = {}) {
      if (!corpusId) corpusId = this.corpusId
      if (this.loading || !UUID_REGEX.test(this.corpusId)) return

      const completeQuery: SearchQuery = {
        // Default the search query to searching anything
        query: '*',
        // Only request facets when no query at all was set, which occurs when first selecting a project
        only_facets: !query.query?.trim(),
        ...query
      }

      this.loading = true
      try {
        await useSearchStore().search(this.corpusId, completeQuery)
      } finally {
        this.loading = false
      }
    }
  },
  watch: {
    corpusId (newValue) {
      // Block using a corpus ID that does not exist or that is not indexable
      if (newValue && !this.corpus?.indexable) this.selectedCorpusId = null
      // Reset results when clicking 'Search' in the NavBar
      if (!newValue) useSearchStore().$reset()
    }
  }
})
