<template>
  <main class="container is-fluid">
    <div class="notification is-danger" v-if="error">
      {{ error }}
    </div>
    <div class="has-text-centered" v-else-if="isLoggedOn">
      Logging out...<br />
      <a class="button is-large is-loading is-white"></a>
    </div>
    <div class="has-text-centered" v-else>
      <p>Logged out successfully.</p>
      <p>
        <router-link :to="{ name: 'home' }">Homepage</router-link>
        &middot;
        <a v-on:click="$router.back">Go back</a>
      </p>
    </div>
  </main>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  data: () => ({
    error: null
  }),
  computed: {
    ...mapGetters('auth', ['isLoggedOn'])
  },
  methods: {
    ...mapActions('auth', ['logout'])
  },
  async mounted () {
    try {
      await this.logout()
    } catch (err) {
      this.error = err
      return
    }
    this.$router.replace({ name: 'login' })
  }
}
</script>

<style scoped>
.button.is-white {
  border-color: transparent;
}
</style>
