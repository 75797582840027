<template>
  <div>
    <div v-if="corpusError" class="notification is-warning">
      {{ corpusError }}
    </div>
    <template v-else>
      <div class="field has-addons is-marginless">
        <FilterBar
          class="is-expanded"
          placeholder="Filter elements…"
          :filters="availableFilters"
          :disabled="loading"
          v-if="corpus.id"
          v-model="selectedFilters"
          v-on:submit="submitFilters"
        />
        <div class="control has-tooltip-bottom" data-tooltip="Sort elements by a field">
          <span class="select">
            <select v-model="order" title="Sort field">
              <option value="position" v-if="elementId">Position</option>
              <option value="name">Name</option>
              <option value="created">Creation date</option>
              <option value="random">Random</option>
            </select>
          </span>
        </div>
        <div class="control has-tooltip-bottom" data-tooltip="Sort direction">
          <button class="button" v-on:click="toggleOrderDirection" :disabled="order === 'random' || null">
            <i :class="`icon-sort-${orderDirection}`"></i>
          </button>
        </div>
      </div>
      <div v-if="parsedErrors.length" class="notification is-danger content mt-3">
        <p>An error occurred while listing elements:</p>
        <ul>
          <li v-for="(errorData, i) in parsedErrors" :key="i">
            <template v-if="errorData.name">
              <strong>{{ errorData.name }}</strong>:
            </template>
            <template v-if="errorData.messages.length === 1">
              {{ errorData.messages[0] }}
            </template>
            <ul v-else>
              <li v-for="(message, j) in errorData.messages" :key="j">
                {{ message }}
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <Paginator
        v-else
        :response="elements"
        :loading="loading"
        v-model:page="pageNumber"
        :page-size="navigationPageSize"
        bottom-bar
      >
        <template v-slot:default="{ results }">
          <ElementList :elements="results" :max-size="!elementId || !displayDetails" />
        </template>
      </Paginator>
    </template>
  </div>
</template>

<script>
import { capitalize, isEqual } from 'lodash'
import { mapState, mapActions } from 'pinia'
import {
  mapState as mapVuexState,
  mapActions as mapVuexActions,
  mapMutations as mapVuexMutations
} from 'vuex'

import { NAVIGATION_PAGE_SIZES, DEFAULT_PAGE_SIZE } from '@/config'
import { getNavigationFilters } from '@/filterbar'
import { ensureArray, errorParser } from '@/helpers'
import { corporaMixin } from '@/mixins'
import { useDisplayStore, useClassificationStore } from '@/stores'

import Paginator from '@/components/Paginator'
import ElementList from './ElementList'
import FilterBar from './FilterBar'
import { isAxiosError } from 'axios'

export default {
  emits: ['update:query'],
  mixins: [
    corporaMixin
  ],
  components: {
    Paginator,
    FilterBar,
    ElementList
  },
  props: {
    corpusId: {
      type: String,
      required: true
    },
    elementId: {
      type: String,
      default: null
    },
    query: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    // Filters that have been typed; they may not be applied if the user has not pressed Enter yet.
    selectedFilters: {},
    pageNumber: 1,
    loading: false,
    /**
     * @type {string | string[] | Record<string, string | string[]> | null}
     */
    error: null,
    corpusError: null,

    /*
     * Beware updating those values as they immediately trigger onFilterUpdate
     * and may create race-conditions
     */
    order: 'name',
    orderDirection: 'asc'
  }),
  computed: {
    ...mapVuexState('navigation', { filteredElements: 'elements' }),
    ...mapVuexState('navigation', ['scheduledDeletion', 'appliedFilters']),
    ...mapVuexState('selection', ['selection']),
    // Used to map selected IDs to element objects
    ...mapVuexState('elements', { elementObjects: 'elements' }),
    ...mapVuexState('elements', ['elementsUpdate']),
    ...mapState(useDisplayStore, ['navigationPageSize', 'displayDetails', 'displayElementClasses']),
    ...mapState(useClassificationStore, ['classifications']),
    availableFilters () {
      return getNavigationFilters(this.$store, this.selectedFilters)
    },
    elements () {
      if (!this.filteredElements) return {}
      // Remove elements scheduled to be deleted from filtered elements to prevent their display
      return {
        ...this.filteredElements,
        results: this.filteredElements.results.filter(e => !this.scheduledDeletion.includes(e.id))
      }
    },
    /**
     * Parses an API error into an array of objects, optionally with relevant filter names if they exist.
     * @returns {{ name: string | null, messages: string[] }[]}
     */
    parsedErrors () {
      if (!this.error) return []
      if (typeof this.error === 'string') return [{ name: null, messages: [this.error] }]
      if (Array.isArray(this.error)) return [{ name: null, messages: this.error }]

      const { detail, non_field_errors: nonFieldErrors, __all__: all, ...specificErrors } = this.error
      const errors = []

      /*
       * Some general errors are returned in the `detail`, `non_field_errors` or `__all__` keys.
       * We display each of those errors as a separate list item with no filter name
       */
      if (detail || nonFieldErrors || all) {
        errors.push(...([
          ...ensureArray(detail),
          ...ensureArray(nonFieldErrors),
          ...ensureArray(all)
        ].map(message => ({ name: null, messages: [message] }))))
      }

      // Other errors are assumed to be filter names
      if (specificErrors) {
        // Build a map of filter keys to display names
        const filterNames = Object.fromEntries([
          ...this.availableFilters.map(filter => [filter.name, filter.displayName]),
          ...this.availableFilters.filter(filter => filter.operatorName).map(filter => [filter.operatorName, `${filter.displayName} operator`])
        ])
        errors.push(...Object.entries(specificErrors).map(([key, value]) => ({
          // Try to find a display name from the filter bar filters, and fall back to pretty-printing the keys returned by the API
          name: filterNames[key] || capitalize(key.replaceAll('_', ' ')),
          messages: ensureArray(value)
        })))
      }

      return errors
    }
  },
  methods: {
    ...mapVuexActions('navigation', ['list']),
    ...mapVuexMutations('notifications', ['notify']),
    ...mapActions(useDisplayStore, ['setPageSize']),
    toggleOrderDirection () {
      // Do not allow toggling the order direction for a random order, as it makes no sense
      if (this.order === 'random') return
      this.orderDirection = this.orderDirection === 'asc' ? 'desc' : 'asc'
    },
    // The submit event of the FilterBar. Reset the page number to 1 before making a new query
    submitFilters () {
      this.pageNumber = 1
      this.onFilterUpdate()
    },
    async onFilterUpdate () {
      /*
       * Trigger new queries when the list filters get updated
       * Ignore queries if the corpus is not available
       * or if we use the selection, as it is already in the store
       */
      if (!this.corpusId) return

      // Build the new route query
      const query = {
        ...this.selectedFilters
      }
      if (this.navigationPageSize !== DEFAULT_PAGE_SIZE) query.page_size = this.navigationPageSize.toString()
      if (query.recursive === 'false') delete query.recursive
      if (this.pageNumber !== 1) query.page = this.pageNumber.toString()
      /*
       * Only include the order and order direction if they do not match the expected defaults.
       * This avoid cases where accessing /element/<id>/ immediately redirects to /element/<id>?order=…,
       * which then gets the user stuck in a redirection loop.
       */
      if (this.order && this.order !== (this.elementId ? 'position' : 'name')) query.order = this.order
      if (this.order !== 'random' && this.orderDirection !== 'asc') query.order_direction = this.orderDirection

      /*
       * If this is not the same route, update the route.
       * We have watchers in place on the route; when the route updates,
       * this method will be called again and then we can run the query.
       */
      if (!isEqual(query, this.query)) {
        this.$emit('update:query', query)
        return
      }

      // Clear this.error when filters are updated or submitted again
      if (this.error) this.error = null
      this.loading = true
      try {
        await this.list({
          ...this.selectedFilters,
          page: this.pageNumber,
          order: this.order,
          order_direction: this.orderDirection
        })
      } catch (err) {
        // Both arrays and objects will have a `typeof` that returns `object`
        if (isAxiosError(err) && typeof err.response?.data === 'object') this.error = err.response.data
        // Leave all the other cases to errorParser, as it can handle basic Axios error messages and any JS exception
        else this.error = errorParser(err)
      } finally {
        this.loading = false
      }
    },
    async silentReload () {
      this.loading = true
      try {
        await this.list()
      } catch (err) {
        this.notify({ type: 'error', text: `An error occurred while updating the elements list: ${errorParser(err)}` })
      } finally {
        this.loading = false
      }
    },
    updateIds () {
      this.$store.commit('navigation/setIds', { corpusId: this.corpusId, elementId: this.elementId })
      // Reset to page 1 if the user switches to another element or corpus
      this.pageNumber = 1
    },
    parseQuery (query) {
      // Clone the query to avoid mutating the original argument
      const newQuery = { ...query }

      // If there is a page size in the URL, store it, otherwise reset to the default size
      let pageSize = DEFAULT_PAGE_SIZE
      if (query.page_size) {
        pageSize = Number.parseInt(query.page_size, 10)
        // Reject invalid page sizes
        if (!NAVIGATION_PAGE_SIZES.includes(pageSize)) pageSize = DEFAULT_PAGE_SIZE
      }
      if (this.navigationPageSize !== pageSize) this.setPageSize(pageSize)
      if (query.page_size) delete newQuery.page_size

      if (query.page) {
        const page = Number.parseInt(query.page, 10)
        if (Number.isInteger(page) && page > 0) this.pageNumber = page
        delete newQuery.page
      } else this.pageNumber = 1

      if (query.order) {
        const allowedOrders = ['name', 'created', 'random']
        if (this.elementId) allowedOrders.push('position')
        if (allowedOrders.includes(query.order.toLowerCase())) this.order = query.order
        else this.order = this.elementId ? 'position' : 'name'
        delete newQuery.order
      } else {
        this.order = this.elementId ? 'position' : 'name'
      }

      if (this.order !== 'random' && query.order_direction) {
        if (['asc', 'desc'].includes(query.order_direction.toLowerCase())) this.orderDirection = query.order_direction
        else this.orderDirection = 'asc'
        delete newQuery.order_direction
      } else this.orderDirection = 'asc'

      this.selectedFilters = newQuery
    }
  },
  watch: {
    navigationPageSize (newValue, oldValue) {
      /*
       * When the page size changes, recompute the page number to make sure the first element in the page
       * is still the same.
       * We do not update when the page number is 1, since it will stay at 1 anyway, and we do not update
       * when the page size was `null` in the store, as this means the page size is being filled in for
       * the first time after loading the page.
       * We do not trigger onFilterUpdate either when the page size was `null`, as it is being set by
       * the query watcher which will call onFilterUpdate itself after performing other operations.
       */
      if (oldValue === null) return
      if (newValue !== oldValue && this.pageNumber !== 1) {
        this.pageNumber = Math.ceil((this.pageNumber - 1) * oldValue / newValue)
      } else this.onFilterUpdate()
    },
    pageNumber: 'onFilterUpdate',
    order: 'onFilterUpdate',
    orderDirection: 'onFilterUpdate',
    // Watch when an element is created or deleted in order to quietly reload the navigation elements list
    elementsUpdate: 'silentReload',
    /**
     * When the user enables the classes display, only reload the list when the elements do not currently have prefetched classes
     */
    displayElementClasses (newValue) {
      if (newValue && !this.elements?.results.some(element => Array.isArray(this.classifications[element.id]))) this.silentReload()
    },
    corpusId: {
      handler: 'updateIds',
      immediate: true
    },
    elementId: {
      handler: 'updateIds',
      immediate: true
    },
    // We cannot use the normal vue-router navigation guards because those only work on the main component of a route
    query: {
      immediate: true,
      handler (query) {
        this.parseQuery(query)
        this.onFilterUpdate()
      }
    }
  }
}
</script>

<style scoped>
.paginator {
  margin-top: .5rem !important;
}
.field.has-addons .button {
  height: 100%;
}
.field.has-addons .select select {
  height: 100% !important;
}
</style>
