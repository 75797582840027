import { defineStore } from 'pinia'
import {
  createGroup,
  updateGroup,
  retrieveGroup,
  deleteGroup,
  listGenericMembers,
  listUserMemberships,
  GenericMembershipParameters,
  GroupEdit,
  GroupMembership,
  PageNumberPaginationParameters
} from '@/api'
import { Group, Membership, PageNumberPagination, UUID } from '@/types'

interface State {
  groups: { [id: UUID]: Group }
  membersPage: PageNumberPagination<Membership> | null
  membershipsPage: PageNumberPagination<GroupMembership> | null
}

export const useRightsStore = defineStore('rights', {
  state: (): State => ({
    groups: {},
    membersPage: null,
    membershipsPage: null
  }),
  actions: {
    async createGroup (payload: GroupEdit): Promise<Group> {
      const createdGroup = await createGroup(payload)
      this.groups[createdGroup.id] = createdGroup
      return createdGroup
    },
    async updateGroup (groupId: UUID, payload: GroupEdit): Promise<Group> {
      const updatedGroup = await updateGroup(groupId, payload)
      this.groups[updatedGroup.id] = updatedGroup
      return updatedGroup
    },
    async retrieveGroup (groupId: UUID) {
      const group = await retrieveGroup(groupId)
      this.groups[group.id] = group
    },
    async deleteGroup (groupId: UUID) {
      await deleteGroup(groupId)
      delete this.groups[groupId]
    },
    /**
     * Lists user members of the group at a specific page
     */
    async listMembers (params: GenericMembershipParameters) {
      this.membersPage = await listGenericMembers(params)
    },
    /**
     * Lists groups the authenticated user belongs to on the profile page
     */
    async listMemberships (params: PageNumberPaginationParameters = { page: 1 }) {
      this.membershipsPage = await listUserMemberships(params)
    }
  }
})