
import { cloneDeep } from 'lodash'
import { PropType, defineComponent } from 'vue'

import { UUID_REGEX } from '@/config'
import { SEARCH_HELP } from '@/help'
import { truncateMixin } from '@/mixins'
import { SearchFacetsResponse, SearchSort, SearchSource } from '@/types/search'

import HelpModal from '@/components/HelpModal.vue'
import Facet from './Facet.vue'
import { LocationQuery } from 'vue-router'
import { ensureArray } from '@/helpers'

export default defineComponent({
  emits: ['update:modelValue'],
  mixins: [
    truncateMixin
  ],
  components: {
    HelpModal,
    Facet
  },
  props: {
    corpusId: {
      type: String,
      required: true,
      validator: value => typeof value === 'string' && UUID_REGEX.test(value)
    },
    modelValue: {
      type: Object as PropType<LocationQuery>,
      required: true
    },
    allFacets: {
      type: Object as PropType<SearchFacetsResponse>,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    currentTerms: '',
    sources: {
      element: true,
      transcription: true,
      metadata: true,
      entity: true
    } as Record<SearchSource, boolean>,
    sort: 'relevance' as SearchSort,
    SEARCH_HELP,
    checkedFacets: {} as Partial<Record<keyof SearchFacetsResponse, string>>
  }),
  methods: {
    reset () {
      this.checkedFacets = {}
      this.submit()
    },
    /**
     * Update `this.checkedFacets` from the facets returned by the API and the filters from the route query.
     */
    updateCheckedFacets () {
      const facets = cloneDeep(this.checkedFacets)
      for (const item of Object.keys(this.allFacets) as (keyof SearchFacetsResponse)[]) {
        // If the facet previously did not exist in checkedFacets, set it to an empty string
        if (!(item in facets)) facets[item] = ''
        // If the facet had a selected value in the URL, set it in this.checkedFacets
        if (item in this.modelValue) {
          // A single value in a query string is declared by Vue Router to be either null, string, or (string | null)[].
          let value = this.modelValue[item]
          // First handle the array case…
          if (Array.isArray(value)) value = value[0]
          // Now the value is either a string or null; ignore nulls
          if (value !== null) facets[item] = value
        }
      }
      this.checkedFacets = facets
    },
    parseParams () {
      // Parse URL params
      const query = this.modelValue.query
      this.currentTerms = (Array.isArray(query) ? query[0] : query)?.trim() ?? ''

      if (this.modelValue.sources) {
        const newSources = Object.fromEntries(Object.keys(this.sources).map(key => ([key, false]))) as Record<SearchSource, boolean>
        // When there is only one source in the URL, the parameter might be a single string and not an array
        for (const source of ensureArray(this.modelValue.sources)) {
          if (source && source in this.sources) newSources[source as SearchSource] = true
        }
        this.sources = newSources
      }
      this.updateCheckedFacets()
    },
    /**
     * Submit the currently selected query, but remove the `page` parameter from the paginator to restart at the beginning.
     */
    submit () {
      this.$emit('update:modelValue', this.parsedQuery)
    }
  },
  computed: {
    parsedQuery () {
      return {
        query: this.currentTerms.trim() || '*',
        sources: this.selectedSources,
        sort: this.sort,
        ...this.checkedFacets
      }
    },
    noSource () {
      return Object.values(this.sources).every(value => value === false)
    },
    selectedSources () {
      return Object.entries(this.sources).filter(([, v]) => v).map(([k]) => k)
    }
  },
  watch: {
    allFacets: 'updateCheckedFacets',
    // Search again if we switch to another project, after resetting the facets.
    corpusId: 'reset',
    // Parse from URL again if the URL params change
    modelValue: {
      handler: 'parseParams',
      immediate: true
    },
    sort: 'submit'
  }
})
