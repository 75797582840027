<template>
  <div class="control">
    <div class="input" :class="{ 'is-active': isActive && !disabled }" :disabled="disabled || null">
      <span class="tags has-addons" v-for="(filterData, index) in selectedFilters" :key="filterData.filter.name">
        <span class="tag">{{ filterData.filter.displayName }}</span>
        <span
          class="tag"
          :class="{ clickable: Object.keys(filterData.filter.operators).length > 1 }"
          v-on:click="editOperator(index)"
        >
          {{ filterData.filter.operators[filterData.operator] }}
        </span>
        <span class="tag clickable" v-on:click="editValue(index)">{{ filterData.filter.display(filterData.value) }}</span>
        <span class="tag is-delete" v-on:click="eraseFilter(filterData.filter.name)"></span>
      </span>
      <span class="tags has-addons" v-if="selectedFilter">
        <span class="tag">{{ selectedFilter.displayName }}</span>
        <span class="tag" v-if="selectedOperator">{{ selectedFilter.operators[selectedOperator] }}</span>
      </span>
      <FilterInput
        ref="filterinput"
        :placeholder="computedPlaceholder"
        :suggestions="inputSuggestions || []"
        :select-single-suggestion="selectedFilterName !== null"
        :is-loading="isLoading"
        :disabled="disabled"
        v-model:is-active="isActive"
        v-model="input"
        v-on:select="select"
        v-on:erase="erase"
        v-on:submit="submit"
      />
      <button
        class="delete"
        title="Remove all filters"
        v-if="selectedFilters.length"
        v-on:click="reset"
      ></button>
    </div>
  </div>
</template>

<script>
import { isEqual } from 'lodash'
import { errorParser } from '@/helpers'
import { FILTER_BAR_SUGGESTION_DELAY } from '@/config'
import { Filter } from '@/filterbar'
import FilterInput from './Input.vue'

export default {
  components: {
    FilterInput
  },
  emits: [
    'submit',
    'update:modelValue'
  ],
  props: {
    filters: {
      type: Array,
      required: true,
      // Ensure that all filters are filters and have unique names
      validator: filters => filters.every(filter => filter instanceof Filter) &&
        new Set(filters.map(({ name }) => name)).size === filters.length
    },
    modelValue: {
      type: Object,
      default: () => ({})
    },
    placeholder: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    /**
     * Raw text from the FilterInput; this allows setting its text to help the user when editing an existing filter
     */
    input: '',
    selectedFilterName: null,
    selectedOperator: null,
    /**
     * Completed filters: an object with the filter itself, the applied operated and the value.
     * We preserve the filter order to avoid display issues, hence the array and not an object.
     * @type {{filter: object, operator: string, value: string}[]}
     */
    selectedFilters: [],
    // Whether or not the input child component is focused; allows faking a focused state on the CSS input
    isActive: false,
    // Whether or not the input child component should be disabled
    isLoading: false,
    // Suggestions returned by a call to the filter's autocomplete(). Null when not loaded.
    loadedSuggestions: null,
    /**
     * This is used by the selectedFilters and value watchers. Each can cause the other to trigger as it updates
     * the other value; it sets this parameter before updating the data, to ensure the other watcher will not care
     * about this change (those watchers are only there to handle changes made outside of themselves…).
     * Each watcher checks for this variable, and if it is true they set it to false and abort.
     */
    debounceValueUpdate: false,
    /**
     * This avoid some bugs with Vue's internal behavior when selectedFilters.length switches from or to 0.
     * When the change occurs, for some reason, Vue decides to destroy the FilterInput component entirely
     * and placing it back immediately, amony many other DOM mutations. This causes the focus to switch from the
     * FilterInput to the <body>.
     * If you encounter this bug, set forceFocus to true to cause the bar to try to grab the new FilterInput when
     * it is available again and "steal" the focus back.
     */
    forceFocus: false,
    /**
     * ID of the setTimeout used to update suggestions from the user's input.
     */
    suggestionTimeoutId: null
  }),
  computed: {
    /**
     * The currently selected filter object.
     * We store the filter's name and not its array index to avoid issues if the filter array gets updated;
     * this computed helps finding a filter again by name.
     */
    selectedFilter () {
      return this.filters.find(filter => filter.name === this.selectedFilterName)
    },
    /**
     * Filters that can still be used by the user.
     */
    availableFilters () {
      const selectedNames = this.selectedFilters.map(({ filter }) => filter.name)
      return this.filters.filter(({ name }) => !selectedNames.includes(name))
    },
    /**
     * Suggested strings sent to the input component.
     */
    inputSuggestions () {
      if (this.selectedFilter && this.selectedOperator) {
        if (!this.loadedSuggestions) return null
        // A filter and an operator are selected; show the filter's suggested values
        const suggestions = this.loadedSuggestions.map(value => ([value, this.selectedFilter.display(value)]))

        /*
         * The ESLint rule tries to prevent computed properties from having side effects
         * by looking at method names; if anything is called `sort`, it fails.
         * The Filter.sort method should not have any side-effects, and this is enforced by unit tests,
         * and the `suggestions` const was generated in the above line and cannot cause a change in the component's `data`.
         * We can safely ignore this rule.
         */
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        return this.selectedFilter.sort(suggestions)
      } else if (this.selectedFilter && !this.selectedOperator) {
        // A filter is selected but no operator; suggest operators
        const operators = Object.entries(this.selectedFilter.operators) ?? ['eq', '=']
        return operators.sort(([, a], [, b]) => a.localeCompare(b))
      } else if (!this.selectedFilter) {
        // No selected filter; suggest the available filters
        const filters = this.availableFilters.map(filter => [filter.name, filter.displayName])
        return filters.sort(([, a], [, b]) => a.localeCompare(b))
      } else return []
    },
    /**
     * Display a custom placeholder if it is defined in the filter
     */
    computedPlaceholder () {
      if (!this.selectedFilter) return this.placeholder
      if (this.selectedOperator) return this.selectedFilter.placeholder
      else return this.selectedFilter.operatorPlaceholder
    }
  },
  updated () {
    this.$nextTick(() => {
      if (this.forceFocus) {
        this.$refs.filterinput.focus()
        this.forceFocus = false
      }
    })
  },
  methods: {
    async select ({ value, validated }) {
      // Ignore empty user input
      if (!validated && !value.trim()) return
      if (!this.selectedFilter) {
        this.selectedFilterName = value
        // Check that the filter exists
        if (!this.selectedFilter) {
          this.selectedFilterName = null
          return
        }
        // Once a filter is selected, if it only has one possible operator, pick that operator immediately
        const operators = Object.keys(this.selectedFilter.operators)
        if (operators.length === 1) this.selectedOperator = operators[0]
        return
      }
      if (!this.selectedOperator) {
        if (validated) this.selectedOperator = value
        else {
          const found = Object.entries(this.selectedFilter.operators).find(([, displayName]) => displayName === value.trim())
          if (found != null) this.selectedOperator = found[0]
        }
        return
      }
      if (!validated) {
        try {
          value = this.selectedFilter.validate(value, this.selectedOperator)
          if (value instanceof Promise) {
            this.isLoading = true
            try {
              value = await value
            } finally {
              this.isLoading = false
            }
          }
        } catch (e) {
          this.$store.commit('notifications/notify', { type: 'error', text: errorParser(e) })
          return
        }
      }
      this.selectedFilters.push({ filter: this.selectedFilter, operator: this.selectedOperator, value })
      this.selectedFilterName = null
      this.selectedOperator = null
      this.forceFocus = true
    },

    erase () {
      if (this.selectedOperator) this.selectedOperator = null
      else if (this.selectedFilterName) this.selectedFilterName = null
      else this.selectedFilters.pop()
    },

    /**
     * Erase an existing filter by name.
     */
    eraseFilter (name) {
      if (this.disabled) return
      const index = this.selectedFilters.findIndex(({ filter }) => filter.name === name)
      if (index >= 0) this.selectedFilters.splice(index, 1)
    },

    /**
     * Erase all the filters at once.
     */
    reset () {
      if (this.disabled) return
      this.selectedFilters = []
    },

    /**
     * Only propagate the FilterInput's submit events when no filter is currently being edited
     */
    submit () {
      if (!this.selectedFilterName && !this.selectedOperator) {
        // Close the FilterInput suggestions
        this.isActive = false
        this.$emit('submit')
      }
    },

    /**
     * Edit an existing filter's operator. This also causes the value to be deleted.
     * This is not allowed if the user is in the middle of editing another filter,
     * overwrite the other filter with this one. This is also blocked for filters
     * with only one operator, since this would just be meaningless.
     */
    editOperator (index) {
      if (
        this.selectedFilterName ||
        !this.selectedFilters[index] ||
        Object.keys(this.selectedFilters[index].filter.operators).length <= 1
      ) return
      const { filter, operator } = this.selectedFilters.splice(index, 1)[0]
      this.selectedFilterName = filter.name
      this.input = filter.operators[operator]
      this.$refs.filterinput.focus()
    },

    /**
     * Edit an existing filter's value.
     * This is not allowed if the user is in the middle of editing another filter,
     * overwrite the other filter with this one.
     */
    editValue (index) {
      if (this.selectedFilterName || !this.selectedFilters[index]) return
      const { filter, operator, value } = this.selectedFilters.splice(index, 1)[0]
      this.selectedFilterName = filter.name
      this.selectedOperator = operator
      this.input = filter.display(value)
      this.$refs.filterinput.focus()
    },

    async updateSuggestions () {
      if (!this.selectedFilter || !this.selectedOperator) {
        this.loadedSuggestions = []
        return
      }
      const completion = this.selectedFilter.autocomplete(this.input)
      if (completion instanceof Promise) {
        this.isLoading = true
        this.loadedSuggestions = await completion
        this.isLoading = false
      } else this.loadedSuggestions = completion
    }
  },
  watch: {
    filters () {
      if (this.selectedFilterName && !this.selectedFilter) {
        this.selectedFilterName = null
        this.selectedOperator = null
      }
    },

    selectedFilters: {
      handler (newValue, oldValue) {
        // Ignore updates from the value watcher
        if (this.debounceValueUpdate) {
          this.debounceValueUpdate = false
          return
        }
        if (newValue.length === 0 || oldValue.length === 0) this.forceFocus = true
        const updatedValue = Object.fromEntries(newValue.flatMap(({ filter, operator, value }) => {
          const result = [[filter.name, filter.serialize(value)]]
          if (filter.operatorName) result.push([filter.operatorName, operator])
          return result
        }))
        if (!isEqual(updatedValue, this.modelValue)) {
          this.debounceValueUpdate = true
          this.$emit('update:modelValue', updatedValue)
        }
      },
      deep: true
    },

    /**
     * Rebuild this.selectedFilters from a value updated by the parent component in v-model.
     * The selectedFilters watcher might cause an update to `value` by emitting an `input` event,
     * so we add some checks in this watcher to make sure we do not end up in an endless loop of updates.
     * There is no simple way to detect that the value is changed by the parent component or "naturally"
     * through an input event.
     * We also trigger this rebuild when the component is mounted, since it might already have a non-empty value.
     */
    modelValue: {
      immediate: true,
      async handler (newValue, oldValue) {
        // Ignore updates from the selectedFilters watcher
        if (this.debounceValueUpdate) {
          this.debounceValueUpdate = false
          return
        }
        if (isEqual(oldValue, newValue)) return
        const newFilters = []
        for (const filter of this.filters) {
          if (newValue[filter.name] === undefined) continue
          let operator = 'eq'
          if (filter.operatorName) {
            if (newValue[filter.operatorName] !== undefined) operator = newValue[filter.operatorName]
            else if (Object.keys(filter.operators).length === 1) operator = Object.keys(filter.operators)[0]
            /*
             * The filter has multiple possible operators, but we could not find it in the value,
             * so we cannot reliably determine this filter's option; skip it
             */
            else continue
          }
          let value = newValue[filter.name]
          // deserialize might be a Promise, so just always wrap it in a Promise
          value = await Promise.resolve(filter.deserialize(newValue[filter.name], operator))
          if (value !== undefined) newFilters.push({ filter, operator, value })
        }
        if (!isEqual(newFilters, this.selectedFilters)) {
          this.debounceValueUpdate = true
          this.selectedFilters = newFilters
        }
      }
    },

    selectedOperator: 'updateSuggestions',

    input () {
      if (!this.selectedFilter || !this.selectedOperator) return
      if (this.suggestionTimeoutId !== null) clearTimeout(this.suggestionTimeoutId)
      this.suggestionTimeoutId = setTimeout(this.updateSuggestions, FILTER_BAR_SUGGESTION_DELAY)
    }
  }
}
</script>

<style lang="scss" scoped>
.tags.has-addons {
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
  margin-right: 0.5rem;
}

.tag {
  margin-bottom: 0;

  &:not(:first-child):not(.is-delete) {
    border-left: 2px solid white;
  }

  &.clickable {
    cursor: pointer;
    &:hover {
      background-color: #e8e8e8;
    }
  }
}

.input {
  height: auto !important;
  flex-wrap: wrap;
  padding-top: 0;
  padding-bottom: 0;
}
</style>
