<template>
  <a
    :title="configuration.name"
    v-on:click="configurationModal = true"
  >
    {{ truncateShort(configuration.name) }}
  </a>
  <br />
  <small>
    <ItemId :item-id="configuration.id" />
  </small>
  <ConfigurationsList
    v-if="configurationModal"
    :configuration-id="configuration?.id"
    v-model="configurationModal"
    :worker-version="workerVersion"
    :read-only="true"
  />
</template>

<script>
import { truncateMixin } from '@/mixins'
import { defineComponent } from 'vue'
import ConfigurationsList from '@/components/Process/Workers/Configurations/List.vue'
import ItemId from '@/components/ItemId.vue'

export default defineComponent({
  props: {
    configuration: {
      type: Object,
      required: true
    },
    workerVersion: {
      type: Object,
      required: true
    }
  },
  components: {
    ConfigurationsList,
    ItemId
  },
  mixins: [
    truncateMixin
  ],
  data: () => ({
    configurationModal: false
  })
})
</script>
