import axios from 'axios'
import { User } from '@/types/user'
import { unique } from '.'

/**
 * Retrieve the currently authenticated user.
 */
export const retrieveUser = unique(async (): Promise<User> => (await axios.get('/user/')).data)

interface UserCreatePayload extends Pick<User, 'display_name' | 'email'> {
  password: string
}

/**
 * Register a new user. Requires the `signup` feature.
 */
export const register = async (payload: UserCreatePayload): Promise<User> => (await axios.post('/user/new/', payload)).data

interface UserLoginPayload extends Pick<User, 'email'> {
  password: string
}

export const login = async (payload: UserLoginPayload): Promise<User> => (await axios.post('/user/login/', payload)).data

export const logout = unique(async () => await axios.delete('/user/'))

export const sendResetEmail = async (payload: Pick<User, 'email'>): Promise<Pick<User, 'email'>> => await axios.post('/user/password-reset/', payload)

interface PasswordResetPayload {
  password: string
  token: string
  uidb64: string
}

export const resetUserPassword = (payload: PasswordResetPayload) => axios.post('/user/password-reset/confirm/', payload)

export interface UserUpdatePayload extends Pick<User, 'display_name'> { password?: string }

export const updateUser = async (payload: UserUpdatePayload): Promise<User> => (await axios.patch('/user/', payload)).data

interface UserVerifyEmailPayload extends Pick<User, 'email'> { token: string }

export const verifyEmail = async (payload: UserVerifyEmailPayload): Promise<User> => (await axios.post('/user/verify-email/', payload)).data

export const sendVerificationEmail = async () => await axios.post('/user/verify-email/send/')
