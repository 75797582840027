
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import { corporaMixin, truncateMixin } from '@/mixins'
import HeaderActions from '@/components/HeaderActions.vue'

export default defineComponent({
  mixins: [
    corporaMixin,
    truncateMixin
  ],
  components: {
    HeaderActions
  },
  props: {
    corpusId: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters('auth', ['isVerified'])
  }
})
