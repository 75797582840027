import axios, { AxiosRequestConfig } from 'axios'
import { ElementBase, PageNumberPagination, UUID, TextOrientation, ElementLight, Element, Polygon, CorpusLight, Classification } from '@/types'
import { unique, PageNumberPaginationParameters } from '.'

type ConfidenceOperator = 'eq' | 'lt' | 'lte' | 'gt' | 'gte'

type ElementOrdering = 'name' | 'created' | 'random' | TextOrientation | 'position'

interface BaseElementListParameters extends PageNumberPaginationParameters {
  class_id?: UUID
  classification_confidence?: number
  classification_confidence_operator?: ConfidenceOperator
  classification_high_confidence?: boolean
  confidence?: number
  confidence_operator?: ConfidenceOperator
  creator_email?: string
  folder?: boolean
  metadata_name?: string
  metadata_operator?: ConfidenceOperator | 'contains'
  metadata_value?: string
  mirrored?: boolean
  modifiedSince?: string
  name?: string
  order?: ElementOrdering
  order_direction?: 'asc' | 'desc'
  rotation_angle?: number
  transcription_confidence?: number
  transcription_confidence_operator?: ConfidenceOperator
  transcription_worker_run?: UUID
  transcription_worker_version?: UUID
  type?: string
  with_classes?: boolean
  with_corpus?: boolean
  with_has_children?: boolean
  with_metadata?: boolean
  with_zone?: boolean
  worker_run?: UUID
  worker_version?: UUID
}

export interface ElementListParameters extends BaseElementListParameters {
  corpus: UUID
  top_level?: boolean
  order?: Exclude<ElementOrdering, 'position'>
}

interface ElementParentListParameters extends BaseElementListParameters {
  id: UUID
  recursive?: boolean
  order?: Exclude<ElementOrdering, 'position'>
}

export interface ElementChildrenListParameters extends BaseElementListParameters {
  id: UUID
  recursive?: boolean
}

interface ElementDestroyParameters extends Pick<ElementListParameters, 'corpus' | 'folder' | 'mirrored' | 'name' | 'rotation_angle' | 'top_level' | 'type' | 'worker_run' > {
  delete_children?: boolean
}

interface ElementChildrenDestroyParameters extends Pick<ElementChildrenListParameters, 'id' | 'folder' | 'mirrored' | 'name' | 'rotation_angle' | 'recursive' | 'type' | 'worker_run' > {
  delete_children?: boolean
}

export interface ApiElementList extends ElementBase {
  classifications: Classification[] | null
}

export interface ApiElement extends ApiElementList {
  classifications: Classification[]
}

/**
 * Generic method for element list endpoints (ListElements, ListElementParents, ListElementChildren)
 * with support for `If-Modified-Since`. May return null if the API returns HTTP 304 Not Modified.
 */
async function elementList<T extends BaseElementListParameters | { with_corpus?: true}> (url: string, payload: T): Promise<PageNumberPagination<ApiElementList & { corpus: CorpusLight }>>
async function elementList<T extends BaseElementListParameters | { modifiedSince?: undefined }> (url: string, payload: T): Promise<PageNumberPagination<ApiElementList>>
async function elementList<T extends BaseElementListParameters> (url: string, payload: T): Promise<PageNumberPagination<ApiElementList> | null> {
  const { modifiedSince, ...params } = payload
  const config: AxiosRequestConfig = { params }
  if (modifiedSince) {
    config.headers = { 'If-Modified-Since': modifiedSince, 'Cache-Control': 'no-cache' }
    // Axios treats HTTP 3xx as errors by default because the browser is supposed to handle them
    config.validateStatus = status => (status >= 200 && status < 300) || status === 304
  }
  const resp = await axios.get(url, config)
  if (resp.status === 304) return null
  return resp.data
}

// List all elements.
export const listElements = unique(({ corpus, ...payload }: ElementListParameters) => elementList(`/corpus/${corpus}/elements/`, payload))

// List children elements of an element.
export const listElementChildren = unique(({ id, ...payload }: ElementChildrenListParameters) => elementList(`/elements/${id}/children/`, payload))

// List parents elements of an element.
export const listElementParents = unique(({ id, ...payload }: ElementParentListParameters) => elementList(`/elements/${id}/parents/`, payload))

// Delete elements asynchronously
export const deleteElements = unique(({ corpus, ...params }: ElementDestroyParameters) => axios.delete(`/corpus/${corpus}/elements/`, { params }))

// Delete children elements asynchronously
export const deleteElementChildren = unique(({ id, ...params }: ElementChildrenDestroyParameters) => axios.delete(`/elements/${id}/children/`, { params }))

export interface ElementNeighbor {
  ordering: number
  previous: ElementLight | null
  next: ElementLight | null
  /**
   * List of parent elements, ending with the most direct parent of this element.
   *
   * When the path in the database refers to elements that no longer exist ("ghost elements"), this path might contain `null` values.
   * An error notification should be displayed to the user to help in troubleshooting those paths.
   */
  path: (ElementLight | null)[]
}

// List an element neighbors
export const listElementNeighbors = unique(async (id: UUID): Promise<ElementNeighbor[]> => (await axios.get(`/elements/${id}/neighbors/`)).data)

// Retrieve an element.
export const retrieveElement = unique(async (id: UUID): Promise<ApiElement> => (await axios.get(`/element/${id}/`)).data)

interface ElementCreate {
  type: string,
  name: string,
  corpus: UUID,
  image?: UUID,
  parent?: UUID,
  polygon?: Polygon,
  rotation_angle?: number,
  mirrored?: boolean,
  confidence?: number,
  worker_run_id?: UUID | null
}

// Create an element.
export const createElement = async (data: ElementCreate): Promise<ApiElement> => (await axios.post('/elements/create/', data)).data

interface ElementUpdate extends Partial<Pick<ElementCreate, 'type' | 'name' | 'image' | 'polygon' | 'confidence' | 'mirrored' | 'rotation_angle'>> {
  id: UUID
}

// Update an element.
export const updateElement = async ({ id, ...data }: ElementUpdate): Promise<ApiElement> => (await axios.patch(`/element/${id}/`, data)).data

interface ElementDelete {
  id: UUID,
  delete_children?: boolean
}

// Delete an element.
export const deleteElement = unique(async ({ id, ...params }: ElementDelete) => (await axios.delete(`/element/${id}/`, { params })).data)

interface MoveElementPayload {
  source: UUID
  destination: UUID
}

// Move an element to a destination folder.
export const moveElement = async (data: MoveElementPayload): Promise<MoveElementPayload> => (await axios.post('/element/move/', data)).data

interface CreateElementParentPayload {
  childId: UUID
  parentId: UUID
}

// Link an element to a new folder.
export const createParentElement = async ({ childId, parentId }: CreateElementParentPayload): Promise<CreateElementParentPayload> => (await axios.post(`/element/${childId}/parent/${parentId}/`)).data
