
import { isAxiosError } from 'axios'
import { isEmpty, cloneDeep } from 'lodash'
import { mapState, mapActions } from 'pinia'
import { PropType, defineComponent } from 'vue'

import { errorParser } from '@/helpers'
import { UUID } from '@/types'
import { useNotificationStore, useWorkerStore } from '@/stores'

import ConfigurationForm from './Form.vue'

interface ConfigurationExistsError {
  name?: [string]
  configuration?: [string]
  id?: [UUID]
}

interface ParsedConfigurationExistsError {
  name?: [string]
  configuration?: [string]
  id?: UUID
}

export default defineComponent({
  emits: [
    'set-configuration',
    'config-created'
  ],
  components: {
    ConfigurationForm
  },
  props: {
    workerVersionId: {
      type: String as PropType<UUID>,
      required: true
    },
    workerId: {
      type: String as PropType<UUID>,
      required: true
    },
    newConfiguration: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    loading: false,
    configurationExistsError: null as ConfigurationExistsError | null,
    disabledTitle: '',
    formConfiguration: {
      name: '',
      configuration: {}
    }
  }),
  computed: {
    ...mapState(useWorkerStore, ['workerConfigurations', 'workerVersions']),
    parsedConfigurationExistsError (): ParsedConfigurationExistsError | undefined {
      // The error returned by the backend returns the existing configuration's ID like this [ID]
      if (!this.configurationExistsError) return
      if ((this.configurationExistsError.id?.length ?? 0) > 1) throw new Error('Multiple existing configuration IDs returned.')
      return {
        ...this.configurationExistsError,
        id: this.configurationExistsError.id?.[0] ?? undefined
      }
    }
  },
  methods: {
    ...mapActions(useWorkerStore, ['createConfiguration']),
    ...mapActions(useNotificationStore, ['notify']),
    async create () {
      if (this.loading || this.disabledTitle) return
      this.loading = true
      try {
        const configuration = await this.createConfiguration(this.workerId, {
          name: this.formConfiguration.name,
          configuration: this.formConfiguration.configuration
        })
        // Go back to configurations list only if the creation succeeded
        this.$emit('config-created')
        // Select the newly created configuration
        this.$emit('set-configuration', configuration.id)
        this.notify({ type: 'info', text: 'Configuration created. Save to add it to this worker run.' })
      } catch (err) {
        if (isAxiosError(err) && err.response?.data) this.configurationExistsError = err.response.data
        this.notify({ type: 'error', text: errorParser(err) })
        this.loading = false
      }
    }
  },
  watch: {
    newConfiguration: {
      immediate: true,
      handler (newValue) {
        if (!isEmpty(newValue.configuration)) this.formConfiguration = cloneDeep(newValue)
      }
    }
  }
})
