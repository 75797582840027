
import { BooleanUserConfigurationField } from '@/types/workerConfiguration'
import { PropType, defineComponent } from 'vue'
export default defineComponent({
  emits: {
    'update:modelValue': (value: boolean) => typeof value === 'boolean'
  },
  props: {
    field: {
      type: Object as PropType<BooleanUserConfigurationField>,
      required: true
    },
    modelValue: {
      type: Boolean,
      required: true
    }
  }
})
