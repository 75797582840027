
import { PropType, defineComponent } from 'vue'
import { mapActions } from 'pinia'
import { CorpusExport } from '@/types/export'
import Modal from '@/components/Modal.vue'
import { useExportStore } from '@/stores'

export default defineComponent({
  components: {
    Modal
  },
  emits: {
    'update:modelValue': (value: boolean) => typeof value === 'boolean'
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    corpusExport: {
      type: Object as PropType<CorpusExport>,
      required: true
    }
  },
  data: () => ({
    loading: false
  }),
  methods: {
    ...mapActions(useExportStore, ['delete']),
    async deleteExport () {
      if (this.loading) return
      this.loading = true
      try {
        await this.delete(this.corpusExport.id, this.corpusExport.corpus_id)
      } finally {
        this.loading = false
        this.$emit('update:modelValue', false)
      }
    }
  }
})
