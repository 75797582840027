import { defineStore } from 'pinia'
import { PageNumberPaginationParameters } from '@/api'
import { errorParser } from '@/helpers'
import { UUID } from '@/types'
import { ElementDatasetSet } from '@/types/dataset'
import { useNotificationStore } from '@/stores'
import * as api from '@/api'

interface State {
  /**
   * List of dataset that contains a specific element
   */
  elementDatasetSets: { [elementId: UUID]: ElementDatasetSet[] }
}

interface ListElementSetsParameters extends PageNumberPaginationParameters {
  with_neighbors?: boolean
}

export const useElementsStore = defineStore('elements', {
  state: (): State => ({
    elementDatasetSets: {}
  }),
  actions: {
    async listElementDatasetSets (elementId: UUID, params: ListElementSetsParameters = {}) {
      // Avoid listing datasets twice for an element
      if ((!params.page || params.page === 1) && this.elementDatasetSets?.[elementId]) return
      // List datasets containing a specific element through all pages
      try {
        const response = await api.listElementDatasetSets(elementId, params)

        // Progressively add results to the store
        if (elementId in this.elementDatasetSets) this.elementDatasetSets[elementId].push(...response.results)
        else this.elementDatasetSets[elementId] = response.results

        // Follow pagination without awaiting, until we fetched all the data
        if (response.next !== null) this.listElementDatasetSets(elementId, { ...params, page: response.number + 1 })
      } catch (err) {
        useNotificationStore().notify({ type: 'error', text: errorParser(err) })
        throw err
      }
    }
  }
})
