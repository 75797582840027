import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-93f08326"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "columns mt-0 mr-0" }
const _hoisted_2 = { class: "column" }
const _hoisted_3 = {
  key: 0,
  title: "High confidence classification",
  class: "icon-award has-text-warning"
}
const _hoisted_4 = { class: "column is-narrow is-paddingless" }
const _hoisted_5 = { class: "tags has-addons ml-1" }
const _hoisted_6 = ["disabled"]
const _hoisted_7 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ConfidenceTag = _resolveComponent("ConfidenceTag")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createTextVNode(_toDisplayString(_ctx.classification.ml_class.name) + " ", 1),
      (_ctx.classification.high_confidence)
        ? (_openBlock(), _createElementBlock("i", _hoisted_3))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_ConfidenceTag, {
        value: _ctx.classification.confidence
      }, null, 8, ["value"]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("span", {
          disabled: _ctx.disabled || _ctx.loading || null,
          class: _normalizeClass(["tag button icon-check", { 'is-success': _ctx.validated, 'is-loading': _ctx.loading }]),
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.validate && _ctx.validate(...args)))
        }, null, 10, _hoisted_6),
        _createElementVNode("span", {
          disabled: _ctx.disabled || _ctx.loading || null,
          class: _normalizeClass(["tag button", { 'is-loading': _ctx.loading, 'is-danger': _ctx.rejected, 'icon-trash has-text-danger': _ctx.isManual, 'is-delete': !_ctx.isManual }]),
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.reject && _ctx.reject(...args)))
        }, null, 10, _hoisted_7)
      ])
    ])
  ]))
}