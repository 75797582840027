
import { EnumUserConfigurationField } from '@/types/workerConfiguration'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  emits: {
    'update:modelValue': (value: string | number) => ['string', 'number'].includes(typeof value)
  },
  props: {
    field: {
      type: Object as PropType<EnumUserConfigurationField>,
      required: true
    },
    modelValue: {
      type: [String, Number],
      required: true
    }
  },
  computed: {
    selected: {
      get () {
        return this.modelValue
      },
      set (value: string | number) {
        this.$emit('update:modelValue', value)
      }
    }
  }
})
