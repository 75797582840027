
import { defineComponent, PropType } from 'vue'
import { mapGetters as mapVuexGetters } from 'vuex'
import { corporaMixin, truncateMixin } from '@/mixins'
import Modal from '@/components/Modal.vue'
import { mapActions } from 'pinia'
import { useDatasetStore, useNotificationStore } from '@/stores'
import { Dataset } from '@/types/dataset'
import { ElementList, Element } from '@/types'

export default defineComponent({
  components: {
    Modal
  },
  emits: {
    'update:modelValue': (value: boolean) => typeof value === 'boolean'
  },
  mixins: [
    corporaMixin,
    truncateMixin
  ],
  props: {
    // The element to delete.
    element: {
      type: Object as PropType<ElementList | Element>,
      required: true
    },
    dataset: {
      type: Object as PropType<Dataset>,
      required: true
    },
    set: {
      type: String,
      required: true
    },
    modelValue: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    loading: false
  }),
  computed: {
    ...mapVuexGetters('auth', ['isVerified']),
    // Required for the corporaMixin
    corpusId () {
      return this.element.corpus?.id
    },
    /**
     * Allow deleting elements only if the user is verified and has contributor rights on the corpus, and
     * the dataset is in the "open" state.
     */
    canRemove () {
      return !this.loading && this.corpus && this.isVerified && this.canWrite(this.corpus) && this.dataset.state === 'open'
    }
  },
  methods: {
    ...mapActions(useDatasetStore, ['removeDatasetElement']),
    ...mapActions(useNotificationStore, ['notify']),
    updateModelValue (value: boolean) { this.$emit('update:modelValue', value) },
    async performRemove () {
      if (!this.canRemove) return
      this.loading = true
      try {
        await this.removeDatasetElement(this.dataset.id, this.element.id, this.set)
        this.updateModelValue(false)
      } finally {
        this.loading = false
      }
    }
  }
})
