/*
 * API endpoints layer
 * Do not call API endpoints directly; add a method here, import it and call it.
 * This enhances readability and makes mocking and future refactorings easier.
 */
export * from './classification'
export * from './corpus'
export * from './dataset'
export * from './element'
export * from './elementType'
export * from './entity'
export * from './entityType'
export * from './export'
export * from './files'
export * from './image'
export * from './ingest'
export * from './job'
export * from './allowedMetadata'
export * from './mlclass'
export * from './mlresults'
export * from './model'
export * from './ponos'
export * from './process'
export * from './rights'
export * from './search'
export * from './selection.js'
export * from './selection.ts'
export * from './transcription'
export * from './user'
export * from './worker'
export * from './workerActivity'
export * from './workerConfiguration'
export * from './workerRun'
export * from './metadata'

/**
 * Prevent running more than one promise at once on an asynchronous function.
 * This allows components to flood some endpoints with calls, such as listCorpora,
 * and only make one HTTP request.
 */
export function unique<Args extends unknown[], Return> (
  func: (...args: Args) => Promise<Return>
): (...args: Args) => Promise<Return> {
  const pending: Record<string, Promise<Return>> = {}
  return async (...args: Args): Promise<Return> => {
    /*
     * Turn arguments into a string so they fit as an object key
     * This will not work for circular references! (args.key = args)
     */
    const stringArgs = JSON.stringify(args)
    if (stringArgs in pending) return await pending[stringArgs]

    /*
     * Using Promise.finally() on the promise returned by func(...args) to erase the
     * pending promise from the `pending` object causes uncaught errors to show up
     * in web browser consoles, but not in unit tests, even when the errors are caught
     * in downstream code, because they actually get thrown twice:
     * once before the .finally() and once after.
     * So instead, we wrap the Promise returned by func(...args) into another async
     * function that contains the finally block, and call it immediately, which
     * returns another Promise.
     */
    const promise = (async () => {
      try {
        return await func(...args)
      } finally {
        delete pending[stringArgs]
      }
    })()

    pending[stringArgs] = promise
    return await promise
  }
}

export interface PageNumberPaginationParameters {
  page?: number
  page_size?: number
}

export interface CursorPaginationParameters {
  cursor?: string | null,
  with_count?: boolean
}
