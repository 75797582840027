<template>
  <div class="is-pulled-right">
    <!--
      Only display the edit button for metadata without entities.
      Keep it disabled if the metadata has no entity, but the user does not have access rights to edit it.
    -->
    <a
      class="icon icon-edit has-text-info"
      :class="{ 'disabled': !metadata.editable }"
      v-if="!metadata.entity"
      v-on:click="edit"
      :title="metadata.editable ? 'Edit metadata' : 'Only an administrator can edit this protected metadata'"
      target="_blank"
    ></a><a
      class="icon icon-trash has-text-danger"
      :class="{ 'disabled': !metadata.editable }"
      v-on:click="drop"
      :title="metadata.editable ? 'Delete metadata' : 'Only an administrator can delete this protected metadata'"
      target="_blank"
    ></a>
  </div>
</template>

<script>
export default {
  emits: ['edit-metadata', 'delete-metadata'],
  props: {
    metadata: {
      type: Object,
      required: true
    }
  },
  methods: {
    drop () {
      if (!this.metadata.editable) return
      this.$emit('delete-metadata', this.metadata)
    },
    edit () {
      if (!this.metadata.editable || this.metadata.entity) return
      this.$emit('edit-metadata', this.metadata)
    }
  }
}
</script>

<style scoped>
.icon.disabled {
  color: lightgray !important;
  cursor: not-allowed;
}
</style>
