import axios from 'axios'
import { unique } from '.'
import { WorkerVersionCache, PageNumberPagination, UUID } from '@/types'

export type DeleteWorkerResultsParameters = {
  /**
   * Delete results that have been produced under a specific element.
   */
  element_id?: UUID

  /**
   * Delete results produced by a specific worker run
   */
  worker_run_id?: UUID

  /**
   * Delete results produced with a specific worker version.
   */
  worker_version_id?: UUID

  /**
   * Delete results generated with a specific model.
   */
  model_version_id?: UUID

  /**
   * Delete results produced with a specific worker configuration, or exclude them.
   */
  configuration_id?: UUID | false

  /**
   * Delete results from selected elements, not compatible with element_id filter.
   */
  use_selection?: boolean
}
// Prevent using a selection with a parent element
& (
  { use_selection?: false }
  | { use_selection: true, element_id?: undefined }
)

/**
 * List worker versions used by elements of a corpus
 */
export const listCorpusWorkerVersions = unique(async (corpusId: UUID, url: string | null): Promise<PageNumberPagination<WorkerVersionCache>> => (await axios.get(url || `/corpus/${corpusId}/versions/`)).data)

/**
 * Delete results on a corpus or under a parent element
 */
export const deleteWorkerResults = unique(
  async (corpusId: UUID, params: DeleteWorkerResultsParameters) => {
    await axios.delete(`/corpus/${corpusId}/worker-results/`, { params })
  }
)
