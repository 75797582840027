import axios from 'axios'
import { PageNumberPaginationParameters, unique } from '.'
import { PageNumberPagination, UUID } from '@/types'
import { Worker, WorkerType, WorkerVersion } from '@/types/worker'

export class DuplicatedWorker extends Error {}

export interface ListWorkersParameters extends PageNumberPaginationParameters {
  name?: string
  type?: string
  archived?: boolean
  compatible_model?: UUID
}

export type CreateWorkerPayload = Pick<Worker, 'name' | 'slug' | 'type'> & Partial<Pick<Worker, 'description'>>

export type UpdateWorkerPayload = Partial<CreateWorkerPayload> & Partial<Pick<Worker, 'archived'>>

export interface CreateWorkerVersionPayload extends Pick<WorkerVersion, 'configuration'>, Partial<Pick<WorkerVersion, 'docker_image_iid' | 'state' | 'gpu_usage' | 'model_usage'>> { revision_id?: UUID }

// List executable workers
export const listWorkers = unique(async (params: ListWorkersParameters): Promise<PageNumberPagination<Worker>> => (await axios.get('/workers/', { params })).data)

// List available worker types on instance
export const listWorkerTypes = unique(async (params: PageNumberPaginationParameters): Promise<PageNumberPagination<WorkerType>> => (await axios.get('/workers/types/', { params })).data)

export interface WorkerVersionListParameters extends PageNumberPaginationParameters {
  mode?: "simple" | "complete"
}

// List versions of a worker
export const listWorkerVersions = unique(async (workerId: UUID, params: WorkerVersionListParameters): Promise<PageNumberPagination<WorkerVersion>> => (await axios.get(`/workers/${workerId}/versions/`, { params })).data)

// Retrieve a worker version
export const retrieveWorkerVersion = unique(async (id: UUID): Promise<WorkerVersion> => (await axios.get(`/workers/versions/${id}/`)).data)

// Retrieve a worker
export const retrieveWorker = unique(async (id: UUID): Promise<Worker> => (await axios.get(`/workers/${id}/`)).data)

/**
 * Create a worker.
 * This call will throw a DuplicatedWorker error in case a worker already exists with this slug and is executable by the user.
 */
export const createWorker = unique(async (params: CreateWorkerPayload): Promise<Worker> => {
  const result = await axios.post('/workers/', params)
  if (result.status === 200) throw new DuplicatedWorker()
  return result.data
})

// Update a worker
export const updateWorker = unique(async (id: UUID, params: UpdateWorkerPayload): Promise<Worker> => (await axios.patch(`/workers/${id}/`, params)).data)

// Create a worker version
export const createWorkerVersion = unique(async (workerId: UUID, params: CreateWorkerVersionPayload): Promise<WorkerVersion> => (await axios.post(`/workers/${workerId}/versions/`, params)).data)
