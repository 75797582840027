import axios from 'axios'
import { APICorpus, Corpus, UUID } from '@/types'
import { unique } from '.'

export const listCorpora = unique(async (): Promise<APICorpus[]> => (await axios.get('/corpus/')).data)

// The name is required, everything else is optional
export type CorpusEditPayload = Pick<Corpus, 'name'> & Partial<Pick<Corpus, 'description' | 'public' | 'top_level_type' | 'indexable'>>

export const createCorpus = async (corpus: CorpusEditPayload): Promise<APICorpus> => (await axios.post('/corpus/', corpus)).data

export const updateCorpus = async (id: UUID, payload: CorpusEditPayload): Promise<APICorpus> => (await axios.patch(`/corpus/${id}/`, payload)).data

export const deleteCorpus = unique((corpusId: UUID) => axios.delete(`/corpus/${corpusId}/`))
