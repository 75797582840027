<template>
  <div class="paginator">
    <slot name="loading" v-if="loading">
      <div class="loading-content loader"></div>
    </slot>
    <template v-else>
      <template v-if="response && (allowEmpty || count > 0)">
        <PaginationBar
          :response="response"
          :singular="singular"
          :plural="plural"
          :page-size="pageSize"
          :simple-navigation="simpleNavigation"
          v-on:navigate="load"
        />
        <hr class="is-hr-top" />

        <slot :results="response.results"></slot>

        <template v-if="bottomBar">
          <hr class="is-hr-bottom" />
          <PaginationBar
            :response="response"
            :singular="singular"
            :plural="plural"
            :page-size="pageSize"
            :simple-navigation="simpleNavigation"
            v-on:navigate="load"
          />
        </template>
      </template>
      <slot name="no-results" v-else-if="response && count === 0">
        <div class="notification is-warning">
          No results.
        </div>
      </slot>
    </template>
  </div>
</template>

<script>
import { clone } from 'lodash'
import { DEFAULT_PAGE_SIZE } from '@/config'
import PaginationBar from './PaginationBar'

export default {
  components: {
    PaginationBar
  },
  emits: ['update:page'],
  props: {
    response: {
      type: Object,
      default: null
    },
    singular: {
      type: String,
      default: 'result'
    },
    plural: {
      type: String,
      default: 'results'
    },
    /*
     * Avoid updating URL query when updating the page.
     * This property requires the .sync modifier in order to be updated.
     */
    page: {
      type: Number,
      default: null
    },
    pageSize: {
      type: Number,
      default: DEFAULT_PAGE_SIZE
    },
    bottomBar: Boolean,
    // Provides previous and next buttons only
    simpleNavigation: Boolean,
    loading: Boolean,
    allowEmpty: Boolean
  },
  computed: {
    count () {
      if (!this.response || !this.response.results) return null
      return this.response.results.length
    }
  },
  methods: {
    load ({ page, cursor }) {
      if (this.loading || (!page && !cursor)) return
      if (page && this.page) {
        // Update the page prop only
        this.$emit('update:page', page)
        return
      }
      const query = { ...clone(this.$route.query) }
      if (page) query.page = page
      if (cursor) query.cursor = cursor
      // Update URL page number
      this.$router.push({ name: this.$route.name, query })
    }
  }
}
</script>

<style scoped>
.paginator:not(:first-child) {
  margin-top: 1em;
}
.title + .paginator {
  margin-top: 0;
}
hr {
  margin: 1rem 0;
}
.loading-content {
  font-size: 2.5rem;
  margin: 2.5rem auto 0 auto;
}
</style>
