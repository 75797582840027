
import { isEmpty } from 'lodash'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import { useJobsStore } from '@/stores'
import Modal from '@/components/Modal.vue'
import Job from './Job.vue'

export default defineComponent({
  components: {
    Modal,
    Job
  },
  emits: {
    'update:modelValue': (value: boolean) => typeof value === 'boolean'
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState(useJobsStore, ['jobs', 'loading'])
  },
  mounted () {
    // Triggers as soon as a user logs in or the frontend's authentication gets loaded
    this.list()
  },
  methods: {
    ...mapActions(useJobsStore, ['list', 'startPolling', 'stopPolling']),
    isEmpty,
    input (value: boolean) {
      // v-model passthrough
      this.$emit('update:modelValue', value)
    }
  },
  watch: {
    modelValue: {
      immediate: true,
      async handler (newValue: boolean) {
        if (newValue) await this.startPolling()
        else this.stopPolling()
      }
    }
  }
})
