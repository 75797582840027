import { Store } from 'vuex'
import { errorParser } from './index'
import { Router } from 'vue-router'

/**
 * Create a process and redirect to elements filtering configuration page.
 */
export const createProcessRedirect = async (store: Store<object>, router: Router, payload: Record<string, unknown>) => {
  try {
    const resp = await store.dispatch('process/createProcess', payload)
    router.push({ name: 'process-filter', params: { id: resp.id } })
  } catch (e) {
    store.commit('notifications/notify', { type: 'error', text: `An error occurred creating the process: ${errorParser(e)}` })
  }
}

/**
 * Handle worker user configuration validation errors.
 */
export class ConfigurationValidationError extends Error {
  /**
   * Error messages on each field of the configuration.
   */
  readonly errors: Record<string, string>

  constructor (errors: Record<string, string>) {
    super(errors.toString())
    // Not setting this would make the error look like a normal Error
    this.name = this.constructor.name
    this.errors = errors
  }
}
