import axios from 'axios'
import { Group, Membership, OneOf, PageNumberPagination, UnionKeys, UUID } from '@/types'
import { PageNumberPaginationParameters, unique } from '.'

export type GroupEdit = Pick<Group, 'name' | 'public'>

// Create a group
export const createGroup = async (params: GroupEdit): Promise<Group> => (await axios.post('/groups/', params)).data

// Retrieve a group
export const retrieveGroup = unique(async (groupId: UUID): Promise<Group> => (await axios.get(`/group/${groupId}/`)).data)

// Delete a group
export const deleteGroup = unique(async (groupId: UUID) => await axios.delete(`/group/${groupId}/`))

// Update a group
export const updateGroup = async (groupId: UUID, payload: GroupEdit): Promise<Group> => (await axios.patch(`/group/${groupId}/`, payload)).data

/**
 * Parameters that can be used with any target type on ListGenericMemberships
 */
interface GenericMembershipBaseParameters extends PageNumberPaginationParameters {
  type?: 'group' | 'user'
}

type MembershipContentTypeParameters = [
  { corpus: UUID },
  { group: UUID },
  { model: UUID },
  { worker: UUID }
]

/**
 * Parameters for the ListGenericMemberships endpoint:
 * one, and only one, key must be defined to specify the target Django model and its UUID.
 */
export type GenericMembershipParameters = GenericMembershipBaseParameters & OneOf<MembershipContentTypeParameters>

export type MembershipContentType = UnionKeys<MembershipContentTypeParameters[number]>

// List members of any content object
export const listGenericMembers = unique(
  async (params: GenericMembershipParameters): Promise<PageNumberPagination<Membership>> => (await axios.get('/memberships/', { params })).data
)

interface BaseMembershipCreateResponse {
  id: UUID
  level: number
  content_type: string
  content_id: UUID
}

interface UserMembershipCreateResponse extends BaseMembershipCreateResponse {
  user_email: string
  group_id: null
}

interface GroupMembershipCreateResponse extends BaseMembershipCreateResponse {
  user_email: null
  group_id: UUID
}

type UserMembershipCreateRequest = Omit<UserMembershipCreateResponse, 'id' | 'group_id'>
type GroupMembershipCreateRequest = Omit<UserMembershipCreateResponse, 'id' | 'user_email'>

type MembershipCreateRequest = UserMembershipCreateRequest | GroupMembershipCreateRequest
type MembershipCreateResponse = UserMembershipCreateResponse | GroupMembershipCreateResponse

/**
 * Create a new generic membership.
 * Only either the user's email address or the group ID may be specified at a time.
 */
// These overloads allow us to state that if you send a user email, you will not get a group ID, and vice versa.
export async function createMembership (payload: UserMembershipCreateRequest): Promise<UserMembershipCreateResponse>
export async function createMembership (payload: GroupMembershipCreateRequest): Promise<GroupMembershipCreateResponse>
export async function createMembership (payload: MembershipCreateRequest): Promise<MembershipCreateResponse> {
  return (await axios.post('/memberships/create/', payload)).data
}

interface MembershipUpdateRequest {
  id: UUID
  level?: number
}
type MembershipUpdateResponse = Required<MembershipUpdateRequest>

// Update a membership: change the access level of a group member
export const updateMembership = unique(
  async ({ id, ...payload }: MembershipUpdateRequest): Promise<MembershipUpdateResponse> => (await axios.patch(`/membership/${id}/`, payload)).data
)

// Delete a membership: revoke a user access to the group
export const deleteMembership = unique(async (id: UUID) => await axios.delete(`/membership/${id}/`))

export interface GroupMembership {
  // The attributes are nullable because a group might be returned to a Django admin despite them not being a member on that group
  id: UUID | null
  level: number | null
  group: Group
}

// List groups the authenticated user belongs to
export const listUserMemberships = unique(
  async (params: PageNumberPaginationParameters): Promise<PageNumberPagination<GroupMembership>> => (await axios.get('/user/memberships/', { params })).data
)
