import axios from 'axios'
import { unique } from '.'

// List files on a corpus.
export const listDataFiles = unique(async ({ corpus, ...params }) => (await axios.get(`/process/files/${corpus}/`, { params })).data)

/*
 * Create a new DataFile.
 * Will return an S3 URL to upload the file.
 */
export const createDataFile = async data => (await axios.post('/process/files/create/', data)).data

// Update an existing DataFile.
export const updateDataFile = async ({ id, ...data }) => axios.patch(`/process/file/${id}/`, data)

// Remove a DataFile by ID.
export const destroyDataFile = unique(async id => axios.delete(`/process/file/${id}/`))

// Run a file import process from files
export const importFromFiles = async payload => (await axios.post('/process/fromfiles/', payload)).data
